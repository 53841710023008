import { ROLES } from '@/lib/authorization';
import { axios } from '@/lib/axios';

import { RegisterResponse } from '../types';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
  role: ROLES;
};

export const commonLogin = async (data: LoginCredentialsDTO): Promise<RegisterResponse> => {
  let response;
  if (data.role === ROLES.CUSTOMER) {
    response = await loginWithEmailAndPassword(data);
    response.data.user.role = ROLES.CUSTOMER;
  } else if (data.role === ROLES.AGENCY) {
    response = await agencyLogin(data);
    response.data.user.role = ROLES.AGENCY;
  } else if (data.role === ROLES.LAWYER) {
    response = await loginWithEmailAndPassword(data);
    response.data.user.role = ROLES.LAWYER;
  } else {
    response = await adminLogin(data);
    response.data.user.role = ROLES.ADMIN;
  }
  return response;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<RegisterResponse> => {
  return axios.post('customer-api/login/', data);
};

export const adminLogin = (data: LoginCredentialsDTO): Promise<RegisterResponse> => {
  return axios.post('admin-api/admin-login', data);
};

export const agencyLogin = (data: LoginCredentialsDTO): Promise<RegisterResponse> => {
  return axios.post('agency-api/agnecy-login', data);
};
