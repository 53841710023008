import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { InvestorContent } from '@/types/InvestorContent';
import { ApiResponse } from '@/types';

export const termsCondition = (): Promise<ApiResponse<InvestorContent>> => {
  return axios.get('/panel-api/get-content-pages?page_type=Terms_And_Conditions');
};

type QueryFnType = typeof termsCondition;

type investorData = {
  config?: QueryConfig<QueryFnType>;
};

export const useTermsCondition = ({ config }: investorData = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['content'],
    queryFn: () => termsCondition(),
  });
};
