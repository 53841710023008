import { axios } from '@/lib/axios';
import { ApiResponse, RoleContent } from '@/types';

export type contentManagementDTO = {
  currency_id: string;
  symbol: string;
  name: string;
  converted_price: string;
};

export const editCurrency = (
  data: contentManagementDTO,
  id: string
): Promise<ApiResponse<RoleContent>> => {
  return axios.post(`/admin-api/edit-currency?currency_id:${id}`, data);
};
