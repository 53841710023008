import { axios } from '@/lib/axios';
import { ApiResponse, RoleContent } from '@/types';

export type contentManagementDTO = {
  role_name: string;
  id: any;
  permission: any;
};

export const editRoles = (data: contentManagementDTO): Promise<ApiResponse<RoleContent>> => {
  return axios.post('/admin-api/edit-role', data);
};
