enum amanetiesIcon {
  Garden_view = 'Garden View',
  Kitchen = 'Kitchen',
  Linens = 'Linens',
  tv = 'Telivision',
  Extra_Chairs = 'Extra Chair',
  Charger = 'Charger',
  Candels = 'Candels',
  Bath_Tub = 'Bath Tub',
  Hair_Chair = 'Hair Chair',
  Window_ac_unit = 'Window Ac',
  Pets_Allowed = 'Pets Allowed',
  Dedicated_Workspace = 'Dedicated Work Space',
  Extra_Pillows_And_Blankets = 'Extra pillows and blankets',
  Extra_bed = 'Extra Bed ',
  Utensils = 'Utensils ',
  Balcony = 'Balcony',
  Smart_Home = 'Smart Home',
  Fitness_Centre = 'Fitness Centre',
  Play_Ground = 'Playground',
  Games_Room = 'Games Room',
  Parking_lots = 'Parking lots',
  Dinnig_Room = 'Dining Room',
  Wood_style_flooring = 'Wood-Style Flooring',
  Spa = 'Spa',
  Keyless_electronic_unit = 'Keyless Electronic Unit',
  Laundry_facility = 'Laundry Facility',
  Large_windows = 'Large Windows',
  Nearby_supermarkets = 'Nearby Supermarkets',
}
export default amanetiesIcon;
