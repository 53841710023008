import { initReactQueryAuth } from 'react-query-auth';

import { getUser, UserResponse, AuthUser, RegisterResponse } from '@/features/auth';
import storage from '@/utils/storage';
import Loader from '@/components/Elements/Loader/Loader';
import logo from '@/assets/new-logo.png';

import { verifyRegisterOtp, VerifyRegisterOtpDTO } from '@/features/auth/api/verifyRegisterOtp';
import { useEffect } from 'react';
import { verifyAgencyRegisterOtp } from '@/features/auth/api/verifyAgencyRegisterOtp';

async function handleUserResponse(data: UserResponse) {
  const { access, user } = data;
  storage.setToken(access);
  return user;
}

async function loadUser() {
  if (storage.getToken()) {
    try {
      const data = await getUser();
      return data.data;
    } catch (e: any) {
      if (e.response.status === 401) {
        await logoutFn();
      }
    }
  }
  return null;
}

async function loginFn(response: RegisterResponse) {
  const user = await handleUserResponse(response.data);
  return user;
}

//This function will verify email and login the user
async function registerFn(data: VerifyRegisterOtpDTO) {
  const response = await verifyRegisterOtp(data);
  const user = await handleUserResponse(response.data);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  localStorage.removeItem('loginType');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('adminToken');
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen alignmentLogo">
        {/* <Loader /> */}
        <img src={logo} alt="pics" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  RegisterResponse,
  VerifyRegisterOtpDTO
>(authConfig);
