import clsx from 'clsx';
import * as React from 'react';

import { Spinner } from '@/components/Elements/Spinner';

const variants = {
  primary: 'btn-primary text-white',
  inverse: 'btn-secondary text-white',
  outline: 'btn-outline-primary',
  outlineDark: 'btn-outline-dark',
  danger: 'btn-danger',
};

const sizes = {
  sm: 'btn-sm',
  md: '',
  lg: 'btn-lg',
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
  block?: boolean;
  heavy?: boolean;
  rounded?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      block = false,
      heavy = false,
      rounded = false,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'btn d-flex justify-content-center align-items-center',
          variants[variant],
          sizes[size],
          className,
          block ? 'btn-block' : '',
          heavy ? 'btn-heavy' : '',
          rounded ? 'btn-rounded' : ''
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" variant="light" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';
