import React, { useEffect, useState } from 'react';
import '../header.css';
import './userProfile.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/lib/auth';

import { HeaderType } from '@/components/Layout/Header/Header';
import SignInAccounts from '@/features/auth/components/SignInAccounts';
import NotificationData from './NotificationData';
import UserAuth from '@/components/Authentication/UserAuth';
import NotificationDataAdmin from './NotificationDataAdmin';
import NotificationDataAgency from './NotificationDataAgency';
import i18next from 'i18next';

const UserProfile = ({ type = HeaderType.LANDING }: { type?: HeaderType }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/auth/login');
  };
  const [val, setVal] = useState('');

  useEffect(() => {
    if (auth?.user?.role == 'admin') {
      setVal('admins');
    } else if (auth?.user?.role == 'agency') {
      setVal('agency');
    } else if (auth?.user?.role == 'lawyer') {
      setVal('lawyer');
    } else {
      setVal('all');
    }
  }, [auth?.user?.role]);

  return (
    <>
      <div className="d-flex dash-men">
        {auth.user ? (
          <>
            {val === 'admins' && <NotificationDataAdmin />}
            {val === 'all' && <NotificationData />}
            {val === 'agency' && <NotificationDataAgency />}
          </>
        ) : (
          <button
            className="d-flex sds justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon  bell"
            type="button"
            onClick={handleNavigate}
          >
            <i className="fa-regular fa-bell"></i>
          </button>
        )}
        {val === 'agency' || val === 'lawyer' ? null : (
          <UserAuth click={() => navigate('/user/favourite-list')}>
            {/* <UserAuth click={() => navigate('/')}> */}
            <button
              className="d-flex justify-content-center align-items-center text-black  px-3 text-md br-50 user-icon"
              type="button"
            >
              <i className="fa-regular fa-heart"></i>
            </button>
          </UserAuth>
        )}
        <div className="dropdown login-vendor">
          {val === 'agency' ? (
            <button
              className="d-flex justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon dropdown-toggle leftMr"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-regular fa-user" />
            </button>
          ) : (
            <button
              className="d-flex justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-regular fa-user" />
            </button>
          )}
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {false && type}
            {auth.user ? (
              <>
                {auth.user.role === 'admin' ? (
                  <>
                    <li>
                      <Link className="dropdown-item" to={`/admin`}>
                        <i className="fa-solid fa-gauge" /> {i18next.t('Dashboard')}
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    {auth.user.role == 'agency' ? (
                      <li>
                        <Link className="dropdown-item" to={`/agency`}>
                          <i className="fa-regular fa-user" /> {i18next.t('Agency')}
                        </Link>
                      </li>
                    ) : (
                      <>
                        {auth.user.role == 'lawyer' ? (
                          <li>
                            <Link className="dropdown-item" to={`/lawyer`}>
                              <i className="fa-regular fa-user" /> {i18next.t('Lawyer')}
                            </Link>
                          </li>
                        ) : (
                          <li>
                            <Link className="dropdown-item" to={`/user/profile`}>
                              <i className="fa-regular fa-user" /> {i18next.t('Profile')}
                            </Link>
                          </li>
                        )}
                      </>
                    )}
                  </>
                )}

                <li className="border-top">
                  <a onClick={() => auth.logout()} className="dropdown-item" href="#">
                    <i className="fa-solid fa-right-from-bracket" /> {i18next.t('Logout')}
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <SignInAccounts>
                    <a className="dropdown-item">
                      <i className="fa-solid fa-users"></i>
                      {i18next.t('Login user')}
                    </a>
                  </SignInAccounts>
                </li>
                <li>
                  <a
                    role="button"
                    className="dropdown-item"
                    onClick={() => navigate('/auth/login-agency')}
                  >
                    <i className="fa-solid fa-people-roof"></i> {i18next.t('Login Agency')}
                  </a>
                </li>
                <li>
                  <a
                    role="button"
                    className="dropdown-item"
                    onClick={() => navigate('/auth/login-lawyer')}
                  >
                    <i className="fa-solid fa-user-secret"></i> {i18next.t('Login Lawyer')}
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
