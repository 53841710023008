import { axios } from '@/lib/axios';

export type CompleteProfileDTO = {
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  dob: string;
  user_type: string;
  your_self: string;
  past_experience: string;
  company: string;
  profile_image: string;
};

export const completeAgencyProfile = (data: CompleteProfileDTO) => {
  return axios.post('agency-api/agnecy-complete-profile', data);
};
