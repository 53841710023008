/* eslint-disable react/jsx-key */
import { useEffect, useState } from 'react';
import Footer from '@/components/Layout/Footer/Footer';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import './landing.css';
import BackdropLoader from '@/components/Elements/Loader/BackdropLoader';
import PropertyForSaleItem from '@/features/user/components/PropertyForSaleItem';
import BreadCrum from './BreadCrum';
import { ContentLayout } from '@/components/Layout';
import { usePropertyForSale } from '@/api/propertyForSale/propertyForSale';
import MyListingLoader from '@/components/Elements/Loader/MyListingLoader';
import PFSSearchForm from './PFSSearchForm';
import { saveSearchApi } from '@/api/Search/saveSearchApi';
import { useNotificationStore } from '@/stores/notifications';
import ServiceItemList from '@/features/misc/routes/Landing/ServiceItemList';
import storage from '@/utils/storage';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Filter } from '../Landing/filterType';
import i18next from 'i18next';

export const PropertyForSale = () => {
  const [loading] = useState(false);
  const [heartClicked, setHeartClicked] = useState<boolean>();
  const [min_price, setMinPrice] = useState('');
  const [max_price, setMaxPrice] = useState('');
  const [min_beds, setMinBeds] = useState('');
  const [max_beds, setMaxBeds] = useState('');
  const [amenities, setAmenities] = useState('');
  const [location, setlocation] = useState('');
  const [search, setSearch] = useState(false);
  const [save, setSave] = useState(false);
  const [lat, setlat] = useState('');
  const [lng, setlng] = useState('');

  const [loadingSave, setLoadingSave] = useState(false);
  const [object, setObject] = useState<Filter | null>(null);
  const [saveSearch, setSaveSearch] = useState(false);
  const [clearSearchVal, setClearSearchVal] = useState(false);
  const type = '';

  const localCurrency = storage.getCurrency();

  // const fdata = localCurrency;
  // useEffect(() => {
  //   if (fdata !== localCurrency) {
  //     window.location.reload();
  //   }
  // }, [localCurrency]);

  // Create a URLSearchParams object with the query string
  const searchParams = new URLSearchParams(document.location.search);

  // Extract the values
  const locationValue = searchParams.get('location');
  const latitudeValue = searchParams.get('latitude');
  const longitudeValue = searchParams.get('longitude');
  const minPriceValue = searchParams.get('min_price');
  const maxPriceValue = searchParams.get('max_price');
  const minBedValue = searchParams.get('bedroom_min');
  const maxBedValue = searchParams.get('bedroom_max');
  const amenitiesValues = searchParams.get('amenities');
  const isTrue = searchParams.get('isTrue');
  const category = searchParams.get('type');

  const [selectedCountry, setCountry] = useState('');
  const [selectedState, setState] = useState('');
  const [selectedCity, setCity] = useState('');

  const handleCountryCheckboxChange = (stateName) => {
    if (selectedCountry) {
      setCountry('');
    } else {
      setCountry(stateName);
    }
  };
  const handleStateCheckboxChange = (stateName) => {
    if (selectedState) {
      setState('');
    } else {
      setState(stateName);
    }
  };
  const handleCityCheckboxChange = (stateName) => {
    if (selectedCity) {
      setCity('');
    } else {
      setCity(stateName);
    }
  };
  const countryFilter = selectedCountry.toString();
  const stateFilter = selectedState.toString();
  const cityFilter = selectedCity.toString();

  const type_url = `/property-for-sale?isTrue=true?type=RentProperty&location=${location}&latitude=${lat}&longitude=${lng}&min_price=${min_price}&max_price=${max_price}&bedroom_min=${min_beds}&bedroom_max=${max_beds}&amenities=${amenities}&category=${category}`;

  const dataToSave = {
    type,
    min_price,
    max_price,
    min_beds,
    max_beds,
    amenities,
    location,
    type_url,
  };

  useEffect(() => {
    if (clearSearchVal === true) {
      setMinPrice('');
      setMinPrice('');
      setlocation('');
      setlat('');
      setlng('');
      setSearch(false);
      setSave(false);
      setClearSearchVal(false);
    }
  }, [clearSearchVal]);

  useEffect(() => {
    if (min_price == undefined) {
      setMinPrice('');
    }
    if (max_price == undefined) {
      setMaxPrice('');
    }
  }, [min_price, max_price]);

  const {
    data: listing,
    isLoading,
    refetch,
    isFetching,
    isRefetching,
  } = usePropertyForSale({
    page: '1',
    min_price: clearSearchVal ? '' : isTrue ? minPriceValue : min_price,
    max_price: clearSearchVal ? '' : isTrue ? maxPriceValue : max_price,
    location: clearSearchVal ? '' : isTrue ? locationValue : location,
    latitude: clearSearchVal ? '' : isTrue ? (latitudeValue ? latitudeValue : '') : lat ? lat : '',
    longitude: clearSearchVal
      ? ''
      : isTrue
      ? longitudeValue
        ? longitudeValue
        : ''
      : lng
      ? lng
      : '',
    bedroom_min: clearSearchVal ? '' : isTrue ? minBedValue : min_beds,
    bedroom_max: clearSearchVal ? '' : isTrue ? maxBedValue : max_beds,
    amenities: clearSearchVal ? '' : isTrue ? amenitiesValues : amenities,
    filter_data: saveSearch,
    subscription_tyre: object?.subscriptionSort ? object?.subscriptionSort : '',
    sort_price: object?.priceSort ? object?.priceSort : '',
    stateFilter: stateFilter,
    countryFilter: countryFilter,
    cityFilter: cityFilter,
    category: `${category === null ? '' : category}`,
    sort_upload_date: object?.timeSort ? object?.timeSort : '',
  });

  console.log(
    selectedCountry,
    'selectedCountryselectedCountryselectedCountryselectedCountryselectedCountryselectedCountry'
  );

  useEffect(() => {
    refetch();
  }, [
    stateFilter,
    category,
    countryFilter,
    cityFilter,
    selectedCountry,
    selectedState,
    selectedCity,
  ]);

  const [datas, setDatas] = useState([]);
  const [datasCountry, setDatasCountry] = useState([]);
  const [datasState, setDatasState] = useState([]);
  const [datasCity, setDatasCity] = useState([]);
  const axiosUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${axiosUrl}/panel-api/sale-property?filter_data=${saveSearch}&subscription_tyre=${
            object?.subscriptionSort ? object?.subscriptionSort : ''
          }&sort_price=${
            object?.priceSort ? object?.priceSort : ''
          }&min_price=${min_price}&max_price=${max_price}&location=${location}&longitude=${
            lng ? lng : ''
          }&latitude=${
            lat ? lat : ''
          }&bedroom_min=${min_beds}&bedroom_max=${max_beds}&amenities=${amenities}&category=${
            category ? category : ''
          }&countryFilter=${countryFilter}&stateFilter=${stateFilter}&cityFilter=${cityFilter}`
        );
        setDatas(response?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [selectedCountry, category, selectedState]);

  useEffect(() => {
    setDatasCountry(datas?.country_data);
    setDatasState(datas?.state_data);
    setDatasCity(datas?.city_data);
  }, [datas]);

  const [clear, setClear] = useState(false);
  const [sendObj, setSendObj] = useState({});
  const obj = {
    priceSort: object?.priceSort,
    subscriptionSort: object?.subscriptionSort,
    timeSort: object?.timeSort,
  };
  const objNew = {
    priceSort: '',
    subscriptionSort: '',
    timeSort: '',
  };
  useEffect(() => {
    refetch();
    setHeartClicked(false);
    setSaveSearch(false);
    setSendObj(obj);
  }, [heartClicked, search, saveSearch, refetch, isTrue]);

  useEffect(() => {
    if (clearSearchVal === true) {
      refetch();
      setClearSearchVal(false);
    }
  }, [clearSearchVal]);

  useEffect(() => {
    setSaveSearch(false);
    setClear(false);
    refetch();
    setSendObj(objNew);
  }, [clear]);

  const handleSearchApi = async (objects: any) => {
    try {
      setLoadingSave(true);
      await saveSearchApi(objects);
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'You have saved this request successfully!',
      });
    } finally {
      setLoadingSave(false);
      setSaveSearch(false);
      setSave(false);
    }
  };

  useEffect(() => {
    if (save === true) {
      handleSearchApi(dataToSave);
    }
  }, [save]);

  useEffect(() => {
    refetch();
  }, [category]);

  // ------------------ Save search ----------------

  return (
    <>
      <ContentLayout title={i18next.t('Property for Sale')}>
        <BackdropLoader open={loading} />
        <Header type={HeaderType.LANDING} />
        <BreadCrum
          head={i18next.t('Discover Properties for Sale')}
          line2={i18next.t('With Room for Every Budget')}
          pragraf={i18next.t(
            'List and browse properties for free to find the best places, from city apartments to beautiful country estates.'
          )}
        />
        <PFSSearchForm
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          setMinBeds={setMinBeds}
          setMaxBeds={setMaxBeds}
          setAmenities={setAmenities}
          setlocation={setlocation}
          setSearch={setSearch}
          setSave={setSave}
          setlat={setlat}
          setlng={setlng}
          setClearSearchVal={setClearSearchVal}
        />

        <ServiceItemList
          setObject={setObject}
          setSaveSearch={setSaveSearch}
          linkAddress={'/Propert_sale_rent?page=Propert_sale_rent'}
        />
        {/* <Carousel /> */}
        <div className="container">
          <h4>{i18next.t('Properties')}</h4>
          <div className="row">
            {listing?.length == 0 ? (
              <h4 className="makeCenter">{i18next.t('No listing found !')}</h4>
            ) : (
              <>
                {isLoading || isFetching || isRefetching || !datas ? (
                  <MyListingLoader />
                ) : (
                  <>
                    <div className="row apart-row">
                      <div className="col-md-3 col-12 col-lg-2 bg-pink">
                        <h6>{i18next.t('Country')}</h6>
                        {datasCountry?.map((countryItem, index) => (
                          <div className="mapChecks" key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedCountry.includes(countryItem.country)}
                                  onChange={() => handleCountryCheckboxChange(countryItem.country)}
                                />
                              }
                              label={countryItem.country}
                            />
                            (<span>{countryItem?.total_properties}</span>)
                          </div>
                        ))}
                        {datasState ? datasState?.length > 0 ? <h6>State</h6> : null : null}
                        {datasState?.map((stateItem, index) => (
                          <div className="mapChecksState" key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedState.includes(stateItem.state)}
                                  onChange={() => handleStateCheckboxChange(stateItem.state)}
                                />
                              }
                              label={stateItem.state}
                            />
                            (<span>{stateItem?.total_properties}</span>)
                          </div>
                        ))}
                        {datasCity ? datasCity?.length > 0 ? <h6>City</h6> : null : null}

                        {datasCity?.map((cityItem, index) => (
                          <div className="mapChecks" key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedCity.includes(cityItem.city)}
                                  onChange={() => handleCityCheckboxChange(cityItem.city)}
                                />
                              }
                              label={cityItem.city}
                            />
                            (<span>{cityItem?.total_properties}</span>)
                          </div>
                        ))}
                      </div>
                      <div className="col-md-9 col-12 col-lg-10">
                        <div className="row">
                          {listing?.map((item, index) => (
                            <PropertyForSaleItem
                              key={index}
                              item={item}
                              deleteIcon={false}
                              headData={false}
                              editIcon={false}
                              setHeartClicked={setHeartClicked}
                              setDeleteClicked={undefined}
                              heartIcon={false}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <br />
        <Footer />
      </ContentLayout>
    </>
  );
};
