import { axios } from '@/lib/axios';
import { ApiResponse, RoleContent } from '@/types';

export type contentManagementDTO = {
  role_name: string;
  permission: any;
};

export const addRoles = (data: contentManagementDTO): Promise<ApiResponse<RoleContent>> => {
  return axios.post('/admin-api/add-role', data);
};
