import React, { useState } from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import Footer from '@/components/Layout/Footer/Footer';
import { useNotifications } from '@/api/Notification/getNotifications';
import { deleteSelectedNotifications } from '@/api/Notification/deleteNotifications';
import { readNotifications } from '@/api/Notification/readNotifications';
import notificationType from '@/constants/notificationType';
import NotifyAll from '@/features/user/components/NotifyAll';
import './Allnotification.css';
import { useNotificationStore } from '@/stores/notifications';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '@/components/Layout';
import NotificationLoader from '@/components/Elements/Loader/NotificationLoader';
import { Button } from '@/components/Elements/Button';
export const AllNotifiations = () => {
  const { data: notifications, refetch, isLoading } = useNotifications();
  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(9);

  const handleAdd = () => {
    setCount(count + 5);
  };
  const handleSub = () => {
    setCount(count - 5);
  };

  const handleSelect = (id: string) => {
    let currentSelected = [...selected];
    if (currentSelected.includes(id)) {
      currentSelected = currentSelected.filter((i) => i !== id);
    } else {
      currentSelected.push(id);
    }
    setSelected(currentSelected);
  };
  const selectAll = () => {
    if (notifications?.data?.length === selected.length) {
      setSelected([]);
      return;
    }
    const currentSelected = notifications?.data?.map((i) => i.id) ?? [];
    setSelected(currentSelected);
  };

  const deleteSelected = async () => {
    setLoading(true);
    await deleteSelectedNotifications(selected);
    refetch();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Notifications deleted successfully!',
    });
    setSelected([]);
    setLoading(false);
  };

  const readSelected = async () => {
    setLoading(true);
    await readNotifications(selected);
    refetch();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Notifications read successfully!',
    });
    setSelected([]);
    setLoading(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <ContentLayout title="Notifications">
        <Header type={HeaderType.LANDING} />
        <section className="container main-notify">
          {loading || isLoading ? (
            <NotificationLoader />
          ) : (
            <div className="notification-box">
              <div className="row lin">
                <div className="col-12 col-md-4 noti">Notifications</div>
                <div className="col-12 col-md-8 toper">
                  {selected.length > 0 && (
                    <>
                      <button onClick={deleteSelected}>
                        Delete {selected ? selected.length : null}
                      </button>
                      <button onClick={readSelected}>
                        Read {selected ? selected.length : null}
                      </button>
                    </>
                  )}
                  {notifications?.data?.length !== 0 && (
                    <button onClick={selectAll}>Select all</button>
                  )}
                </div>
              </div>
              <div className="notifies">
                {notifications?.data?.length === 0 && <p>No new notifications found</p>}
                <ul>
                  {notifications?.data?.slice(0, count)?.map((item) => (
                    <li key={item.id}>
                      {item.notification_type === notificationType.INQUIRE && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-leads')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.PROPERTY_PROJECT && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/property-project')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.CREATE_CONTRACT && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-deals')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.property_inquire && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-leads')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.contract_status && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-deals')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.start_contract && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-deals')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.contract_created && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-deals')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                      {item.notification_type === notificationType.booking_meeting && (
                        <button
                          className="notify-navigate-button"
                          onClick={() => navigate('/user/my-bookings')}
                        >
                          <NotifyAll item={item} selected={selected} onSelectItem={handleSelect} />
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {notifications?.data?.length !== 0 && (
                <div className="notifyBottom row">
                  {count <= 11 ? (
                    <>
                      {count > notifications?.data?.length ? null : (
                        <div>
                          <Button onClick={() => handleAdd()}>Show More</Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        {count <= 11 ? null : (
                          <Button onClick={() => handleSub()}>Show Less</Button>
                        )}
                      </div>
                      <div className="col-md-6">
                        {count >= notifications?.data?.length ? null : (
                          <Button onClick={() => handleAdd()}>Show More</Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </section>
        <br />
        <Footer />
      </ContentLayout>
    </>
  );
};
