const storagePrefix = 'eipo_';
// const Redirect = {
//   url: '',
//   type: '',
//   payload: '',
// };
const storage = {
  getEmail: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}email`) as string);
  },
  setEmail: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}email`, JSON.stringify(token));
  },
  clearEmail: () => {
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getCurrency: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}currency`) as string);
  },
  setCurrency: (currency: string) => {
    window.localStorage.setItem(`${storagePrefix}currency`, JSON.stringify(currency));
  },
  clearCurrency: () => {
    window.localStorage.removeItem(`${storagePrefix}currency`);
  },
  getAmount: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}amount`) as string);
  },
  setAmount: (currency: string) => {
    window.localStorage.setItem(`${storagePrefix}amount`, JSON.stringify(currency));
  },
  clearAmount: () => {
    window.localStorage.removeItem(`${storagePrefix}amount`);
  },
  getSymbol: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}Symbol`) as string);
  },
  setSymbol: (currency: string) => {
    window.localStorage.setItem(`${storagePrefix}Symbol`, JSON.stringify(currency));
  },
  clearSymbol: () => {
    window.localStorage.removeItem(`${storagePrefix}Symbol`);
  },
  getReloadFn: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}ReloadFn`) as string);
  },
  setReloadFn: (currency: string) => {
    window.localStorage.setItem(`${storagePrefix}ReloadFn`, JSON.stringify(currency));
  },
  clearReloadFn: () => {
    window.localStorage.removeItem(`${storagePrefix}ReloadFn`);
  },
  // setUrl: (token: string) => {
  //   window.localStorage.setItem(`${storagePrefix}email`, JSON.stringify(token));
  // },
  // setRedirect: ({ url, type, payload }: { url: string; type: string; payload: string }) => {
  //   window.localStorage.setItem(`${storagePrefix}Redirect`, JSON.stringify({ url, type, payload }));
  // },
  getFormData: () => {
    return JSON.parse(window.localStorage.getItem(`FormData`) as string);
  },
  setFormData: (token: any) => {
    window.localStorage.setItem(`FormData`, JSON.stringify(token));
  },
  clearFormData: () => {
    window.localStorage.removeItem(`FormData`);
  },
  setAddData: (token: any) => {
    window.localStorage.setItem(`AddData`, JSON.stringify(token));
  },

  getSubmitFormData: () => {
    return JSON.parse(window.localStorage.getItem(`formValue`) as string);
  },
  setSubmitFormData: (token: any) => {
    window.localStorage.setItem(`formValue`, JSON.stringify(token));
  },
  clearSubmitFormData: () => {
    window.localStorage.removeItem(`formValue`);
  },
};

export default storage;
