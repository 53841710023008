import { useNavigate } from 'react-router-dom';
import { Button, Link } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import * as z from 'zod';
import { useState } from 'react';
import { sendForgetPasswordLink } from '../api/forgetPassword';
import { useNotificationStore } from '@/stores/notifications';
import img from '@/assets/new-logo.png';
import i18next from 'i18next';

const schema = z.object({
  email: z
    .string()
    .min(1, 'Please enter email address')
    .max(35, 'Cannot exceed 35 characters')
    .email(),
});

type ForgetPasswordValues = {
  email: string;
};

export const ForgetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: ForgetPasswordValues) => {
    setLoading(true);
    try {
      const payload = { ...values, base_url: window.location.origin };
      await sendForgetPasswordLink(payload);
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: 'Reset password link has been sent to your email!',
      });
      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <img src={img} width="70" alt="alttext" />
      <h1 className="mb-0 mt-3">{i18next.t('Forgot Password')}</h1>
      <div className="mt-4">
        <Form<ForgetPasswordValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
          {({ register, formState }) => (
            <>
              <InputField
                type="email"
                label={i18next.t('Email Adress')}
                error={formState.errors['email']}
                registration={register('email')}
                placeholder={i18next.t('Email Address')}
              />
              <Button isLoading={loading} type="submit" className="w-full">
                {i18next.t('Send reset link')}
              </Button>
              <p className="text-center loggedIn text-muted">
                <Link to={`/auth/login`} className="text-decoration-none">
                  <i className="fa-solid fa-chevron-left pe-2" />
                  {i18next.t('Back to login')}
                </Link>
              </p>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
