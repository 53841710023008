import UserAuth from '@/components/Authentication/UserAuth';
import { Button } from '@/components/Elements';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';

const AddList = () => {
  const navigate = useNavigate();

  return (
    <UserAuth click={() => navigate('/user/add')}>
      {/* <Button size="sm" startIcon={<i className="fa-solid fa-plus" />}> */}
      <Button size="sm">{i18next.t('Add List')}</Button>
    </UserAuth>
  );
};

export default AddList;
