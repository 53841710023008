import { axios } from '@/lib/axios';

import { RegisterResponse } from '../types';

export type VerifyRegisterOtpDTO = {
  otp: string;
  email: string;
};

export const verifyAgencyRegisterOtp = (data: VerifyRegisterOtpDTO): Promise<RegisterResponse> => {
  return axios.post(`/agency-api/agency-verify-email`, data);
};
