import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const PropertyDetail = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <Skeleton variant="rounded" height={500}></Skeleton>
          </div>
          <div className="col-md-6 col-12 left-columns">
            <p className="property-name mt-2">
              <Skeleton variant="rounded" height={50} width={250}></Skeleton>
            </p>
            <p className="property-name mt-4">
              <Skeleton variant="rounded" height={50} width={250}></Skeleton>
            </p>

            <div className="row miniBox mt-4">
              <div className="col-4 col-md-4  mainBOx">
                <Skeleton variant="rounded" height={100}></Skeleton>
              </div>
              <div className="col-4 col-md-4  mainBOx">
                <Skeleton variant="rounded" height={100}></Skeleton>
              </div>
              <div className="col-4 col-md-4  mainBOx">
                <Skeleton variant="rounded" height={100}></Skeleton>
              </div>
            </div>
            <div className="mt-4">
              <Skeleton variant="rounded" height={100}></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="row main-datas col-6 col-md-6">
            <Skeleton variant="rounded" height={400}></Skeleton>
          </div>
          <div className="row main-datas col-6 col-md-6">
            <Skeleton variant="rounded" height={400}></Skeleton>
          </div>
        </div>
        <div className="row">
          <div className="row main-datas col-6 col-md-6">
            <Skeleton variant="rounded" height={400}></Skeleton>
          </div>
          <div className="row main-datas col-6 col-md-6">
            <Skeleton variant="rounded" height={400}></Skeleton>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <Skeleton variant="rounded" height={300}></Skeleton>
      </div>
    </>
  );
};

export default PropertyDetail;
