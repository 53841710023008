import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const AdminLoader = () => {
  return (
    <div>
      <div className="row p-4">
        <div className="col-1">
          <Skeleton variant="circular" width={100} height={100} />
        </div>
        <div className="col-11">
          <Skeleton variant="rectangular" height={100} />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <Skeleton variant="rectangular" height={600} />
        </div>
      </div>
    </div>
  );
};

export default AdminLoader;
