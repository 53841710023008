import { axios } from '@/lib/axios';

import { RegisterResponse } from '../types';

export type RegisterDTO = {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  password: string;
  isRoleAgency: boolean;
  loginUserType: string;
};

export const registerWithEmailAndPassword = (data: RegisterDTO): Promise<RegisterResponse> => {
  return axios.post('agency-api/agency-register', data);
};
