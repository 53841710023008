import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const RolesContent = () => {
  return (
    <div className="container">
      <div className="row mt-2">
        <div className="col-5">
          <div className="p-3 pb-0">
            <h5>
              <strong>
                {' '}
                <Skeleton variant="rectangular" height={50}></Skeleton>{' '}
              </strong>
            </h5>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="mt-2">
            <Skeleton variant="rectangular" height={50}></Skeleton>
          </div>
          <div className="mt-2">
            <Skeleton variant="rectangular" height={50}></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesContent;
