import { useQuery } from 'react-query';
import { axios } from '@/lib/axiosData';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Property } from '@/types';

export const propertyForSale = ({
  min_price,
  max_price,
  location,
  bedroom_min,
  bedroom_max,
  amenities,
  filter_data,
  latitude,
  longitude,
  subscription_tyre,
  sort_price,
  countryFilter,
  stateFilter,
  cityFilter,
  category,
  sort_upload_date,
}: {
  min_price: string | null;
  max_price: string | null;
  location: string | null;
  bedroom_min: string | null;
  bedroom_max: string | null;
  amenities: string | null;
  filter_data: boolean;
  subscription_tyre: string;
  latitude: string;
  longitude: string;
  sort_price: string;
  countryFilter: string;
  stateFilter: string;
  cityFilter: string;
  category: string | null;
  sort_upload_date: string;
}): Promise<Property[]> => {
  return axios.get(
    `/panel-api/sale-property?filter_data=${filter_data}&subscription_tyre=${subscription_tyre}&sort_price=${sort_price}&min_price=${min_price}&max_price=${max_price}&location=${location}&longitude=${longitude}&latitude=${latitude}&bedroom_min=${bedroom_min}&bedroom_max=${bedroom_max}&amenities=${amenities}&countryFilter=${countryFilter}&stateFilter=${stateFilter}&cityFilter=${cityFilter}&category=${category}&sort_upload_date=${sort_upload_date}`
  );
};

type QueryFnType = typeof propertyForSale;

type UseListingOptions = {
  config?: QueryConfig<QueryFnType>;
  min_price: string | null;
  max_price: string | null;
  location: string | null;
  bedroom_min: string | null;
  bedroom_max: string | null;
  amenities: string | null;
  filter_data: boolean;
  subscription_tyre: string;
  sort_price: string;
  latitude: string;
  longitude: string;
  countryFilter: string;
  stateFilter: string;
  cityFilter: string;
  category: string | null;
  sort_upload_date: string;
};

export const usePropertyForSale = ({
  config,
  min_price,
  max_price,
  location,
  bedroom_min,
  bedroom_max,
  amenities,
  filter_data,
  subscription_tyre,
  sort_price,
  latitude,
  longitude,
  countryFilter,
  stateFilter,
  cityFilter,
  category,
  sort_upload_date,
}: UseListingOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['property-for-sale'],
    queryFn: () =>
      propertyForSale({
        min_price,
        max_price,
        location,
        bedroom_min,
        bedroom_max,
        amenities,
        filter_data,
        latitude,
        longitude,
        subscription_tyre,
        sort_price,
        countryFilter,
        stateFilter,
        cityFilter,
        category,
        sort_upload_date,
      }),
  });
};
