import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
const googleMapAPIkey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
import { Libraries, LoadScript } from '@react-google-maps/api';
function App() {
  const libraries: Libraries = ['places'];
  return (
    <AppProvider>
      <LoadScript googleMapsApiKey={googleMapAPIkey} libraries={libraries}>
        <AppRoutes />
      </LoadScript>
    </AppProvider>
  );
}

export default App;
