import React, { useState } from 'react';
import searching from '@/assets/search-bg.jpg';
import './newLanding.css';
import { useNavigate } from 'react-router-dom';
import { SearchTabForm } from './SearchTabContent/SearchTabForm';

export const SearchTabs = ({ setApply }) => {
  const [activeCategory, setActiveCategory] = useState('House Rent'); // Default category
  const [searchVal, setSearchVal] = useState('');
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const handleSearchChange = (e) => {
    setSearchVal(e.target.value);
  };

  return (
    <section className="new-search relative text-white">
      <div className="container">
        <div
          className="search-content-bg rounded"
          style={{ backgroundImage: 'url(' + searching + ')' }}
        >
          <h3 className="f-30 bold text-white">Explore REEIPO: The future of property</h3>
          <p className="f-14">
            A platform where properties, projects and real estate opportunities meet connections
            determined to see them succeed in today's dynamic markets.
          </p>
          <div className="top-searching-tabs pt-4">
            <ul
              className="nav nav-tabs justify-content-center border-0 gap-2"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeCategory === 'HouseRent' ? 'active' : ''}`}
                  onClick={() => handleCategoryClick('HouseRent')}
                  type="button"
                  role="tab"
                  aria-selected={activeCategory === 'HouseRent'}
                >
                  House Rent
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeCategory === 'BussinessSale' ? 'active' : ''}`}
                  onClick={() => handleCategoryClick('BussinessSale')}
                  type="button"
                  role="tab"
                  aria-selected={activeCategory === 'BussinessSale'}
                >
                  Commercialism
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeCategory === 'PropertyProject' ? 'active' : ''}`}
                  onClick={() => handleCategoryClick('PropertyProject')}
                  type="button"
                  role="tab"
                  aria-selected={activeCategory === 'PropertyProject'}
                >
                  Property Project
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeCategory === 'RentProperty' ? 'active' : ''}`}
                  onClick={() => handleCategoryClick('RentProperty')}
                  type="button"
                  role="tab"
                  aria-selected={activeCategory === 'RentProperty'}
                >
                  Rent Property
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeCategory === 'InvestmentCompany' ? 'active' : ''}`}
                  onClick={() => handleCategoryClick('InvestmentCompany')}
                  type="button"
                  role="tab"
                  aria-selected={activeCategory === 'InvestmentCompany'}
                >
                  Investment Company
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${activeCategory === 'SaleProperty' ? 'active' : ''}`}
                  onClick={() => handleCategoryClick('SaleProperty')}
                  type="button"
                  role="tab"
                  aria-selected={activeCategory === 'SaleProperty'}
                >
                  Sale Property
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active">
                <SearchTabForm category={activeCategory} setApply={setApply} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
