import { Navigate, Route, Routes } from 'react-router-dom';

import { Login } from './Login';

export const AdminAuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/*" element={<Navigate to="/auth/admin/login" />} />
    </Routes>
  );
};
