import React from 'react';
import { Skeleton } from '@mui/material';
import './loader.css';

const ContentManagement = () => {
  return (
    <>
      <div className="mx-4 mt-4">
        <Skeleton variant="rectangular" height={50}></Skeleton>
      </div>
      <div>
        <div className="mx-4  mt-2">
          <Skeleton variant="rectangular" height={50}></Skeleton>
        </div>
        <div className="mx-4  mt-2">
          {' '}
          <Skeleton variant="rectangular" height={50}></Skeleton>
        </div>
      </div>
    </>
  );
};

export default ContentManagement;
