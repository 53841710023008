import React from 'react';
import './viewer.css';

const VideoViewer = ({ file }: { file: string }) => {
  return (
    <video width="400" height="100" controls>
      <track src="captions_en.vtt" kind="captions" label="english_captions" />
      <source src={file} type="video/mp4" />
      <source src={file} type="video/ogg" />
      Your browser does not support HTML video.
    </video>
  );
};

export default VideoViewer;
