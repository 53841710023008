import { Table, TableColumn } from '@/components/Elements';
import data from './data.json';
import { useNavigate } from 'react-router-dom';
import { AdminLayout } from '@/components/Layout';

type Content = {
  id: string;
  page: string;
  page_name: string;
};

const ContentManagement = () => {
  const navigate = useNavigate();
  const columns: TableColumn<Content>[] = [
    { title: 'Page Name', field: 'page_name' },
    {
      title: 'Action',
      field: 'page',
      Cell({ entry: { id, page } }) {
        return (
          <>
            <span
              role="button"
              tabIndex={-1}
              onKeyDown={() => navigate(`/admin/edit-content/${id}?pageName=${page}`)}
              onClick={() => {
                if (id == '9') {
                  navigate(`/admin/faq-content`);
                } else {
                  navigate(`/admin/edit-content/${id}?pageName=${page}`);
                }
              }}
              className="icon me-2"
              title="Edit"
            >
              <i className="fa-solid fa-pencil"></i>
            </span>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <>
        <AdminLayout title="Edit Content Management">
          <Table<Content>
            data={data || []}
            columns={columns}
            page={1}
            total={11}
            changePage={() => null}
          />
        </AdminLayout>
      </>
    </div>
  );
};

export default ContentManagement;
