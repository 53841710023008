import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNotifications } from '@/api/Notification/getNotifications';
import './NotificationData.css';
import notificationType from '@/constants/notificationType';
import Notify from '@/features/user/components/Notify';
import { Notification } from '@/types';
import { useAuth } from '@/lib/auth';
import { readNotifications } from '@/api/Notification/readNotifications';
import { useNotificationStore } from '@/stores/notifications';

function NotificationData() {
  const { data: notifications, refetch } = useNotifications();
  const auth = useAuth();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/auth/login');
  };
  // const count = notifications?.data?.length;
  const [count, setCount] = useState(0);
  useEffect(() => {
    const countUnRead = notifications?.data?.filter((i) => !i.is_read).length ?? 0;
    setCount(countUnRead);
  }, [notifications]);
  const selected = '';
  const readSelected = async () => {
    await readNotifications(selected);
    refetch();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Notifications read successfully!',
    });
  };

  return (
    <>
      <div className="dropdown login-vendor">
        {auth.user ? (
          <>
            <button
              className="d-flex justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon "
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-regular fa-bell"></i>
              {count == 0 ? '' : <span className="count-notification">{count}</span>}
            </button>
            <ul className="dropdown-menu notify-drop" aria-labelledby="dropdownMenuButton1">
              <div>
                <div className="notification-head">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h5>Notification</h5>
                    </div>
                    {notifications?.data?.length === 0 ? null : (
                      <div className="col-12 col-md-6">
                        <div className="row hr-notify">
                          <div className="col-md-6 col-6">
                            <button className="" onClick={readSelected}>
                              Read All
                            </button>
                          </div>
                          <div className="col-md-6 col-6">
                            <Link to={`/read-Notifications`}>View All</Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="notifies">
                  {notifications?.data?.length === 0 && (
                    <p className="nonotifications">No new notifications found</p>
                  )}
                  <ul>
                    {notifications?.data?.slice(0, 3).map((item: Notification) => (
                      <li key={item.id}>
                        {item.notification_type === notificationType.INQUIRE && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-leads')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.PROPERTY_PROJECT && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/property-project')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.CREATE_CONTRACT && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-deals')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.property_inquire && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-leads')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.contract_status && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-deals')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.start_contract && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-deals')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.property_meeting && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-deals')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.contract_created && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-deals')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                        {item.notification_type === notificationType.booking_meeting && (
                          <button
                            className="notify-navigate-button"
                            onClick={() => navigate('/user/my-bookings')}
                          >
                            <Notify item={item} />
                          </button>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </ul>
          </>
        ) : (
          <>
            <button
              className="d-flex sds justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon dropdown-toggle"
              type="button"
              onClick={handleNavigate}
            >
              <i className="fa-regular fa-bell"></i>
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default NotificationData;
