import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { Notification } from '@/types/Notification';
import { ApiResponse } from '@/types';

export const getAgencyNotifications = (): Promise<ApiResponse<Notification[]>> => {
  return axios.get('/agency-api/get-agency-notifications');
};
type QueryFnType = typeof getAgencyNotifications;
type UseNotificationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAgencyNotifications = ({ config }: UseNotificationOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['notifications-agency'],
    queryFn: () => getAgencyNotifications(),
  });
};
