import { axios } from '@/lib/axios';

export type inquiryAdDTO = {
  project_id: string;
  telephone: string;
  message: string;
};

export const inquiryPropertyForSale = (data: inquiryAdDTO) => {
  return axios.post('/customer-api/inquire-mail', data);
};
