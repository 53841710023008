import { useNotificationStore } from '@/stores/notifications';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { Layout } from '../components/Layout';

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'Token required to access this page',
      });
      navigate('/');
    }
  }, [navigate, token]);

  return (
    <Layout title="Register your account">
      <ResetPasswordForm token={token ?? ''} />
    </Layout>
  );
};
