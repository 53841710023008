import React, { useEffect, useState } from 'react';
import { Button } from '@/components/Elements/Button';
import TextField from '@mui/material/TextField';
import '../newLanding.css';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import i18next from 'i18next';
import { SelectField } from '@/components/Form';
import { MenuItem, Select } from '@mui/material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';
import chevronDown from "@/assets/chevronDown.png";
import buildBlocks from "@/assets/buildBlocks.png";
import locationIcon from "@/assets/location.png";

export const SearchCategory = ({ category, setApply }: any) => {
  const navigate = useNavigate();
  const [minPrice, setMin] = useState<number | null>(null);
  const [maxPrice, setMax] = useState<number | null>(null);
  const [location, setLocation] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [address, setAddress] = useState<string>('');
  const [locationGoogle, setLocationGoogle] = useState();
  const [value, setValue] = useState(null);
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');

  const googleMapAPIkey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMin(Number(event.target.value));
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMax(Number(event.target.value));
  };

  const handleChangeLocation = async (value: any) => {
    const placeId = value.value.place_id;
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${googleMapAPIkey}`
    );
    const location = data?.results[0]?.geometry?.location;
    const addressData = data?.results[0]?.formatted_address;

    if (location) {
      setLocationGoogle(location);
      setValue(value);
    }
    if (addressData) {
      setAddress(addressData);
    }
  };

  useEffect(() => {
    if (locationGoogle) {
      setlatitude(locationGoogle?.lat);
      setlongitude(locationGoogle?.lng);
    }
  }, [locationGoogle]);

  const handlePropertyTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPropertyType(event.target.value);
  };

  const handleSubscriptionTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event, "event------------->")
    setSubscriptionType(event.target.value);
  };

  const handleSubmit = () => {
    setApply({
      minPrice,
      maxPrice,
      location: address,
      propertyType,
      category,
      subscriptionType,
      latitude,
      longitude,
      disableSlider: true
    });
  };

  // ------------------- Menu Bar -------------------
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <section className="search-tabs-form relative">
      <div className="container">
        <div className='selectBlocks'>
            <div className='select-tag-wrapper position-relative'>
                <span className='selectIcon position-absolute'><img src={locationIcon} alt="location" className='d-block w-100' /></span>
                <select name="selectCity" id="selectCity">
                    <option value="">Search by City/Town</option>
                    <option value="capetown">Cape Town</option>
                </select>
                <span className='chevDown position-absolute'><img src={chevronDown} alt="chevronDown" className='d-block w-100' /></span>
            </div>
            <div className='select-tag-wrapper position-relative'>
                <span className='selectIcon position-absolute'><img src={buildBlocks} alt="location" className='d-block w-100' /></span>
                <select name="selectProperty" id="selectProperty">
                    <option value="">Select by Property Type</option>
                    <option value="apartment_for_sale">Apartment for sale</option>
                    <option value="Villa_house_for_sale">Villa/House for sale</option>
                    <option value="Townhouse">Rekkehus</option>
                </select>
                <span className='chevDown position-absolute'><img src={chevronDown} alt="chevronDown" className='d-block w-100' /></span>
            </div>
            <div className='select-tag-wrapper position-relative'>
                <span className='selectIcon position-absolute'><img src={buildBlocks} alt="location" className='d-block w-100' /></span>
                <select name="subsciptionType" id="subsciptionType">
                    <option value="">Subscription Type</option>
                    <option value="1">Basic</option>
                    <option value="2">Standard</option>
                    <option value="3">Premium</option>
                </select>
                <span className='chevDown position-absolute'><img src={chevronDown} alt="chevronDown" className='d-block w-100' /></span>
            </div>
            <div className='select-tag-wrapper position-relative range-tag-wrapper'>
                <span className='text'>Price Range</span>
                <input type="range" name="priceRange" id="priceRange" />
            </div>
            <div className='select-tag-wrapper position-relative button-tag-wrapper'>
                <button className="searchbtn">Search</button>
            </div>
        </div>
      </div>
    </section>
  );
};
