import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/Elements/Card';
import { Button } from '@/components/Elements/Button';
import { AdminLayout } from '@/components/Layout';
import './ContentManagement.css';
import { addFaq } from '@/features/admin/api/addFaq';
import * as z from 'zod';
import { Form, InputField } from '@/components/Form';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import i18next from 'i18next';

function AddFaq() {
  const schema = z.object({
    title: z.string().min(1, 'Please enter faq title'),
  });

  type ContactValues = {
    title: string;
  };
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState<any>(null);

  const handleChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setHtml(currentContentAsHTML);
  };

  const handleSubmit = async (values: ContactValues) => {
    setLoading(true);
    await addFaq({
      ...(values ?? ''),
      content: html ?? '',
    });
    setLoading(false);
    navigate('/admin/faq-content');
  };

  return (
    <>
      <AdminLayout title="Add Faq Content ">
        <Card shadow>
          <div className="p-3 pb-0">
            <h5>
              <strong> Add </strong>
            </h5>
          </div>

          <Form<ContactValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
            {({ register, formState }) => (
              <>
                <div className="mx-4">
                  <InputField
                    type="text"
                    label={i18next.t('Title')}
                    error={formState.errors['title']}
                    registration={register('title')}
                  />
                </div>
                <div className="mx-4">
                  <Editor editorState={editorState} onEditorStateChange={handleChange} />
                  <div className="d-flex justify-content-end my-5">
                    <Button onClick={() => navigate(-1)} variant="inverse" block className="me-3">
                      {i18next.t(`Cancel`)}
                    </Button>
                    <Button isLoading={loading} type="submit" block>
                      Save
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        </Card>
      </AdminLayout>
    </>
  );
}

export default AddFaq;
