import { axios } from '@/lib/axios';
import pageType from '@/constants/contentManagement';
import { InvestorContent } from '@/types/InvestorContent';
import { ApiResponse } from '@/types';

export type contentManagementDTO = {
  page_title: string;
  page_content: string;
};

export const postContent = (data: contentManagementDTO): Promise<ApiResponse<InvestorContent>> => {
  const searchParams = new URLSearchParams(document.location.search);
  const pageName = searchParams.get('pageName');
  switch (pageName) {
    case 'investor':
      return axios.post('/admin-api/investor-content-page', data);
    case 'Terms_And_Conditions':
      return axios.post('/admin-api/terms-and-conditions', data);
    case 'Privacy_Policy':
      return axios.post('/admin-api/privacy-policy', data);
    case pageType.project:
      return axios.post('/admin-api/project-content-page', data);
    case pageType.propertyDeveloper:
      return axios.post('/admin-api/property-content-page', data);
    case pageType.saleCondition:
      return axios.post('/admin-api/sale-condition-page', data);
    case pageType.howItWorks:
      return axios.post('/admin-api/how-it-work-content', data);
    case pageType.PropertySale:
      return axios.post('/admin-api/property-sale-rent-page', data);
    case pageType.PropertyProject:
      return axios.post('/admin-api/property-project-content', data);
    case pageType.businessForSale:
      return axios.post('/admin-api/bussiness-for-sale-page', data);
    case pageType.businessDeveloper:
      return axios.post('/admin-api/bussiness-developer-content', data);
    case pageType.faq:
      return axios.post('/admin-api/faq-listing', data);
    default:
      return axios.post('/admin-api/investor-content-page', data);
      break;
  }
};
