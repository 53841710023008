import { axios } from '@/lib/axios';
import { ApiResponse } from '@/types';
import { Currency } from '@/types/Currency';

export type contentManagementDTO = {
  symbol: string;
  name: string;
  converted_price: string;
};

export const addCurrency = (data: contentManagementDTO): Promise<ApiResponse<Currency>> => {
  return axios.post('/admin-api/add-currency', data);
};
