import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from '@/components/Elements/Card';
import { Button } from '@/components/Elements/Button';
import { AdminLayout } from '@/components/Layout';
import './RoleManagement.css';
import { addRoles } from '@/features/admin/api/addRoles';
import { editRoles } from '@/features/admin/api/editRoles';
import { getRoleData } from '@/features/admin/api/getRoleData';
import { usePermissions } from '@/features/admin/api/getPermissions';
import * as z from 'zod';
import { Form, InputField } from '@/components/Form';
import PermissionCategory from '@/constants/PermissionCategory';
import { MultiSelect } from 'react-multi-select-component';
import { RoleContent } from '@/types';
import RolesContent from '@/components/Elements/Loader/RolesContent';
import i18next from 'i18next';

function AddRole() {
  const { id } = useParams();
  const page = '10';
  const { data } = usePermissions({ page });
  const permissionList = data?.data;

  const [user, setUser] = useState<RoleContent>();
  const [isLoader, setisLoader] = useState<boolean>(false);

  const getDataOfApi = async () => {
    setisLoader(true);
    if (id != null) {
      const data = await getRoleData(id);
      const userIn = data?.data ?? ({} as RoleContent);
      setUser(userIn);
    }
    setisLoader(false);
  };

  const options = { ...user };

  useEffect(() => {
    getDataOfApi();
  }, [id]);

  const schema = z.object({
    role_name: z.string().min(1, 'Please enter role title'),
  });

  type ContactValues = {
    role_name: string;
    permission: string;
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const optionsList = permissionList?.map((item) => ({
    label: item.name,
    value: item.code_name,
  }));

  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionss = [{ label: 'User Management', value: 'user_management' }];

  const handleSelect = (selected: any) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    if (user && user.permission) {
      const facilites = user.permission.split(',');
      const selecte = facilites.map((i) => {
        return {
          label: PermissionCategory[i],
          value: i,
        };
      });
      setSelectedOptions(selecte);
    }
  }, [user]);

  const handleSubmit = async (values: ContactValues) => {
    setLoading(true);
    const permission = selectedOptions.map(({ value }) => `${value}`).join(',');
    {
      id
        ? await editRoles({ ...values, permission, id })
        : await addRoles({ ...values, permission });
    }
    setLoading(false);
    navigate('/admin/roles');
  };

  return (
    <>
      {isLoader ? (
        <RolesContent />
      ) : (
        <>
          <AdminLayout title="Role Content ">
            <Card shadow>
              <div className="p-3 pb-0">
                <h5>
                  <strong> Roles here </strong>
                </h5>
              </div>

              <Form<ContactValues, typeof schema>
                onSubmit={handleSubmit}
                schema={schema}
                options={{
                  defaultValues: options,
                }}
              >
                {({ register, formState }) => (
                  <>
                    <div className="mx-4">
                      <InputField
                        type="text"
                        label={i18next.t('Title')}
                        error={formState.errors['role_name']}
                        registration={register('role_name')}
                      />
                    </div>
                    <div className="mx-4">
                      <MultiSelect
                        options={optionsList ?? optionss}
                        value={selectedOptions}
                        onChange={handleSelect}
                        labelledBy="Select"
                      />
                    </div>
                    <div className="mx-4">
                      <div className="d-flex justify-content-end my-5">
                        <Button
                          onClick={() => navigate(-1)}
                          variant="inverse"
                          block
                          className="me-3"
                        >
                          {i18next.t(`Cancel`)}
                        </Button>
                        <Button isLoading={loading} type="submit" block>
                          Save
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </Card>
          </AdminLayout>
        </>
      )}
    </>
  );
}

export default AddRole;
