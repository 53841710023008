const serviceType: {
  [key: string]: string;
} = {
  BUSINESS_SALE: 'BusinessSale',
  SALE_PROPERTY: 'SaleProperty',
  RENT_PROPERTY: 'RentProperty',
  HOUSE_RENT: 'HouseRent',
  INVESTMENT_PROJECT: 'InvestmentProject',
  INVESTMENT_COMPANY: 'InvestmentCompany',
  PROPERTY_PROJECT: 'PropertyProject',
};
export default serviceType;
