import { lazyImport } from '@/utils/lazyImport';
import { MainLayout, SideNavigationItem } from '@/components/Layout';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  HomeIcon,
  UserIcon,
  FingerPrintIcon,
  PencilAltIcon,
  ClipboardListIcon,
  PresentationChartBarIcon,
  IdentificationIcon,
  UserAddIcon,
  FilterIcon,
  BriefcaseIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';

import { useAuth } from '@/lib/auth';
import AdminLoader from '@/components/Elements/Loader/AdminLoader';
import ContentManagement from './routes/ContentManagement/ContentManagement';
import EditContent from './routes/ContentManagement/EditContent';
import EditFaq from './routes/ContentManagement/EditFaq';
import AssignUsersRole from './routes/UserRoleManagement/AssignUsersRole';
import EditAssignUsersRole from './routes/UserRoleManagement/EditAssignUsersRole';
import AddFaq from './routes/ContentManagement/AddFaq';
import AddRole from './routes/roleManagement/AddRole';
import { SubscriptionAgency } from '../agency/routes/SubscriptionAgency/SubscriptionAgency';
import { Currency } from './routes/currencyManagement/currency';
import AddCurrency from './routes/currencyManagement/AddCurrency';

// import { EditUser } from './routes/User/EditUser';
// import { Profile } from './auth/routes/Profile';
// import { PropertySaleRentDetail } from './routes/PropertySaleRent/PropertySaleRentDetail';
// import { AddSubscription } from './routes/Subscription/AddSubscription';
// import { EditSubscription } from './routes/Subscription/EditSubscription';
// import { PropertyForRent } from './routes/PropertyForRent/PropertyForRent';
// import { PropertyForRentDetail } from './routes/PropertyForRent/PropertyForRentDetail';
// import { ProjectInvestment } from './routes/ProjectInvestment/ProjectInvestment';
// import { ProjectInvestmentDetail } from './routes/ProjectInvestment/ProjectInvestmentDetail';
// import { CompanyInvestment } from './routes/CompanyInvestment/CompanyInvestment';
// import { CompanyInvestmentDetail } from './routes/CompanyInvestment/CompanyInvestmentDetail';

const { EditUser } = lazyImport(() => import('./routes/User/EditUser'), 'EditUser');
const { Profile } = lazyImport(() => import('./auth/routes/Profile'), 'Profile');
const { PropertySaleRentDetail } = lazyImport(
  () => import('./routes/PropertySaleRent/PropertySaleRentDetail'),
  'PropertySaleRentDetail'
);
const { AddSubscription } = lazyImport(
  () => import('./routes/Subscription/AddSubscription'),
  'AddSubscription'
);
const { EditSubscription } = lazyImport(
  () => import('./routes/Subscription/EditSubscription'),
  'EditSubscription'
);

const { PropertyForRent } = lazyImport(
  () => import('./routes/PropertyForRent/PropertyForRent'),
  'PropertyForRent'
);
const { PropertyForRentDetail } = lazyImport(
  () => import('./routes/PropertyForRent/PropertyForRentDetail'),
  'PropertyForRentDetail'
);
const { ProjectInvestment } = lazyImport(
  () => import('./routes/ProjectInvestment/ProjectInvestment'),
  'ProjectInvestment'
);
const { ProjectInvestmentDetail } = lazyImport(
  () => import('./routes/ProjectInvestment/ProjectInvestmentDetail'),
  'ProjectInvestmentDetail'
);
const { CompanyInvestment } = lazyImport(
  () => import('./routes/CompanyInvestment/CompanyInvestment'),
  'CompanyInvestment'
);
const { CompanyInvestmentDetail } = lazyImport(
  () => import('./routes/CompanyInvestment/CompanyInvestmentDetail'),
  'CompanyInvestmentDetail'
);
// import { Faq } from './routes/ContentManagement/Faq';
// import { MyDealsAdminList } from './routes/MyDealsAdmin/MyDealsAdminList';
// import { MyDealsAdmin } from './routes/MyDealsAdmin/MyDealsAdmin';
// import { AdminReport } from './routes/AdminReport';
// import { Roles } from './routes/roleManagement/Roles';
// import { UserRoles } from './routes/UserRoleManagement/UserRoles';
// import { GeneralSetting } from './routes/GeneralSetting/GeneralSetting';
// import { TeamAgents } from './routes/TeamAgent/TeamAgents';
// import { AgencyMember } from './routes/AgencyMember/AgencyMember';
// import { AgencyMemberDetail } from './routes/AgencyMember/AgencyMemberDetail';

const { Faq } = lazyImport(() => import('./routes/ContentManagement/Faq'), 'Faq');
const { MyDealsAdminList } = lazyImport(
  () => import('./routes/MyDealsAdmin/MyDealsAdminList'),
  'MyDealsAdminList'
);
const { MyDealsAdmin } = lazyImport(
  () => import('./routes/MyDealsAdmin/MyDealsAdmin'),
  'MyDealsAdmin'
);
const { AdminReport } = lazyImport(() => import('./routes/AdminReport'), 'AdminReport');
const { Roles } = lazyImport(() => import('./routes/roleManagement/Roles'), 'Roles');
const { UserRoles } = lazyImport(
  () => import('./routes/UserRoleManagement/UserRoles'),
  'UserRoles'
);
const { GeneralSetting } = lazyImport(
  () => import('./routes/GeneralSetting/GeneralSetting'),
  'GeneralSetting'
);
const { TeamAgents } = lazyImport(() => import('./routes/TeamAgent/TeamAgents'), 'TeamAgents');
const { AgencyMember } = lazyImport(
  () => import('./routes/AgencyMember/AgencyMember'),
  'AgencyMember'
);
const { AgencyMemberDetail } = lazyImport(
  () => import('./routes/AgencyMember/AgencyMemberDetail'),
  'AgencyMemberDetail'
);

// import { PropertySaleRent } from './routes/PropertySaleRent/PropertySaleRent';
// import { AddSubscriptionAgency } from '../agency/routes/SubscriptionAgency/AddSubscriptionAgency';
// import { EditSubscriptionAgency } from '../agency/routes/SubscriptionAgency/EditSubscriptionAgency';
// import { LawyerList } from './routes/LawyerList/LawyerList';
// import { LawyerMemberDetail } from './routes/LawyerList/LawyerMemberDetail';
// import { AdminLawyerSubscription } from './routes/Subscription/AdminLawyerSubscription';
// import { AddLawyerSubscription } from './routes/Subscription/AddLawyerSubsciption';
// import { EditLawyerSubscription } from './routes/Subscription/EditLawyerSubscription';
// import { AdminAgencySubscription } from './routes/Subscription/AdminAgencySubcription';

const { AddSubscriptionAgency } = lazyImport(
  () => import('../agency/routes/SubscriptionAgency/AddSubscriptionAgency'),
  'AddSubscriptionAgency'
);
const { EditSubscriptionAgency } = lazyImport(
  () => import('../agency/routes/SubscriptionAgency/EditSubscriptionAgency'),
  'EditSubscriptionAgency'
);
const { LawyerList } = lazyImport(() => import('./routes/LawyerList/LawyerList'), 'LawyerList');
const { LawyerMemberDetail } = lazyImport(
  () => import('./routes/LawyerList/LawyerMemberDetail'),
  'LawyerMemberDetail'
);
const { AdminLawyerSubscription } = lazyImport(
  () => import('./routes/Subscription/AdminLawyerSubscription'),
  'AdminLawyerSubscription'
);
const { AddLawyerSubscription } = lazyImport(
  () => import('./routes/Subscription/AddLawyerSubsciption'),
  'AddLawyerSubscription'
);
const { EditLawyerSubscription } = lazyImport(
  () => import('./routes/Subscription/EditLawyerSubscription'),
  'EditLawyerSubscription'
);
const { AdminAgencySubscription } = lazyImport(
  () => import('./routes/Subscription/AdminAgencySubcription'),
  'AdminAgencySubscription'
);
const { Dashboard } = lazyImport(() => import('@/features/admin/routes'), 'Dashboard');
const { Users } = lazyImport(() => import('@/features/admin/routes'), 'Users');
const { Businesses } = lazyImport(() => import('@/features/admin/routes'), 'Businesses');
const { Business } = lazyImport(() => import('@/features/admin/routes'), 'Business');
const { User } = lazyImport(() => import('@/features/admin/routes'), 'User');
const { Subscription } = lazyImport(() => import('@/features/admin/routes'), 'Subscription');
const { AllNotifiationsAdmin } = lazyImport(
  () => import('@/features/admin/routes/NotificationAdmin/AllNotifiationsAdmin'),
  'AllNotifiationsAdmin'
);
const { Housing } = lazyImport(() => import('@/features/admin/routes'), 'Housing');
const { PropertySaleRent } = lazyImport(
  () => import('@/features/admin/routes'),
  'PropertySaleRent'
);
const { HousingDetail } = lazyImport(() => import('@/features/admin/routes'), 'HousingDetail');
const { AdminChangePassword } = lazyImport(
  () => import('./auth/routes/AdminResetPassword'),
  'AdminChangePassword'
);
const { Inquiries } = lazyImport(() => import('@/features/admin/routes'), 'Inquiries');
const { Inquiry } = lazyImport(() => import('@/features/admin/routes'), 'Inquiry');
const { Properties } = lazyImport(() => import('@/features/admin/routes'), 'Properties');
const { Property } = lazyImport(() => import('@/features/admin/routes'), 'Property');
const { PropertyProject } = lazyImport(() => import('@/features/admin/routes'), 'PropertyProject');
const { PropertyProjects } = lazyImport(
  () => import('@/features/admin/routes'),
  'PropertyProjects'
);
export const publicRoutes = [];
export const protectedRoutes = [];

const App = () => {
  const { user } = useAuth();

  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', to: '.', icon: HomeIcon },
    { name: 'Report', to: './admin-report', icon: HomeIcon },
    // { name: 'General Settings', to: './general-settings', icon: HomeIcon },
    { name: 'Profile', to: './profile', icon: UserIcon },
    { name: 'Role Management', to: './roles', icon: UserIcon },
    { name: 'User Role Management', to: './user-roles', icon: UserIcon },
    { name: 'Property Requests', to: './property-requests', icon: UserIcon },
    { name: 'User Listing', to: './users', icon: UserIcon },
    { name: 'Agency Listing', to: './agency-list', icon: UserIcon },
    { name: 'Subrcription Management', to: './subscription-management', icon: UserIcon },
    { name: 'Inquiries', to: './inquiries', icon: ClipboardListIcon },
    { name: 'Property Projects', to: './property-project-listings', icon: ClipboardListIcon },
    // { name: 'My Deals', to: './completed-deals-listings', icon: ClipboardListIcon },
    { name: 'Property For Sale', to: './property-Sale-Rent', icon: ClipboardListIcon },
    { name: 'Property For Rent', to: './property-for-Rent', icon: ClipboardListIcon },
    { name: 'Housing List', to: './housing-listings', icon: ClipboardListIcon },
    {
      name: 'Company Investment  List',
      to: './company-Investment-listings',
      icon: ClipboardListIcon,
    },
    {
      name: 'Project Invetment List',
      to: './project-investment-listings',
      icon: ClipboardListIcon,
    },
    { name: 'Business Listings', to: './business-listings', icon: ClipboardListIcon },
    { name: 'Property Listing', to: './property-listings', icon: ClipboardListIcon },
    { name: 'Change Password', to: './reset-password', icon: FingerPrintIcon },
    { name: 'Content Management', to: './content-management', icon: PencilAltIcon },
  ]);

  useEffect(() => {
    if (user?.permission?.includes('All')) {
      setNavigation([
        { name: 'Dashboard', to: '.', icon: HomeIcon },
        { name: 'Report', to: './admin-report', icon: PresentationChartBarIcon },
        { name: 'Property Requests', to: './property-requests', icon: UserIcon },
        // { name: 'General Settings', to: './general-settings', icon: HomeIcon },
        { name: 'User Subrcription', to: './subscription-management', icon: FilterIcon },
        { name: 'Agency Subscription', to: './agency-subsciption', icon: FilterIcon },
        { name: 'Lawyer Subscription', to: './lawyer-subsciption', icon: FilterIcon },
        { name: 'Role Management', to: './roles', icon: BriefcaseIcon },
        { name: 'Currency Management', to: './currency', icon: BriefcaseIcon },
        { name: 'Assign User Roles ', to: './user-roles', icon: UserAddIcon },
        { name: 'User Listing', to: './users', icon: IdentificationIcon },
        { name: 'Agency Listing', to: './agency-list', icon: UserIcon },
        { name: 'Lawyer Listing', to: './lawyer-list', icon: UserIcon },

        { name: 'Inquiries', to: './inquiries', icon: ClipboardListIcon },
        // { name: 'My Deals', to: './completed-deals-listings', icon: ClipboardListIcon },
        { name: 'Property Projects', to: './property-project-listings', icon: OfficeBuildingIcon },
        { name: 'Property For Sale', to: './property-Sale-Rent', icon: OfficeBuildingIcon },
        { name: 'Property For Rent', to: './property-for-Rent', icon: OfficeBuildingIcon },
        { name: 'Housing List', to: './housing-listings', icon: OfficeBuildingIcon },
        {
          name: 'Company Investment  List',
          to: './company-Investment-listings',
          icon: ClipboardListIcon,
        },
        {
          name: 'Project Invetment List',
          to: './project-investment-listings',
          icon: ClipboardListIcon,
        },
        { name: 'Business Listings', to: './business-listings', icon: ClipboardListIcon },
        { name: 'Property Listing', to: './property-listings', icon: OfficeBuildingIcon },
        { name: 'Content Management', to: './content-management', icon: PencilAltIcon },
        { name: 'Profile', to: './profile', icon: UserIcon },
        { name: 'Change Password', to: './reset-password', icon: FingerPrintIcon },
      ]);
    } else {
      setNavigation([
        { name: 'Dashboard', to: '.', icon: HomeIcon },
        { name: 'Report', to: './admin-report', icon: HomeIcon },
        ...(user?.permission?.includes('subscription_management')
          ? [{ name: 'Subrcription Management', to: './subscription-management', icon: UserIcon }]
          : []),
        ...(user?.permission?.includes('user_management')
          ? [{ name: 'Role Management', to: './roles', icon: UserIcon }]
          : []),
        ...(user?.permission?.includes('user_management')
          ? [{ name: 'User Role Management', to: './user-roles', icon: UserIcon }]
          : []),
        ...(user?.permission?.includes('user_management')
          ? [{ name: 'User Listing', to: './users', icon: UserIcon }]
          : []),
        ...(user?.permission?.includes('inquiries_management')
          ? [{ name: 'Inquiries', to: './inquiries', icon: ClipboardListIcon }]
          : []),
        // { name: 'My Deals', to: './comphttps://reeipo.com/leted-deals-listings', icon: ClipboardListIcon },
        { name: 'Property For Sale', to: './property-Sale-Rent', icon: ClipboardListIcon },

        {
          name: 'Company Investment List',
          to: './company-Investment-listings',
          icon: ClipboardListIcon,
        },
        {
          name: 'Project Investment List',
          to: './project-investment-listings',
          icon: ClipboardListIcon,
        },

        ...(user?.permission?.includes('cohttps://reeipo.com/ntent_management')
          ? [{ name: 'Content Management', to: './content-management', icon: PencilAltIcon }]
          : []),

        ...(user?.permission?.includes('property_management')
          ? [{ name: 'Property Listing', to: './property-listings', icon: ClipboardListIcon }]
          : []),

        ...(user?.permission?.includes('property_project_management')
          ? [
              {
                name: 'Property Projects',
                to: './property-project-listings',
                icon: ClipboardListIcon,
              },
            ]
          : []),

        ...(user?.permission?.includes('property_rent_management')
          ? [{ name: 'Property For Rent', to: './property-for-Rent', icon: ClipboardListIcon }]
          : []),
        ...(user?.permission?.includes('housing_management')
          ? [{ name: 'Housing List', to: './housing-listings', icon: ClipboardListIcon }]
          : []),

        ...(user?.permission?.includes('business_management')
          ? [{ name: 'Business Listings', to: './business-listings', icon: ClipboardListIcon }]
          : []),

        { name: 'Profile', to: './profile', icon: UserIcon },
        { name: 'Change Password', to: './reset-password', icon: FingerPrintIcon },
      ]);
    }
  }, [user?.permission]);
  //reeipo.com/
  navigation.filter(Boolean) as SideNavigationItem[];

  return (
    <MainLayout navigation={navigation}>
      <Suspense
        fallback={
          <div className="h-full w-full">
            <AdminLoader />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
export const adminRoutes = [
  {
    path: '/admin',
    element: <App />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'admin-report', element: <AdminReport /> },
      { path: 'property-requests', element: <TeamAgents /> },
      { path: 'general-settings', element: <GeneralSetting /> },
      { path: 'profile', element: <Profile /> },
      { path: 'reset-password', element: <AdminChangePassword /> },
      { path: 'content-management', element: <ContentManagement /> },
      { path: 'edit-content', element: <EditContent /> },
      { path: 'faq-content', element: <Faq /> },
      { path: 'faq-content-edit', element: <EditFaq /> },
      { path: 'faq-content-edit/:id', element: <EditFaq /> },
      { path: 'add-faq-content', element: <AddFaq /> },
      { path: 'add-role-content', element: <AddRole /> },
      { path: 'agency-subsciption', element: <AdminAgencySubscription /> },
      { path: 'agency-add-subscription', element: <AddSubscriptionAgency /> },
      { path: 'agency-edit-subscription/:id', element: <EditSubscriptionAgency /> },
      { path: 'lawyer-subsciption', element: <AdminLawyerSubscription /> },
      { path: 'lawyer-add-subscription', element: <AddLawyerSubscription /> },
      { path: 'lawyer-edit-subscription/:id', element: <EditLawyerSubscription /> },
      { path: 'add-user-roles', element: <AssignUsersRole /> },
      { path: 'edit-user-roles/:id', element: <EditAssignUsersRole /> },
      { path: 'add-role-content/:id', element: <AddRole /> },
      { path: 'add-currency-content', element: <AddCurrency /> },
      { path: 'add-currency-content/:id', element: <AddCurrency /> },
      { path: 'faq-content/:id', element: <EditContent /> },
      { path: 'edit-content/:id', element: <EditContent /> },
      { path: 'users', element: <Users /> },
      { path: 'agency-list', element: <AgencyMember /> },
      { path: 'agencyDetail/:id', element: <AgencyMemberDetail /> },
      { path: 'lawyer-list', element: <LawyerList /> },
      { path: 'lawyerDetail/:id', element: <LawyerMemberDetail /> },
      { path: 'roles', element: <Roles /> },
      { path: 'currency', element: <Currency /> },
      { path: 'user-roles', element: <UserRoles /> },
      { path: 'subscription-management', element: <Subscription /> },
      { path: 'admin-notification', element: <AllNotifiationsAdmin /> },
      { path: 'add-subscription', element: <AddSubscription /> },
      { path: 'edit-subscription', element: <EditSubscription /> },
      { path: 'edit-subscription/:id', element: <EditSubscription /> },
      { path: 'users/:id', element: <User /> },
      { path: 'edit-item/:id', element: <EditUser /> },
      { path: 'business-listings', element: <Businesses /> },
      { path: 'business-listings/:id', element: <Business /> },
      { path: 'company-Investment-listings', element: <CompanyInvestment /> },
      { path: 'company-Investment-detail/:id', element: <CompanyInvestmentDetail /> },
      { path: 'property-Sale-Rent', element: <PropertySaleRent /> },
      { path: 'property-Sale-Rent-detail/:id', element: <PropertySaleRentDetail /> },
      { path: 'property-for-Rent', element: <PropertyForRent /> },
      { path: 'property-for-Rent-detail/:id', element: <PropertyForRentDetail /> },
      { path: 'housing-listings', element: <Housing /> },
      { path: 'housing-listings-detail/:id', element: <HousingDetail /> },
      { path: 'project-investment-listings', element: <ProjectInvestment /> },
      { path: 'project-investment-detail/:id', element: <ProjectInvestmentDetail /> },
      { path: 'inquiries', element: <Inquiries /> },
      { path: 'inquiry/:id', element: <Inquiry /> },
      { path: 'completed-deals-listings', element: <MyDealsAdminList /> },
      { path: 'completed-deals-details/:id', element: <MyDealsAdmin /> },
      { path: 'completed-deals-details', element: <MyDealsAdmin /> },
      { path: 'property-project-listings', element: <PropertyProjects /> },
      { path: 'property-project/:id', element: <PropertyProject /> },
      { path: 'property-listings', element: <Properties /> },
      { path: 'property/:id', element: <Property /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
