const firebaseConfig = {
  apiKey: 'AIzaSyAcKGomlyPEjvZYRtxcmwPjOqfIAV1JfRU',
  authDomain: 'reeipo-3cc28.firebaseapp.com',
  projectId: 'reeipo-3cc28',
  storageBucket: 'reeipo-3cc28.appspot.com',
  messagingSenderId: '594059499512',
  appId: '1:594059499512:web:195dc95eaa7a8f7772280d',
  measurementId: 'G-4X7RFT1QCZ',
};

export default firebaseConfig;
