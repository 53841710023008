import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAdminNotifications } from '@/features/admin/api/Notification/getadminNotifications';
import './NotificationData.css';
import notificationType from '@/constants/notificationType';
import Notify from '@/features/user/components/Notify';
import { Notification } from '@/types';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';
import { readNotificationsAgency } from '@/features/agency/api/Notification/readNotificationsAgency';

function NotificationDataAdmin() {
  const { data: notifications, refetch, isFetching, isLoading } = useAdminNotifications();
  const auth = useAuth();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/auth/login');
  };
  // const count = notifications?.data?.length;
  const [count, setCount] = useState(0);
  useEffect(() => {
    const countUnRead = notifications?.data?.filter((i) => !i.is_read).length ?? 0;
    setCount(countUnRead);
  }, [notifications]);
  const selected = '';
  const readSelected = async () => {
    await readNotificationsAgency(selected);
    refetch();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Notifications read successfully!',
    });
  };

  return (
    <>
      {isFetching || isLoading ? (
        <button
          className="d-flex justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon "
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa-regular fa-bell"></i>
        </button>
      ) : (
        <div className="dropdown login-vendor">
          {auth.user ? (
            <>
              <button
                className="d-flex justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-regular fa-bell"></i>
                {count == 0 ? '' : <span className="count-notification">{count}</span>}
              </button>
              <ul className="dropdown-menu notify-drop" aria-labelledby="dropdownMenuButton1">
                <div>
                  <div className="notification-head">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h5>Notification</h5>
                      </div>
                      {notifications?.data?.length === 0 ? null : (
                        <div className="col-12 col-md-6">
                          <div className="row hr-notify">
                            <div className="col-md-6 col-6">
                              <button className="" onClick={readSelected}>
                                Read All
                              </button>
                            </div>
                            <div className="col-md-6 col-6">
                              <Link to={`/admin/admin-notification`}>View All</Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="notifies">
                    {notifications?.data?.length === 0 && (
                      <p className="nonotifications">No new notifications found</p>
                    )}
                    <ul>
                      {notifications?.data?.slice(0, 3).map((item: Notification) => (
                        <li key={item.id}>
                          {item.notification_type === notificationType.inquire && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/inquiries')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.property_project && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/property-project-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.contract_created && (
                            <button
                              className="notify-navigate-button"
                              // onClick={() => navigate('/admin/')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.bussiness_sale && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/business-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.property_meeting && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/business-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}

                          {item.notification_type === notificationType.new_registered && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/admin-report')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.company_registered && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/admin-report')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.complete_profile && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/profile')}
                            >
                              <Notify item={item} />
                            </button>
                          )}

                          {item.notification_type === notificationType.property_inquire && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/inquiries')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.contract_status && (
                            <button
                              className="notify-navigate-button"
                              // onClick={() => navigate('/admin/')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.start_contract && (
                            <button
                              className="notify-navigate-button"
                              // onClick={() => navigate('/admin/')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.contact_us && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/admin-report')}
                            >
                              <Notify item={item} />
                            </button>
                          )}

                          {item.notification_type === notificationType.rent_property && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/property-for-Rent')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.sale_property && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/property-Sale-Rent')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.house_rent && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/housing-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.investment_project && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/project-investment-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.investment_company && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/company-Investment-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                          {item.notification_type === notificationType.upload_property_request && (
                            <button
                              className="notify-navigate-button"
                              onClick={() => navigate('/admin/property-listings')}
                            >
                              <Notify item={item} />
                            </button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </ul>
            </>
          ) : (
            <>
              <button
                className="d-flex sds justify-content-center align-items-center text-black py-2 pe-3 text-md br-50 user-icon dropdown-toggle"
                type="button"
                onClick={handleNavigate}
              >
                <i className="fa-regular fa-bell"></i>
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default NotificationDataAdmin;
