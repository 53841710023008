import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const NotificationLoader = () => {
  return (
    <div>
      <div className="notification-box">
        <div className="row lin">
          <div className="col-4 col-md-4 noti">Notifications</div>
          <div className="col-8 col-md-8 toper">
            <div className="col-3 ms-3">
              <Skeleton variant="rounded" height={50} />
            </div>

            <div className="col-3 ms-3">
              <Skeleton variant="rounded" height={50} />
            </div>

            <div className="col-3 ms-3">
              <Skeleton variant="rounded" height={50} />
            </div>
          </div>
        </div>
        <div className="notifies">
          <p className="p-2">
            <Skeleton variant="rounded" height={50} />
          </p>

          <p className="p-2">
            <Skeleton variant="rounded" height={50} />
          </p>

          <p className="p-2">
            <Skeleton variant="rounded" height={50} />
          </p>

          <p className="p-2">
            <Skeleton variant="rounded" height={50} />
          </p>

          <p className="p-2">
            <Skeleton variant="rounded" height={50} />
          </p>

          <p className="p-2">
            <Skeleton variant="rounded" height={50} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationLoader;
