import { lazyImport } from '@/utils/lazyImport';
import { MainLayout, SideNavigationItem } from '@/components/Layout';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserIcon, FingerPrintIcon, ClipboardListIcon, FilterIcon } from '@heroicons/react/outline';
import AdminLoader from '@/components/Elements/Loader/AdminLoader';
import { useAuth } from '@/lib/auth';

// import { SubscriptionType } from './routes/SubscriptionAgency/SubscriptionType';
// import { LawyerContractDetails } from './components/LawyerContractDetails';
// import { StripePayment } from './routes/Stripe/StripePayment';
// import { SubScriptionPayment } from './routes/SubscriptionAgency/SubScriptionPayment';
// import { LawyerProfile } from './auth/routes/LawyerProfile';
// import { LawyerResetPassword } from './auth/routes/LawyerResetPassword';
// import { Inquiries } from './routes';

const { LawyerContractDetails } = lazyImport(
  () => import('./components/LawyerContractDetails'),
  'LawyerContractDetails'
);
const { SubscriptionType } = lazyImport(
  () => import('./routes/SubscriptionAgency/SubscriptionType'),
  'SubscriptionType'
);
const { StripePayment } = lazyImport(
  () => import('./routes/Stripe/StripePayment'),
  'StripePayment'
);

const { SubScriptionPayment } = lazyImport(
  () => import('./routes/SubscriptionAgency/SubScriptionPayment'),
  'SubScriptionPayment'
);
const { LawyerProfile } = lazyImport(() => import('./auth/routes/LawyerProfile'), 'LawyerProfile');
const { LawyerResetPassword } = lazyImport(
  () => import('./auth/routes/LawyerResetPassword'),
  'LawyerResetPassword'
);
const { Inquiries } = lazyImport(() => import('./routes'), 'Inquiries');

const { AllNotifiationsAgency } = lazyImport(
  () => import('@/features/agency/routes/NotificationAgency/AllNotifiationsAgency'),
  'AllNotifiationsAgency'
);

export const publicRoutes = [];
export const protectedRoutes = [];
const App = () => {
  const auth = useAuth();
  const [hasSub, setSubscription] = useState<boolean>(false);
  useEffect(() => {
    if (auth?.user?.has_subscription === true) {
      setSubscription(true);
    }
  }, [auth?.user?.has_subscription]);

  const navigation = [
    { name: 'Profile', to: './profile', icon: UserIcon },
    { name: 'Contract List', to: './inquiries', icon: ClipboardListIcon },
    { name: 'Subscription Type', to: './subscription-type', icon: FilterIcon },
    { name: 'Change Password', to: './reset-password', icon: FingerPrintIcon },
  ];

  navigation.filter(Boolean) as SideNavigationItem[];

  return (
    <MainLayout navigation={navigation}>
      <Suspense
        fallback={
          <div className="h-full w-full">
            <AdminLoader />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
export const lawyerRoutes = [
  {
    path: '/lawyer',
    element: <App />,
    children: [
      { path: '', element: <LawyerProfile /> },
      { path: 'profile', element: <LawyerProfile /> },
      { path: 'inquiries', element: <Inquiries /> },
      { path: 'reset-password', element: <LawyerResetPassword /> },
      { path: '*', element: <Navigate to="." /> },
      { path: 'agency-notification', element: <AllNotifiationsAgency /> },
      { path: 'subscription-type', element: <SubscriptionType /> },
      { path: 'subscription-select-payment/:id', element: <SubScriptionPayment /> },
      { path: 'subscription-payment/:id', element: <StripePayment /> },
      { path: 'contract-details-page/:id', element: <LawyerContractDetails /> },
    ],
  },
];
