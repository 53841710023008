import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const TermsLoader = () => {
  return (
    <div>
      <div className="notification-box">
        <div className="col-4 col-md-4 noti">
          <Skeleton variant="rounded" height={50} />
        </div>

        <div className=" lin">
          <div className="mt-5 ms-3">
            <Skeleton variant="rounded" height={150} />
          </div>

          <div className=" ms-3">
            <Skeleton variant="rounded" height={150} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsLoader;
