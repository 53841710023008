import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const MyListingLoader = () => {
  return (
    <div className="row mt-2">
      <div className="col-4">
        <Skeleton variant="rectangular" height={500}></Skeleton>
      </div>
      <div className="col-4">
        <Skeleton variant="rectangular" height={500}></Skeleton>
      </div>
      <div className="col-4">
        <Skeleton variant="rectangular" height={500}></Skeleton>
      </div>

    </div>
  );
};

export default MyListingLoader;
