import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const NewListingLoader = () => {
    return (
        <div className="row mt-2">
            <div className="col-3">
                <Skeleton variant="rectangular" height={250}></Skeleton>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="rectangular" width={200} height={40} />
                <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} width={180} />
            </div>
            <div className="col-3">
                <Skeleton variant="rectangular" height={250}></Skeleton>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="rectangular" width={200} height={40} />
                <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} width={180} />
            </div>
            <div className="col-3">
                <Skeleton variant="rectangular" height={250}></Skeleton>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="rectangular" width={200} height={40} />
                <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} width={180} />
            </div>
            <div className="col-3">
                <Skeleton variant="rectangular" height={250}></Skeleton>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="rectangular" width={200} height={40} />
                <Skeleton variant="text" sx={{ fontSize: '1.3rem' }} width={180} />
            </div>

        </div>
    );
};

export default NewListingLoader;
