import React, { useState, useEffect } from 'react';
import logo from '@/assets/logofooter.png';
import pay1 from '@/assets/pay1.png';
import pay2 from '@/assets/pay2.png';
import pay3 from '@/assets/pay3.png';
import './Footer.css';

import { newsLetter } from '@/api/newsLetter';
import { useNotificationStore } from '@/stores/notifications';

import BackdropLoader from '@/components/Elements/Loader/BackdropLoader';
import i18next from 'i18next';

const Footer = () => {
  const [toggle, setToggle] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const [email, setEmail] = useState('');
  const [loadings, setLoadings] = useState(false);
  const [error, setError] = useState('');

  const validateEmail = () => {
    // Implement your email validation logic here
    return /\S+@\S+\.\S+/.test(email);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const xyz = () => {
    if (windowDimensions?.width > 768) {
      setToggle(true);
    } else {
      setToggle(!toggle);
    }
  };

  useEffect(() => {
    if (windowDimensions?.width > 768) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [windowDimensions]);

  /* quick menu */
  const quick = () => {
    if (windowDimensions?.width > 768) {
      setIsVisible(true);
    } else {
      setIsVisible(!isVisible);
    }
  };
  useEffect(() => {
    if (windowDimensions?.width > 768) {
      setIsVisible(true);
    } else {
      setIsVisible(!isVisible);
    }
  }, [windowDimensions]);

  /* Downlaod */
  const download = () => {
    if (windowDimensions?.width > 768) {
      setIsDownload(true);
    } else {
      setIsDownload(!isDownload);
    }
  };
  useEffect(() => {
    if (windowDimensions?.width > 768) {
      setIsDownload(true);
    } else {
      setIsDownload(!isVisible);
    }
  }, [windowDimensions]);

  const handleSubmit = async () => {
    if (!validateEmail()) {
      setError('Invalid email address');
      return;
    }

    setLoadings(true);
    try {
      await newsLetter(email);
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: 'Newsletter Suscribed!',
      });
      setEmail('');
      setError('');

      // Show success message or perform any other actions
      console.log('Newsletter subscribed successfully!');
    } catch (error) {
      // Handle errors, e.g., show an error message
      console.error('Error subscribing to newsletter:', error);
    } finally {
      setLoadings(false);
    }
  };
  return (
    <div>
      <BackdropLoader open={loadings} />
      <footer style={{ background: `#1a1818`, padding: `70px`, borderTop: '2px solid #46464e' }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="footer-col">
                <div className="footer-logo">
                  <a className="navbar-brand" href="#">
                    <img className="logoFooter" src={logo} alt="logo" />
                  </a>
                </div>
                <p className="text-white f-14 pt-3">
                  REEIPO brings those with a passion for property and investing together. Our
                  pltform helps everyone buy, sell, and invest in projects that match their needs.
                </p>
                <div className="payment">
                  Payment :
                  <div className="xy">
                    <div className="xd col-sm-3">
                      <img src={pay1} alt="pay" />
                    </div>
                    <div className="xd col-sm-3">
                      <img src={pay2} alt="pay" />
                    </div>
                    <div className="xd col-sm-3">
                      <img src={pay3} alt="pay" />
                    </div>
                    <div className="xd col-sm-3">
                      <img src={pay1} alt="pay" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="footer-col right-space">
                    <div className="footer-title" onClick={() => xyz()}>
                      <h4 className="f-18 text-white pb-4">{i18next.t('About Us')}</h4>
                    </div>
                    <div>
                      {toggle && (
                        <ul className="menu-listing f-14">
                          <li>
                            <a className="text-white" href="/how_It_work?page=how_It_work">
                              How It work
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/sales_condition">
                              Sales and Conditions
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/contact-us">
                              {i18next.t('Contact')} Us
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/about-us">
                              {i18next.t('About Us')}
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="footer-col  left-space">
                    <div className="footer-title" onClick={() => quick()}>
                      <h4 className="f-18 text-white pb-4">Quick Links</h4>
                    </div>

                    <div className="quick-links">
                      {isVisible && (
                        <ul className={isVisible ? 'visible menu-listing f-14' : 'hidden'}>
                          <li>
                            <a className="text-white" href="/">
                              Home
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/property-project">
                              Property Projects
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/property-for-sale">
                              Properties For Sales
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/property-for-rent">
                              Properties for rent
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/our-service">
                              {i18next.t('Reeipo Services')}
                            </a>
                          </li>

                          <li>
                            <a
                              className="text-white"
                              href="/terms-conditions?page=Terms_And_Conditions"
                            >
                              Terms Conditions
                            </a>
                          </li>
                          <li>
                            <a className="text-white" href="/privacy-policy?page=Privacy_Policy">
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="footer-col  left-space">
                <div className="footer-title" onClick={() => download()}>
                  <h4 className="f-18 text-white pb-4">Download App</h4>
                </div>
                {isDownload && (
                  <ul className={isDownload ? 'visible address-listing f-14' : 'hidden'}>
                    <li>
                      <div>
                        <a
                          href="#"
                          target="_blank"
                          className="market-btn google-btn"
                          role="button"
                          rel="noreferrer"
                        >
                          <span className="market-button-subtitle">Download on the</span>
                          <span className="market-button-title">Google Play</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        <a
                          href="#"
                          target="_blank"
                          className="market-btn apple-btn"
                          role="button"
                          rel="noreferrer"
                        >
                          <span className="market-button-subtitle">Download on the</span>
                          <span className="market-button-title">App Store</span>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="input-group suprt">
                        <div className="form-outline">
                          <div className="">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                placeholder="Newsletter"
                                aria-label="Newsletter"
                                aria-describedby="basic-addon2"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={handleSubmit}
                                  disabled={loadings}
                                >
                                  <i className="fa-solid fa-arrow-right"></i>{' '}
                                </button>
                              </div>
                            </div>
                            {error && <div className="invalid-feedback">{error}</div>}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section
        className="bottom-footer"
        style={{ background: `#1a1818`, padding: `20px`, borderTop: '2px solid #292727' }}
      >
        <div className="container">
          <div className="bottom-content d-flex align-items-center justify-content-between">
            <div className="left-footer">
              <a href="#" className="f-14 text-white">
                www.company.com
              </a>
            </div>
            <div className="right-footer">
              <p className="f-14 text-white mb-0">
                Follow us :
                <span className="footerIcons">
                  <i className="fa-brands fa-facebook"></i>
                </span>
                <span className="footerIcons">
                  <i className="fa-brands fa-twitter"></i>
                </span>
                <span className="footerIcons">
                  <i className="fa-brands fa-instagram"></i>
                </span>
                <span className="footerIcons">
                  <i className="fa-brands fa-youtube"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Footer;
