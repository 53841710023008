import { axios } from '@/lib/axios';
import { ApiResponse, SubAdminData } from '@/types';

export type contentManagementDTO = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
};

export const addRoles = (data: contentManagementDTO): Promise<ApiResponse<SubAdminData>> => {
  return axios.post('/admin-api/add-sub-admin', data);
};
