import React, { useEffect, useState } from 'react';
import { Button } from '@/components/Elements/Button';
import TextField from '@mui/material/TextField';
import '../newLanding.css';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import i18next from 'i18next';
import { SelectField } from '@/components/Form';
import { MenuItem, Select } from '@mui/material';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axios from 'axios';

export const SearchTabForm = ({ category, setApply }: any) => {
  const navigate = useNavigate();
  const [minPrice, setMin] = useState<number | null>(null);
  const [maxPrice, setMax] = useState<number | null>(null);
  const [location, setLocation] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [address, setAddress] = useState<string>('');
  const [locationGoogle, setLocationGoogle] = useState();
  const [value, setValue] = useState(null);
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');

  const googleMapAPIkey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMin(Number(event.target.value));
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMax(Number(event.target.value));
  };

  const handleChangeLocation = async (value: any) => {
    const placeId = value.value.place_id;
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${googleMapAPIkey}`
    );
    const location = data?.results[0]?.geometry?.location;
    const addressData = data?.results[0]?.formatted_address;

    if (location) {
      setLocationGoogle(location);
      setValue(value);
    }
    if (addressData) {
      setAddress(addressData);
    }
  };

  useEffect(() => {
    if (locationGoogle) {
      setlatitude(locationGoogle?.lat);
      setlongitude(locationGoogle?.lng);
    }
  }, [locationGoogle]);

  const handlePropertyTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPropertyType(event.target.value);
  };

  const handleSubscriptionTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event, "event------------->")
    setSubscriptionType(event.target.value);
  };

  const handleSubmit = () => {
    setApply({
      minPrice,
      maxPrice,
      location: address,
      propertyType,
      category,
      subscriptionType,
      latitude,
      longitude,
      disableSlider: true
    });
  };

  // ------------------- Menu Bar -------------------
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <section className="search-tabs-form relative">
      <div className="container">
        <div className="row justify-content-center">
          <div className={
            ['PropertyProject', 'SaleProperty', 'RentProperty'].includes(category)
              ? 'loc-select col-12 col-md-4 col-lg-4 col-xl-4'
              : 'loc-select col-12 col-md-3 col-lg-3 col-xl-3'
          }>
            <GooglePlacesAutocomplete
              apiKey={''}
              selectProps={{
                defaultInputValue: '',
                value,
                isClearable: true,
                placeholder: `${i18next.t('Search for city/location')}`,
                onChange: handleChangeLocation,
              }}
            />
          </div>

          {/* Property Type Select Field */}
          {!['PropertyProject', 'SaleProperty', 'RentProperty'].includes(category) && (
            <div className="loc-select col-12 col-md-2 col-lg-2 col-xl-2">
              <select
                value={propertyType}
                onChange={handlePropertyTypeChange}
                className="form-select"
              >
                <option value="" disabled selected>{i18next.t('Select Property Type')}</option>
                <option value="apartment_for_sale">{i18next.t('Apartment for sale')}</option>
                <option value="Villa_house_for_sale">{i18next.t('Villa/House for sale')}</option>
                <option value="Townhouse">{i18next.t('Townhouse')}</option>
                <option value="Penthouse">{i18next.t('Penthouse')}</option>
                <option value="Residential_Floor">{i18next.t('Residential Floor')}</option>
                <option value="Residential_Building">{i18next.t('Residential Building')}</option>
                <option value="Villa_Compound">{i18next.t('Villa Compound')}</option>
                <option value="Commercial_for_Sale">{i18next.t('Commercial for Sale')}</option>
                <option value="Land_for_Sale">{i18next.t('Land for Sale')}</option>
                <option value="Multiple_Units_for_Sale">{i18next.t('Multiple Units for Sale')}</option>
              </select>
            </div>
          )}

          <div
            className={
              ['PropertyProject', 'SaleProperty', 'RentProperty'].includes(category)
                ? 'loc-select col-12 col-md-3 col-lg-3 col-xl-3'
                : 'loc-select col-12 col-md-2 col-lg-2 col-xl-2'
            }
          >
            <select
              value={subscriptionType}
              onChange={handleSubscriptionTypeChange}
              className="form-select"
            >
              <option value="" disabled selected>{i18next.t('Select Subscription Type')}</option>
              <option value="1">Basic</option>
              <option value="2">Standard</option>
              <option value="3">Premium</option>
            </select>
          </div>

          <div className="loc-select col-12 col-md-2 col-lg-2 col-xl-2">
            <div className="MenuItemSearch search-prc-range">
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="priceButton"
              >
                {minPrice == null && maxPrice == null ? (
                  `${i18next.t('Price')}`
                ) : (
                  <>
                    <span>
                      ${minPrice} - ${maxPrice}
                    </span>
                  </>
                )}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className="jojoDiv search-r">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Min. Price</label>
                      <TextField
                        type="number"
                        value={minPrice || ''}
                        onChange={handleStartChange}
                        label={i18next.t('Min')}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Max. Price</label>
                      <TextField
                        type="number"
                        value={maxPrice || ''}
                        onChange={handleEndChange}
                        label={i18next.t('Max')}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          </div>

          <div className="search-tabs-btn col-12 col-md-2 col-lg-2 col-xl-2">
            <div className="d-flex gap-1 align-items-center">
              <Button type="button" onClick={handleSubmit} className="">
                Search
              </Button>
              <Button type="button" onClick={() => {
                setApply({
                  minPrice: '',
                  maxPrice: '',
                  location: '',
                  propertyType: '',
                  category: '',
                  subscriptionType: '',
                  latitude: '',
                  longitude: '',
                  disableSlider: false
                });
              }} className="refresh-button p-0 bg-transparent border-0 justify-center">
                <i className="fa-solid fa-rotate"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
