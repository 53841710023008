import React from 'react';
import bread from '@/assets/breadcrumb.png';
import './sidebar.css';
import { Button } from '../Elements';

type BreadCrumProps = {
  head?: string;
  line2?: string;
  paragraph?: string;
  button?: string;
  button2?: string;
  button3?: string;
};

export const BreadCrumb = ({
  head,
  line2,
  paragraph,
  button,
  button2,
  button3,
}: BreadCrumProps) => {
  return (
    <div className="breadcrumb-box" style={{ backgroundImage: 'url(' + bread + ')' }}>
      <div className="overlay-box">
        <div className="breadcrumb-content">
          <h3>{head}</h3>
          <h1>{line2}</h1>
          <p>{paragraph}</p>

          {button3 ? (
            <>
              <div className="row two-buttons">
                <div className="col-6 col-md-6">
                  <Button className="btn-rounded">{button}</Button>
                </div>
                <div className="col-6 col-md-6">
                  <Button className="btn-rounded">{button3}</Button>
                </div>
              </div>
            </>
          ) : (
            <>{button ? <Button className="btn-rounded">{button}</Button> : null}</>
          )}
          {button2 ? <Button className="btn-rounded bigBtn">{button2}</Button> : ' '}
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
