const notificationType: {
  [key: string]: string;
} = {
  inquire: 'inquire',
  property_project: 'property_project',
  contract_created: 'contract_created',
  booking_meeting: 'booking_meeting',
  bussiness_sale: 'bussiness_sale',
  new_registered: 'new_registered',
  company_registered: 'company_registered',
  complete_profile: 'complete_profile',
  property_inquire: 'property_inquire',
  contract_status: 'contract_status',
  start_contract: 'start_contract',
  contact_us: 'contact_us',
  rent_property: 'rent_property',
  sale_property: 'sale_property',
  house_rent: 'house_rent',
  investment_project: 'investment_project',
  investment_company: 'investment_company',
  property_meeting: 'property_meeting',
  upload_property_request: 'upload_property_request',
};
export default notificationType;
