import { useQuery } from 'react-query';
import { axios, axios2 } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ApiResponse, PropertyforRent } from '@/types';

export const getPropertyforRentDetails = (id: string): Promise<ApiResponse<PropertyforRent>> => {
  //return axios.get(`/panel-api/rent-property-detail?id=${id}`);
  return axios2.get(`/customer-api/rent-property-detail?rent_id=${id}`);
};
type QueryFnType = typeof getPropertyforRentDetails;

type UsePropertyProjectOptions = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const usePropertyForRentDetails = (
  { config, id }: UsePropertyProjectOptions = { id: '1' }
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['property-for-rent-detail'],
    queryFn: () => getPropertyforRentDetails(id),
  });
};
