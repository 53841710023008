import React, { useState, useEffect } from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import Footer from '@/components/Layout/Footer/Footer';
import apart from '@/assets/apart.jpg';
import pent from '@/assets/pent.jpg';
import villa from '@/assets/villa.jpg';
import resi from '@/assets/resi.jpg';
import profile from '@/assets/man-new.jpg';
import building from '@/assets/backWal.png';
import './newLanding.css';
import { ContentLayout } from '@/components/Layout';
import { SearchTabs } from './SearchTabs';
import Slider from 'react-slick';
import { Dialog, Link } from '@/components/Elements';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/lib/auth';
import { useNewLandingListing } from '@/api/landing/newLandingListing';
import DOMPurify from 'dompurify';
import { useNotificationStore } from '@/stores/notifications';
import { axios } from '@/lib/axios';
import NewListingLoader from '@/components/Elements/Loader/NewListingLoader';

export const NewLandingPage = () => {
  const [listing, setListing] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const [shareLink, setShareLink] = useState('');
  const navigate = useNavigate();
  const [apply, setApply] = useState({});
  const [activeCategory, setActiveCategory] = useState('All');
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [filterData, setFilterData] = useState(false);
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL as string;
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [subscribe, setSubscribe] = useState('');
  const [disableSlider, setDisableSlider] = useState(false)

  const {
    data,
    isLoading,
    refetch,
    isFetching,
  } = useNewLandingListing({
    type: type,
    filter_data: true,
    search_data: '',
    sub_category: subType,
    latitude: lat,
    longitude: lng,
    subscription_tyre: subscribe,
    min_price: min,
    max_price: max
  });

  useEffect(() => {
    setType(apply.category);
    setSubType(apply.propertyType);
    setLat(apply.latitude);
    setLng(apply.longitude);
    setMin(apply.min_price);
    setMax(apply.max_price);
    setSubscribe(apply.subscriptionType);
    setDisableSlider(apply.disableSlider ?? false);
  }, [apply])

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setSubType('');
    if (category === 'All') {
      setType('');
      setFilterData(false);
    } else {
      setType(category);
      setFilterData(true);
    }
  };

  const handleSubCategoryClick = (category) => {
    setActiveCategory(category);
    setType('');
    if (category === 'All') {
      setSubType('');
      setFilterData(false);
    } else {
      setSubType(category);
      setFilterData(true);
    }
  };

  useEffect(() => {
    setListing(data);
  }, [data, isLoading])

  useEffect(() => {
    refetch();
  }, [type, filterData, apply, subType]);

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    cssEase: "linear"
  };
  const filters = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const openDialog = (event, type, id) => {
    event.stopPropagation();
    const url = navigateToDetailPage(type, id);
    setShareLink(`${frontendUrl + url}`);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    refetch();
  }, [])

  const renderHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const navigateToDetailPage = (type, id) => {
    const routes = {
      'BusinessSale': `/sale-detail/${id}?type=BusinessSale`,
      'SaleProperty': `/property-for-sale-detail/${id}?type=SaleProperty`,
      'RentProperty': `/property-for-rent-detail/${id}?type=RentProperty`,
      'HouseRent': `/housing-detail/${id}`,
      'InvestmentProject': `/investment-detail/${id}?type=InvestmentProject`,
      'InvestmentCompany': `/company-detail/${id}?type=InvestmentCompany`,
      'PropertyProject': `/property-detail/${id}?type=PropertyProject`
    };

    return routes[type] || `/`;
  };

  const handleNavigation = (type, id) => {
    const url = navigateToDetailPage(type, id);
    navigate(url);
  };

  const handleFavorite = async (event, type, id, isFavourite) => {
    event.stopPropagation();

    if (!user) {
      useNotificationStore.getState().addNotification({
        title: 'Login',
        type: 'info',
        message: 'Need to Login first!',
      });
      navigate('/auth/login-agency');
      return;
    }

    const updatedListing = listing.map(item =>
      item.id === id ? { ...item, is_favourite: !isFavourite } : item
    );
    setListing(updatedListing);

    const apiEndpoints = {
      'BusinessSale': 'favourite-unfavourite-business-sale',
      'SaleProperty': 'favourite-unfavourite-sale-property',
      'RentProperty': 'favourite-unfavourite-rent-property',
      'HouseRent': 'favourite-unfavourite-house-rent',
      'InvestmentProject': 'favourite-unfavourite-invest-project',
      'InvestmentCompany': 'favourite-unfavourite-invest-company',
      'PropertyProject': 'favourite-unfavourite-property-project'
    };

    const payloadKeys = {
      'BusinessSale': 'business_id',
      'SaleProperty': 'sale_id',
      'RentProperty': 'rent_id',
      'HouseRent': 'house_rent_id',
      'InvestmentProject': 'invest_id',
      'InvestmentCompany': 'invest_id',
      'PropertyProject': 'property_project_id'
    };

    const apiUrl = `/panel-api/${apiEndpoints[type]}`;
    const payloadKey = payloadKeys[type];

    try {
      const response = await axios.post(apiUrl, {
        [payloadKey]: id,
        status_action: !isFavourite,
      });

      useNotificationStore.getState().addNotification({
        title: `${!isFavourite ? 'Added Favourite' : 'Removed Favourite'}`,
        type: 'success',
        message: `Successfully ${!isFavourite ? 'added to' : 'removed from'} favourites`,
      });
    } catch (error) {
      console.error('Failed to update favorite status:', error);
      setListing(listing.map(item =>
        item.id === id ? { ...item, is_favourite: isFavourite } : item
      ));
      useNotificationStore.getState().addNotification({
        title: 'Error',
        type: 'error',
        message: 'Failed to update favorite status. Please try again.',
      });
    }
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      useNotificationStore.getState().addNotification({
        title: 'Copied!',
        type: 'success',
        message: 'Link copied to clipboard.',
      });
    }).catch(err => {
      console.error('Failed to copy link:', err);
    });
  };

  return (
    <ContentLayout title="New Landing">
      <Header type={HeaderType.LANDING} />
      <div className="search-sec">
        <SearchTabs setApply={setApply} />
      </div>
      <div className="landing-section-tabs border-tp space-tb">
        <div className="container">
          {!disableSlider && <div className="main-searching-tabs gap-5 mb-3">
            <Slider {...filters}>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'All' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('All')}>
                    <img src={apart} className="aprt-icon" alt="apartment" />
                    <h6 className="f-13 semi-bold">All</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'RentProperty' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('RentProperty')}>
                    <img src={apart} className="aprt-icon" alt="apartment" />
                    <h6 className="f-13 semi-bold">Rent Property</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'PropertyProject' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('PropertyProject')}>
                    <img src={pent} className="aprt-icon" alt="apartment" />
                    <h6 className="f-13 semi-bold">Property Project</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'SaleProperty' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('SaleProperty')}>
                    <img src={villa} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Sale Property</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'HouseRent' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('HouseRent')}>
                    <img src={villa} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">House Rent</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'InvestmentCompany' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('InvestmentCompany')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Investment Company</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'BusinessSale' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleCategoryClick('BusinessSale')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Business Sale</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'apartment_for_sale' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('apartment_for_sale')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Apartment for sale</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'villa_house_for_sale' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('villa_house_for_sale')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Villa/House for sale</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'townhouse' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('townhouse')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Townhouse</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'penthouse' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('penthouse')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Penthouse</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'Residential_Floor' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('Residential_Floor')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Residential Floor</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'Residential_Building' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('Residential_Building')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Residential Building</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'Villa_Compound' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('Villa_Compound')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Villa Compound</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'Commercial_for_Sale' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('Commercial_for_Sale')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Commercial for Sale</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'Land_for_Sale' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('Land_for_Sale')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Land for Sale</h6>
                  </Button>
                </div>
              </div>
              <div>
                <div className={`cat-filter-categories text-center ${activeCategory === 'Multiple_Units_for_Sale' ? 'active' : ''}`}>
                  <Button className="cat-link p-0 bg-transparent border-0" onClick={() => handleSubCategoryClick('Multiple_Units_for_Sale')}>
                    <img src={resi} className="aprt-icon" alt="villa" />
                    <h6 className="f-13 semi-bold">Multiple Units for Sale</h6>
                  </Button>
                </div>
              </div>
            </Slider>
          </div>}
          <div className="residential-tab-cards">
            {isLoading || isFetching ? (
              <NewListingLoader />
            ) : (
              <div className="row">
                {(listing && listing?.length > 0) ? listing?.map((item, index) => {
                  const parseImageData = (data) => {
                    try {
                      const parsedData = eval(data);
                      if (Array.isArray(parsedData)) {
                        return parsedData;
                      }
                    } catch (e) {
                      console.log(e)
                    }
                    return [];
                  };

                  const imageData = parseImageData(item?.image_data);

                  return (
                    <div key={index} className="col-12 col-md-3 mb-4">
                      <div className="resi-card-box" onClick={() => handleNavigation(item.type, item.id)}>
                        <div className="resi-card-icon mb-3 relative">
                          <Slider {...settings}>
                            {imageData.length > 0 ? imageData.map((img, idx) => (
                              <div key={idx} className="res-slider-icon">
                                <img src={img?.image ? img?.image : building} alt={img?.name || "Property Image"} />
                              </div>
                            )) :
                              <div className="res-slider-icon">
                                <img src={building} alt={"Property Image"} />
                              </div>}
                          </Slider>
                          <div className="resi-popup" >
                            <Button className="pop-btn bg-transparent border-0 p-0" onClick={(e) => openDialog(e, item.type, item.id)}>
                              <span className="bg-pop">
                                <i className="fa-solid fa-share"></i>
                              </span>
                            </Button>
                          </div>
                          <div className="resi-propname" >
                            {activeCategory == 'All' && <span className='f-14'>{item?.type}</span>}
                          </div>
                          <div className="wish-popup">
                            <Button className="wish-btn bg-transparent border-0 p-0" onClick={(e) => handleFavorite(e, item.type, item.id, item.is_favourite)}>
                              <span className="bg-pop">
                                <i className={`fa-heart ${item.is_favourite ? 'fa-solid text-danger' : 'fa-regular'}`}></i>
                              </span>
                            </Button>
                          </div>
                        </div>
                        <div className="resi-content">
                          <h4 className="semi-bold f-16">{item?.title}</h4>
                          <p className="f-15 mb-0" dangerouslySetInnerHTML={renderHTML(item?.location || "")}></p>
                          <p className="f-16 semi-bold mb-0">${item?.filter_price?.toLocaleString()}</p>
                        </div>
                      </div>
                    </div>
                  );
                }) : (<>                 <center>NO DATA FOUND</center> </>)
                }
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog isOpen={isOpen} onClose={closeDialog}>
        <div className="inline-block align-top bg-white px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="share-exp-modal">
            <div className="row">
              <div className="col-12">
                <div className="share-experience">
                  <h5 className="f-20 mb-0 text-black">Share this experience</h5>
                  <div className="share-img-col d-flex gap-4 align-items-center my-4">
                    <img src={profile} className="share-img-exp" />
                    <p className="f-15 mb-0">Go VIP with Kevin Hart</p>
                  </div>
                  <div className="experience-shares d-flex gap-3">
                    <Button type="button" className="exp-button" onClick={copyLinkToClipboard}>
                      <i className="fa-solid fa-copy me-3"></i> Copy Link
                    </Button>
                    <Button type="button" className="exp-button" onClick={() => window.open(`mailto:?subject=Check this out&body=${shareLink}`, '_blank')}>
                      <i className="fa-regular fa-envelope me-3"></i> Email
                    </Button>
                    <Button type="button" className="exp-button" onClick={() => window.open(`sms:?body=${shareLink}`, '_blank')}>
                      <i className="fa-solid fa-comment me-3"></i> Messages
                    </Button>
                    <Button type="button" className="exp-button" onClick={() => window.open(`https://wa.me/?text=${shareLink}`, '_blank')}>
                      <i className="fa-brands fa-whatsapp me-3"></i> WhatsApp
                    </Button>
                    <Button type="button" className="exp-button" onClick={() => window.open(`https://m.me/?link=${shareLink}`, '_blank')}>
                      <i className="fa-brands fa-facebook-messenger me-3"></i> Messenger
                    </Button>
                    <Button type="button" className="exp-button" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`, '_blank')}>
                      <i className="fa-brands fa-facebook me-3"></i> Facebook
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Footer />
    </ContentLayout >
  );
};
