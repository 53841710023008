import Footer from '@/components/Layout/Footer/Footer';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import PropertyContent from './PropertyContent';
import { ContentLayout } from '@/components/Layout';

function PropertyForRentDetail() {
  return (
    <ContentLayout title="Property for Rent Detail">
      <div>
        <Header type={HeaderType.LANDING} />
        <PropertyContent />
        <Footer />
      </div>
    </ContentLayout>
  );
}

export default PropertyForRentDetail;
