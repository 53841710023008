import { axios, axios2 } from '@/lib/axios';
import { UploadResponse } from '@/types/UploadResponse';

export type UploadDTO = {
  file: File;
};

export const uploadImage = (data: UploadDTO): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append('file', data.file);
  return axios2.post('customer-api/upload-image', formData);
};
