import nextArrow from "@/assets/arrowNext.png";
import prevArrow from "@/assets/arrowPrev.png";
import { Form } from '@/components/Form';
// import SelectField from "@/components/Form/SelectField";
import { TextAreaField } from '@/components/Form/TextareaField';
import { InputField } from '@/components/Form/InputField';
import requiredIcon from "@/assets/requiredIcon.png";
import Map from "@/assets/map.png";
import { SelectField } from '@/components/Form/SelectField';


const StepThird = () => {
        
    return (
        <div className="form-component">
            <div className="d-flex justify-content-between mb-4">
                <div className="d-flex tab-buttons">
                    <button
                    className="tab-button active"
                    >
                    Company Information
                    </button>
                </div>
            </div>

            <div className="card">
            <div className="card-body">
                <div className="property-info-form">
                <Form<FormValues>
                    onSubmit={async (values) => {
                    alert(JSON.stringify(values, null, 2));
                    }}
                    id="my-form"
                >
                {({ register, formState }) => (
                    <>
                    <div className="row col-gap">
                        <div className="col-md-6 mb-4">
                            <div className="form-block d-flex flex-column gap-2 requiredIcons">
                                <InputField
                                    type="text"
                                    label="Valuation"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Share for Sale"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Price Per Share NOK"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Postal Code"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Bank Account Created"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Bank Account Created"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Issue Account Number"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Post Number"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                />
                                <span className="requiredField">Required</span>

                                <TextAreaField
                                    rows={4}
                                    error={formState.errors['company_details']}
                                    registration={register('company_details')}
                                    label="Self report"
                                />
                                <span className="requiredField">Required</span>

                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="form-block d-flex flex-column gap-2 requiredIcons">
                                <InputField
                                    type="text"
                                    label="Maximum Drawing"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Minimum Drawing"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Remaining Shares"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Number of Shares Before Issue"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Nominal Share Price NOK"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Number of Shares Before Issue"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                                <InputField
                                    type="text"
                                    label="Company Website"
                                    error={formState.errors['company_name']}
                                    registration={register('company_name')}
                                    placeholder="Content"
                                    icon={requiredIcon}
                                />
                                <span className="requiredField">Required</span>

                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div className="form-block">
                                <img src={Map} alt="" className="d-block w-100" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-block d-flex flex-column gap-2">
                                <SelectField
                                    label="Location"
                                    error={formState.errors['type']}
                                    registration={register('type')}
                                    options={['Content', '1999', '2007'].map((type) => ({
                                    label: type,
                                    value: type,
                                    }))}
                                />
                                <span className="requiredField">Required</span>
                            </div>
                        </div>
                    </div>
                    </>
                    )}
                </Form>

                <div className="next-prev-buttons d-flex gap-4 align-items-center mt-4">
                    <span className="arrow prev-arrow"><img src={prevArrow} className="d-block w-100" alt="" /></span>
                    <button className="arrow next-arrow d-flex gap-2 align-items-center">Submit<img src={nextArrow} className="d-block w-100" alt="" /></button>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}
export default StepThird;