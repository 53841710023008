/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import Footer from '@/components/Layout/Footer/Footer';
import './landing.css';
import BreadCrumb from '@/components/Layout/BreadCrumb';
import i18next from 'i18next';
import Carousel from '@/features/misc/routes/Landing/Carousel/Carousel';
import MyListingLoader from '@/components/Elements/Loader/MyListingLoader';
import BackdropLoader from '@/components/Elements/Loader/BackdropLoader';
import { usePropertyProjectListing } from '@/api/propertyProject/getPropertyProhectWebsite';
import ServiceItemList from '@/features/misc/routes/Landing/ServiceItemList';
import PropertyProjects from '@/features/user/components/PropertyProjects';

import { ContentLayout } from '@/components/Layout';
import { Filter } from '../Landing/filterType';

export const PropertyProject = () => {
  const [loading] = useState(false);
  const [object, setObject] = useState<Filter | null>(null);
  const [saveSearch, setSaveSearch] = useState(false);
  const [heartClicked, setHeartClicked] = useState<boolean>();
  const {
    data: listing,
    isLoading,
    refetch,
    isFetching,
    isRefetching,
  } = usePropertyProjectListing({
    page: '1',
    min_price: '',
    max_price: '',
    location: '',
    type: '',
    amenities: '',
    filter_data: saveSearch,
    bedroom_min: '',
    bedroom_max: '',
    subscription_tyre: object?.subscriptionSort ? object?.subscriptionSort : '',
    sort_price: object?.priceSort,
    sort_upload_date: object?.timeSort ? object?.timeSort : '',
  });

  const [clear, setClear] = useState(false);
  const [sendObj, setSendObj] = useState({});
  const obj = {
    priceSort: object?.priceSort,
    subscriptionSort: object?.subscriptionSort,
    timeSort: object?.timeSort,
  };
  const objNew = {
    priceSort: '',
    subscriptionSort: '',
    timeSort: '',
  };
  useEffect(() => {
    refetch();
    setHeartClicked(false);
    setSaveSearch(false);
    setSendObj(obj);
  }, [heartClicked, refetch, saveSearch]);

  useEffect(() => {
    setSaveSearch(false);
    setClear(false);
    refetch();
    setSendObj(objNew);
  }, [clear]);

  return (
    <>
      <ContentLayout title="Property Project">
        <BackdropLoader open={loading} />
        <Header type={HeaderType.LANDING} />
        <BreadCrumb
          head={i18next.t('Explore Property Projects')}
          paragraph={i18next.t(
            'Any individual can publish a property project at no cost and engage with our network of investors to secure the required capital.'
          )}
          // button="Property For Rent"
          // button3="Property For Sale"
        />
        <br />
        <br />
        <br />
        {/* <Carousel /> */}
        <ServiceItemList
          setObject={setObject}
          setSaveSearch={setSaveSearch}
          setClear={setClear}
          newObj={sendObj}
          linkAddress={'/project_type?page=project_type'}
        />
        ;
        {isLoading || isFetching || isRefetching ? (
          <MyListingLoader />
        ) : (
          <>
            <div className="container">
              <div className="row">
                {listing?.length == 0 ? (
                  <h4 className="makeCenter">{i18next.t('No listing found !')}</h4>
                ) : (
                  <>
                    {listing?.map((item, index) => (
                      <PropertyProjects
                        headData={false}
                        editIcon={false}
                        deleteIcon={false}
                        item={item}
                        key={index}
                        setHeartClicked={setHeartClicked}
                        setDeleteClicked={undefined}
                        heartIcon={false}
                      />
                    ))}
                  </>
                )}
              </div>
              {/* <div className="row maps">
            <button>
              Show Map<i className="fa-solid fa-map-location-dot"></i>
            </button>
          </div> */}
            </div>
          </>
        )}
        <br />
        <Footer />
      </ContentLayout>
    </>
  );
};
