import nextArrow from "@/assets/arrowNext.png";
import prevArrow from "@/assets/arrowPrev.png";
import { Form } from '@/components/Form';
// import SelectField from "@/components/Form/SelectField";
import { SelectField } from '@/components/Form/SelectField';
import { TextAreaField } from '@/components/Form/TextareaField';
import { InputField } from '@/components/Form/InputField';
import uploadFiles from "@/assets/uploadFiles.png";


const StepFirst = () => {

    return (

  <div className="form-component">
    <div className="d-flex justify-content-between mb-4">
        <div className="d-flex tab-buttons">
            <button
            className="tab-button active"
            >
            Property Information
            </button>
        </div>

        </div>

        <div className="card">
        <div className="card-body">
            <div className="property-info-form">
            <p className="form-heading pb-2 mb-3">Property Information</p>
            <Form<FormValues>
                onSubmit={async (values) => {
                alert(JSON.stringify(values, null, 2));
                }}
                id="my-form"
            >
            {({ register, formState }) => (
                <>
                <div className="row">
                    <div className="col-md-3">
                    <div className="form-block d-flex flex-column gap-2">
                        <TextAreaField
                        rows={4}
                        error={formState.errors['company_details']}
                        registration={register('company_details')}
                        label="Property Name"
                        />
                        <span className="requiredField">Required</span>

                        <div className="position-relative mb-3">
                        <div className="form-floating position-relative upload-files">
                            <input type="file" name="uploadFiles" id="upload" />
                            <span className="uploadFile"><img src={uploadFiles} alt="" className="d-block w-100" /></span>
                            <label htmlFor="Property Images">Company Logo</label>
                        </div>
                        </div>

                        <div className="position-relative mb-3">
                        <div className="form-floating position-relative upload-files">
                            <input type="file" name="uploadFiles" id="upload" />
                            <span className="uploadFile"><img src={uploadFiles} alt="" className="d-block w-100" /></span>
                            <label htmlFor="Property Videos">Company Videos</label>
                        </div>
                        </div>

                        <div className="position-relative mb-3">
                        <div className="form-floating position-relative upload-files">
                            <input type="file" name="uploadFiles" id="upload" />
                            <span className="uploadFile"><img src={uploadFiles} alt="" className="d-block w-100" /></span>
                            <label htmlFor="Property Documents">Documents</label>
                        </div>
                        </div>

                    </div>
                    </div>
                    <div className="col-md-9">
                      <div className="form-block d-flex flex-column gap-2">

                        <InputField
                            type="text"
                            label="Property Type"
                            error={formState.errors['company_name']}
                            registration={register('company_name')}
                            placeholder="Content"
                        />
                        <span className="requiredField">Required</span>

                        <SelectField
                            label="Company Established (In Years)"
                            error={formState.errors['type']}
                            registration={register('type')}
                            options={['2015', '1999', '2007'].map((type) => ({
                            label: type,
                            value: type,
                            }))}
                        />
                        <span className="requiredField">Required</span>

                        <InputField
                            type="text"
                            label="Company Email Address"
                            error={formState.errors['company_name']}
                            registration={register('company_name')}
                            placeholder="Content"
                        />
                        <span className="requiredField">Required</span>

                        <InputField
                            type="text"
                            label="Company Phone Number"
                            error={formState.errors['company_name']}
                            registration={register('company_name')}
                            placeholder="Content"
                        />
                        <span className="requiredField">Required</span>

                        <InputField
                            type="text"
                            label="Company Address"
                            error={formState.errors['company_name']}
                            registration={register('company_name')}
                            placeholder="Content"
                        />
                        <span className="requiredField">Required</span>

                      </div>
                    </div>
                </div>
                </>
                )}
            </Form>

            <div className="next-prev-buttons d-flex gap-4 align-items-center mt-5">
                <span className="arrow prev-arrow"><img src={prevArrow} className="d-block w-100" alt="" /></span>
                <button className="arrow next-arrow d-flex gap-2 align-items-center">Next Step<img src={nextArrow} className="d-block w-100" alt="" /></button>
            </div>
            </div>
        </div>
        </div>
    </div>
    )
}
export default StepFirst;