import { ArchiveIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import './Table.css';
import clsx from 'clsx';
import { User as Customer } from '@/types/User';

export enum TableActions {
  EDIT = 'Edit',
  VIEW = 'view',
  DELETE = 'delete',
  ALLOW = 'allow',
  BLOCK = 'block',
}

export type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

export type TableProps<Entry> = {
  data: Entry[];
  tableName?: string;
  actions?: TableActions[];
  columns: TableColumn<Entry>[];
  onActionClicked?: (action: TableActions, entry: Entry) => void;
  page: number;
  total: number;
  changePage: (page: string) => void;
};

export const Table = <Entry extends { id: string; sr?: number }>({
  data,
  tableName,
  columns,
  actions = [],
  onActionClicked,
  page,
  total,
  changePage,
}: TableProps<Entry>) => {
  const pageSize = 10;

  const [indexedEntries, setIndexedEntries] = useState<Entry[]>([]);

  useEffect(() => {
    let start: number;

    if (page === 1) {
      start = 1;
    } else {
      start = pageSize * page - pageSize + 1;
    }

    const indexed = data.map((i) => {
      i.sr = start++;
      return i;
    });
    setIndexedEntries(indexed);
  }, [data, page]);

  const goBack = () => {
    if (page > 1) {
      changePage((page - 1).toString());
    }
  };

  const goForward = () => {
    const end = pageSize * page;
    if (total >= end) {
      changePage((page + 1).toString());
    }
  };

  const goToPage = (p: number) => {
    changePage(p.toString());
  };

  const getPaginationString = () => {
    let start, end;
    if (page === 1) {
      start = 1;
      end = pageSize;
    } else {
      start = pageSize * page - pageSize + 1;
      end = pageSize * page;
    }
    if (end > total) {
      end = total;
    }

    return `Showing ${start} to ${end} of ${total} entries`;
  };

  if (!data?.length) {
    return (
      <div className="flex flex-col items-center justify-center text-gray-500 bg-white h-80">
        <ArchiveIcon className="w-16 h-16" />
        <h4>No Entries Found</h4>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden  sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 text-xs font-medium tracking-wider text-left text-gray-500"
                  >
                    Sr. No.
                  </th>
                  {columns.map((column, index) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className="px-2 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {indexedEntries.map((entry, entryIndex) => (
                  <tr key={entry?.id || entryIndex} className="odd:bg-white even:bg-white">
                    <td className="px-3 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {entry?.sr ?? 1}
                    </td>
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className="px-3 py-2 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        {Cell ? <Cell entry={entry} /> : entry[field]}
                      </td>
                    ))}
                    {actions.length > 0 && (
                      <td
                        key="EDIT"
                        className="px-3 py-2 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="d-flex cursor-pointer">
                          {actions.map((action: TableActions, index) => (
                            <span key={index}>
                              {action === TableActions.EDIT && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onActionClicked?.(TableActions.EDIT, entry)}
                                  onClick={() => onActionClicked?.(TableActions.EDIT, entry)}
                                  className="icon me-2"
                                  title="Edit"
                                >
                                  <i className="fa-solid fa-pen" />
                                </span>
                              )}
                              {action === TableActions.VIEW && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onActionClicked?.(TableActions.VIEW, entry)}
                                  onClick={() => onActionClicked?.(TableActions.VIEW, entry)}
                                  className="icon me-2"
                                  title="View"
                                >
                                  <i className="fa-regular fa-eye" />
                                </span>
                              )}
                              {action === TableActions.DELETE && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onActionClicked?.(TableActions.VIEW, entry)}
                                  onClick={() => onActionClicked?.(TableActions.VIEW, entry)}
                                  className="icon me-2"
                                  title="View"
                                >
                                  <i className="fa-solid fa-trash" />
                                </span>
                              )}
                              {action === TableActions.BLOCK &&
                                tableName === 'customers' &&
                                (entry as unknown as Customer).status && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onActionClicked?.(TableActions.BLOCK, entry)}
                                    onClick={() => onActionClicked?.(TableActions.BLOCK, entry)}
                                    className="icon me-2"
                                    title="Block"
                                  >
                                    <i className="fa-solid fa-ban" />
                                  </span>
                                )}
                              {action === TableActions.ALLOW &&
                                tableName === 'customers' &&
                                !(entry as unknown as Customer).status && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onActionClicked?.(TableActions.ALLOW, entry)}
                                    onClick={() => onActionClicked?.(TableActions.ALLOW, entry)}
                                    className="icon me-2"
                                    title="Unblock"
                                  >
                                    <i className="fa-solid fa-check" />
                                  </span>
                                )}
                            </span>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between table-sorting">
            <p className="mt-3 ms-4">{getPaginationString()}</p>
            <div className="d-flex justify-content-end my-3 me-3 pe-2">
              <div
                className="page me-2"
                role="button"
                tabIndex={-1}
                onKeyDown={goBack}
                onClick={goBack}
              >
                <span>
                  <i className="fa-solid fa-chevron-left" />
                </span>
              </div>
              {[1, 2, 3, 4, 5].map((i) => (
                <div key={i}>
                  {(i === 1 || total >= i * pageSize - pageSize) && (
                    <div
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => goToPage(i)}
                      onClick={() => goToPage(i)}
                      className={clsx('page number me-2', i === page && 'active')}
                    >
                      <span>{i}</span>
                    </div>
                  )}
                </div>
              ))}
              <div
                role="button"
                tabIndex={-1}
                onKeyDown={goForward}
                className="page me-2"
                onClick={goForward}
              >
                <span>
                  <i className="fa-solid fa-chevron-right" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
