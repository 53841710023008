import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { socialLogin } from '@/api/agentRequests/socialLogin';
import storage from '@/utils/storage';
import { useNotificationStore } from '@/stores/notifications';

const GoogleLoginButton = () => {
  const [googleLoginData, setGoogleLoginData] = useState<any>();
  const [provider, setProvider] = useState<any>();
  const [social_id, setSocialId] = useState<any>();
  const [first_name, setFirstName] = useState<any>();
  const [last_name, setLastName] = useState<any>();
  const [dob, setDob] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [posts, setPost] = useState<boolean>(false);
  const [socialToken, setSocialToken] = useState<string>('');

  const googleAuth = googleLoginData;
  const googleSocialId = googleLoginData?._delegate?.uid;
  const googleFName = googleLoginData?.providerData?.[0]?.displayName;
  const googleEmail = googleLoginData?._delegate?.email;

  useEffect(() => {
    if (googleAuth) {
      setProvider('google');
      setSocialId(googleSocialId);
      setFirstName(googleFName);
      setLastName(googleFName);
      setDob('12/02/2001');
      setEmail(googleEmail);
      setPost(true);
    }
  }, [googleAuth]);

  type SocialLoginDTO = {
    provider: string;
    social_id: string;
    first_name: string;
    last_name: string;
    dob: string;
    email: string;
  };

  const handleSubmit = async (values: SocialLoginDTO) => {
    const social = await socialLogin(values);

    const val = social?.data?.access;

    if (social) {
      setSocialToken(val);
    }
  };

  const values = { provider, social_id, first_name, last_name, dob, email };

  useEffect(() => {
    if (posts === true) {
      handleSubmit(values);
      setPost(false);
    }
  }, [posts]);

  useEffect(() => {
    if (socialToken != '') {
      storage.setToken(socialToken);
      window.location.reload();
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'USer Logged in  successfully!',
      });
    }
  }, [socialToken]);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        setGoogleLoginData(result.user);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };

  return (
    <button onClick={signInWithGoogle} className="logStyle">
      Continue with Google
    </button>
  );
};

export default GoogleLoginButton;
