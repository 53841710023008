import * as React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initMocks } from './test/server';
import 'bootstrap/dist/css/bootstrap.css';
import './custom.scss';
import { createTheme, ThemeProvider } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import firebaseConfig from './config/firebaseConfig';

import i18next from 'i18next';
import './i18n';

const lang = localStorage.getItem('lang') || 'en';
i18next.changeLanguage(lang);

firebase.initializeApp(firebaseConfig);

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
  },
  palette: {
    primary: {
      main: '#FE5E00',
    },
    secondary: {
      main: '#ffcc80',
    },
  },
});

initMocks();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
