import React from 'react';
import { IMAGE, VIDEO, PDF } from './constants';
import ImageViewer from './ImageViewer';
import VideoViewer from './VideoViewer';
import PDFViewer from './PDFViewer';

const FileViewer = ({ file, style }: { file: string; style?: object }) => {
  const extension = file.split('.').at(-1);
  if (IMAGE.includes(extension ?? '')) {
    return <ImageViewer file={file} style={style} />;
  }

  if (VIDEO.includes(extension ?? '')) {
    return <VideoViewer file={file} />;
  }

  if (PDF.includes(extension ?? '')) {
    return <PDFViewer file={file} />;
  }
  return <div>{extension}</div>;
};

export default FileViewer;
