import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ApiResponse, RoleContent } from '@/types';

export const getCurrencyData = (id: string): Promise<ApiResponse<RoleContent>> => {
  return axios.get(`/customer-api/get-currency-details?currency_id=${id}`);
};

type QueryFnType = typeof getCurrencyData;

type UsePropertyProjectOptions = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const useCurrencyData = ({ config, id }: UsePropertyProjectOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['currency-detail'],
    queryFn: () => getCurrencyData(id),
  });
};
