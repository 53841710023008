import { Layout } from '../components/Layout';
import { ForgetPasswordForm } from '../components/ForgetPasswordForm';

export const Forget = () => {
  return (
    <Layout title="Forget password">
      <ForgetPasswordForm />
    </Layout>
  );
};
