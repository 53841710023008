import { axios } from '@/lib/axios';

export type ForgtPasswordDTO = {
  email: string;
  base_url: string;
};

export const sendForgetPasswordLink = (data: ForgtPasswordDTO) => {
  return axios.post('customer-api/forgot-password/', data);
};
