import * as React from 'react';

import { Head } from '../Head';

type AdminLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const AdminLayout = ({ children, title }: AdminLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="container pt-5 px-5">
        <h3 className="text-primary mb-4">{title}</h3>
        {children}
      </div>
    </>
  );
};
