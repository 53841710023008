import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';

const UserAuth = ({ children, click }: { children: React.ReactElement; click: () => void }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    if (!user) {
      useNotificationStore.getState().addNotification({
        type: 'info',
        title: 'Warning',
        message: 'Please login first!',
      });
      navigate('/auth/login');
      return;
    }
    click();
  };
  return (
    <span role="button" tabIndex={-1} onKeyDown={handleClick} onClick={handleClick}>
      {children}
    </span>
  );
};

export default UserAuth;
