import Footer from '@/components/Layout/Footer/Footer';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import React from 'react';
import { ContentLayout } from '@/components/Layout';
import PropertyContentRent from './PropertyContentRent';

function PropertyForSaleDetail() {
  return (
    <ContentLayout title="Property Project  Detail">
      <div>
        <Header type={HeaderType.LANDING} />
        <PropertyContentRent />
        <Footer />
      </div>
    </ContentLayout>
  );
}

export default PropertyForSaleDetail;
