import bread from '@/assets/breadcrumb.png';
import './sidebar.css';
type BreadCrumProps = {
  head?: string;
  line2?: string;
  pragraf?: string;
  buton?: string;
  buton2?: string;
};

export const BreadCrum = ({ head, line2, pragraf }: BreadCrumProps) => {
  return (
    <div className="breadcrumb-box bigb" style={{ backgroundImage: 'url(' + bread + ')' }}>
      <div className="overlay-box">
        <div className="breadcrumb-content">
          <h3>{head}</h3>
          <h1>{line2}</h1>
          <p>{pragraf}</p>
        </div>
      </div>
    </div>
  );
};

export default BreadCrum;
