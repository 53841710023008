import { Dialog, Transition } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './sidebar.css';
import logo from '@/assets/new-logo.png';
import UserProfile from '@/components/Layout/Header/UserProfile/UserProfile';
import { HeaderType } from '@/components/Layout/Header/Header';

export type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  children?: SideNavigationItem[];
};

const SideNavigation = ({ navigation }: { navigation: SideNavigationItem[] }) => {
  const [active, setActive] = React.useState('');
  return (
    <>
      <ul className="list-unstyled ps-0">
        {navigation.map((item, index) => (
          <li className="mb-1" key={index}>
            <NavLink
              {...(item?.children && {
                'data-bs-toggle': 'collapse',
                'data-bs-target': `#orders-collapse${index}`,
                'aria-expanded': false,
              })}
              to={item.to}
              end={index === 0}
              className={clsx(
                'text-gray-300 hover:bg-primary hover:text-white',
                'group flex items-center px-2 py-2 text-base font-medium'
              )}
            >
              <item.icon
                className={clsx(
                  'text-black-400 group-hover:text-black-300',
                  'mr-4 flex-shrink-0 h-4 w-4'
                )}
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
            {item?.children && (
              <div className="collapse" id={`orders-collapse${index}`}>
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {item?.children.map((subItem, index) => (
                    <li key={index}>
                      <Link
                        to={subItem.to}
                        onClick={() => setActive(subItem.to)}
                        className={clsx('link-dark rounded', subItem.to === active ? 'active' : '')}
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigation: SideNavigationItem[];
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen, navigation }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation navigation={navigation} />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = ({ navigation }: { navigation: SideNavigationItem[] }) => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col sidebar-width">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center justify-content-center h-16 flex-shrink-0 px-4 bg-white">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col  sidebar-over overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-white border rounded space-y-1">
              <SideNavigation navigation={navigation} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center  text-white" to=".">
      <img className="w-auto" style={{ height: '45px' }} src={logo} alt="Workflow" />
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
  navigation: SideNavigationItem[];
};

export const MainLayout = ({ children, navigation }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <MobileSidebar
        navigation={navigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Sidebar navigation={navigation} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <UserProfile type={HeaderType.DASHBOARD} />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
      </div>
    </div>
  );
};
