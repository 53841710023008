import clsx from 'clsx';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const variants = {
  primary: 'text-primary',
  secondary: 'text-secondary',
  success: 'text-success',
  warning: 'text-warning',
  danger: 'text-danger',
};

export type LinkTypeProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant?: keyof typeof variants;
};

export const Link = ({
  className,
  children,
  variant = 'primary',
  ...props
}: LinkProps & LinkTypeProps) => {
  return (
    <RouterLink
      className={clsx('text-indigo-600 hover:text-indigo-900', className, variants[variant])}
      {...props}
    >
      {children}
    </RouterLink>
  );
};
