import { useQuery } from 'react-query';
import { axios } from '@/lib/axiosData';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Listing } from '@/types';

export const newLandingListing = ({
    type,
    filter_data,
    search_data,
    sub_category,
    latitude,
    longitude,
    subscription_tyre,
    min_price,
    max_price
}: {
    filter_data: boolean;
    type: any;
    search_data: string;
    sub_category: string;
    latitude: string;
    longitude: string;
    subscription_tyre: string;
    min_price: string;
    max_price: string;
}): Promise<Listing[]> => {
    return axios.get(
        `/customer-api/home-page-data-listing`, {
        params: {
            filter_data,
            type,
            search_data,
            sub_category,
            latitude,
            longitude,
            subscription_tyre,
            min_price,
            max_price
        }
    }
    );
};

type QueryFnType = typeof newLandingListing;

type UseNewListingOptions = {
    config?: QueryConfig<QueryFnType>;
    type: any;
    filter_data: boolean;
    search_data: string;
    sub_category: string;
    latitude: string;
    longitude: string;
    subscription_tyre: string;
    min_price: string;
    max_price: string;
};

export const useNewLandingListing = ({
    config,
    type,
    filter_data,
    search_data,
    sub_category,
    latitude,
    longitude,
    subscription_tyre,
    min_price,
    max_price
}: UseNewListingOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['listing-landing-page', {
            type,
            filter_data,
            search_data,
            sub_category,
            latitude,
            longitude,
            subscription_tyre,
            min_price,
            max_price
        }],
        queryFn: () =>
            newLandingListing({
                filter_data,
                type,
                search_data,
                sub_category,
                latitude,
                longitude,
                subscription_tyre,
                min_price,
                max_price
            }),
    });
};
