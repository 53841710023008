import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { Notification } from '@/types/Notification';
import { ApiResponse } from '@/types';

export const getadminNotifications = (): Promise<ApiResponse<Notification[]>> => {
  return axios.get('/admin-api/admin-notifications');
};
type QueryFnType = typeof getadminNotifications;
type UseNotificationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAdminNotifications = ({ config }: UseNotificationOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['notifications-admin'],
    queryFn: () => getadminNotifications(),
  });
};
