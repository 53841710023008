import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PaginationQuery } from '@/types/PaginationQuery';
import { formatURL as url } from '@/utils/format';
import { RoleNameList } from '@/types';
import { TableResponse } from '@/types/TableResponse';

export const getPermissions = (
  pagination: PaginationQuery
): Promise<TableResponse<RoleNameList>> => {
  return axios.get(url('/admin-api/role-listing', pagination));
};

type QueryFnType = typeof getPermissions;

type UseFaqOptions = {
  config?: QueryConfig<QueryFnType>;
  page_per_data?: string;
  page: string;
};

export const usePermissions = ({ config, page_per_data = '10', page }: UseFaqOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['faq-list'],
    queryFn: () => getPermissions({ page_per_data, page }),
  });
};
