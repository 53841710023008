import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import * as z from 'zod';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropertyDetail from '@/components/Elements/Loader/PropertyDetail';
import { usePropertyForSale } from '@/api/propertyForSale/getPropertyForSale';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Carousel.css';
import GoogleMapReact from 'google-map-react';
import { Form, InputField, TextAreaField } from '@/components/Form';
import { Button } from '@/components/Elements';
import { inquiryPropertyForSale } from '@/api/propertyForSale/inquiryPropertyForSale';
import propertyCategory from '@/constants/propertyCategory';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';
import 'react-responsive-tabs/styles.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { InputPhone } from '@/components/Form/InputPhone';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';
import FsLightbox from 'fslightbox-react';
import storage from '@/utils/storage';
import i18next from 'i18next';
import { translateAmenties } from '@/translations/translateFunction';

function PropertyContent() {
  const [phone, setPhone] = useState<boolean>(false);
  const localCurrency = storage.getAmount();
  const localSymbol = storage.getSymbol();
  const [toggler, setToggler] = useState<boolean>(false);
  const [pics, setPics] = useState([]);
  const [productIndex, setProductIndex] = useState(0);

  const AnyReactComponent = ({ text }: { text: any }) => <div>{text}</div>;
  const auth = useAuth();
  const loggedinId = auth.user?.customer_id;
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = usePropertyForSale({ id: id ?? '2' });

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      // setScroll(window.scrollY > 700);

      if (window.scrollY > 700) {
        if (window.scrollY > 1800) {
          setScroll(false);
        } else {
          setScroll(true);
        }
      } else {
        setScroll(false);
      }
    });
  }, []);

  const customerId = data?.data?.customer_id;
  const property = data?.data;
  const [iLat, setiLat] = useState(0);
  const [iLong, setiLong] = useState(0);
  useEffect(() => {
    if (property) {
      const parsedLat = parseFloat(property.latitude);
      const parsedLong = parseFloat(property.longitude);

      if (!isNaN(parsedLat) && !isNaN(parsedLong)) {
        setiLat(parsedLat);
        setiLong(parsedLong);
      } else {
        console.error('Invalid latitude or longitude values');
      }
    }
  }, [property]);
  const categoryVal = property?.category;

  const amenitiesValues = property?.amenities ?? '';
  const amenitiesArray = amenitiesValues.split(',');

  const schema = z.object({
    telephone: z.custom(isValidMobile, isValidMobileMsg),

    message: z.string().min(1, 'Please enter a message'),
    email: z
      .string()
      .min(1, 'Please enter email address')
      .email('Please enter valid email address!'),
  });

  type inquiryAd = {
    telephone: string;
    message: string;
    email: string;
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: inquiryAd) => {
    try {
      setLoading(true);

      await inquiryPropertyForSale({
        ...values,
        project_id: `${id}`,
        id: `${id}`,
        type: `SaleProperty`,
      });
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'info',
        message: 'Inquiry Sent successfully!',
      });
    } finally {
      setLoading(false);
      localStorage.removeItem('values');
    }
  };
  const optionsCar = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<', '>'],
    rewind: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 3,
      },
      600: {
        items: 4,
      },
      700: {
        items: 5,
      },
      1000: {
        items: 5,
      },
    },
  };
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<', '>'],
    rewind: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  useEffect(() => {
    // eval(property?.image_data)?.map((val) => {
    //   setPics((item) => [...item, val?.image]);
    // });
  }, [isLoading, loading]);

  const handleToggler = (index: number) => {
    setToggler((val) => !val);
    setProductIndex(index);
  };

  return (
    <>
      {isLoading || loading ? (
        <PropertyDetail />
      ) : (
        <div className="Content">
          {/* {eval(property?.image_data).length == 0 ? null : (
            <>
              <div className="container prop-content-slide">
                <div className="row mt-5">
                  <div className="col-md-12 col-12">
                    <div className="propertyCarousel noter ">
                      <OwlCarousel {...options} className="owl-theme" loop nav>
                        {eval(property?.image_data).map((item: any, index: number) => (
                          <div key={index}>
                            <div className="item">
                              {item?.name && <span className="imageNames">{item?.name}</span>}
                              <img alt="hias" src={item?.image} />
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                  </div>

                  <div className="col-md-12 col-12 row picsfor fiveBOxes">
                    {property?.image_data?.length <= 4 ? (
                      <>
                        {eval(property?.image_data)
                          .slice(0, 4)
                          .map((item: any, index: number) => (
                            <div className="col-md-3 col-6" key={index}>
                              <img
                                alt="hixas"
                                src={item?.image}
                                onClick={() => handleToggler(index)}
                              />
                            </div>
                          ))}
                      </>
                    ) : (
                      <OwlCarousel {...optionsCar} className="owl-theme" loop nav>
                        {eval(property?.image_data).map((item: any, index: number) => (
                          <div key={index}>
                            <div className="item">
                              <img
                                alt="hias"
                                src={item?.image}
                                onClick={() => handleToggler(index)}
                              />
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    )}
                  </div>
                  <FsLightbox toggler={toggler} sources={pics} sourceIndex={productIndex} />
                </div>
              </div>
            </>
          )} */}

          <div className="row col-md-12 col-12">
            <img alt="hixas" src={property?.image_data} />
          </div>

          <section className="bg-light-pink mt-5 main-prop-details">
            <div className="container">
              <div className="row col-12 propertyName">
                <div className="col-md-12 col-12 col-lg-8 left-columns">
                  <p className="property-name dark-color">{property?.title}</p>
                  <p className="property-category">
                    {Object.entries(propertyCategory).map(([key, val], i) => (
                      <span key={i}>{key == categoryVal ? val : null}</span>
                    ))}
                  </p>
                  <h3 className="money">
                    {' '}
                    {localSymbol} {(property?.price * localCurrency).toFixed(0)}{' '}
                  </h3>
                  <i className="fa-solid fa-location-dot"></i>
                  <span className="ms-2">
                    {' '}
                    {property?.country} , {property?.state} , {property?.city}
                  </span>
                  <hr />
                  <div className="row miniBox my-3">
                    <div className="col-12 col-md-4  mainBOx">
                      <div className="row inBOxc">
                        <div className="leftSide ">
                          <p className="mb-0">
                            <i className="fa-solid fa-bed"></i>
                            <span> {property?.bedrooms}</span>
                          </p>
                          <p>{i18next.t('Bedrooms')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4  mainBOx">
                      <div className="row inBOxc">
                        <div className="leftSide">
                          <p className="mb-0">
                            <i className="fa-solid fa-bath"></i>
                            <span>{property?.bathrooms}</span>
                          </p>
                          <p>Bathrooms</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4  mainBOx">
                      <div className="row inBOxc">
                        <div className="leftSide">
                          <p className="mb-0">
                            <i className="fa-solid fa-building-wheat"></i>
                            <span>{property?.size}</span>
                          </p>
                          <p>Size {i18next.t('Sq.Ft')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="adrs house-pr-add pt-0">
                    <p>
                      <i className="fa-solid fa-map-pin"></i>
                      {i18next.t('Neighbourhood')} : {property?.neighbourhood}
                    </p>
                    <p>
                      <i className="fa-solid fa-location-dot"></i> Occupancy Status :
                      {property?.occupancy_status}
                    </p>
                    <p>
                      <i className="fa-solid fa-phone-volume"></i> {i18next.t('Phone')} :{' '}
                      {property?.phone_number}
                    </p>
                    <p>
                      <i className="fa-solid fa-building"></i> {i18next.t('Building')} :{' '}
                      {property?.building}
                    </p>
                    <p>
                      <Link to={property?.youtube_url ?? ''}>
                        <i className="fa-brands fa-youtube"></i> {i18next.t('Watch video')}
                      </Link>
                    </p>
                  </div>
                  <p>
                    {property?.user_is == 'Landlord' ? (
                      <Button onClick={onOpenModal}> {i18next.t('Landloard Detail')}</Button>
                    ) : (
                      <Button onClick={onOpenModal2}>Agent Detail</Button>
                    )}
                  </p>
                  <div className="main-invest-return">
                    <div className="rsmain invest-return  row">
                      <div className="col-md-4 col-12 no-space">
                        <div className="rsInner">
                          <p className="toprs">{i18next.t('Annual community fee')}</p>
                          <p className="bottomrs">
                            {localSymbol}{' '}
                            {(property?.annual_community_fee * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12  no-space">
                        <div className="rsInner">
                          <p className="toprs">{i18next.t('Buyer transfer fee')} </p>
                          <p className="bottomrs">
                            {localSymbol}{' '}
                            {(property?.buyer_transfer_fee * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12  no-space">
                        <div className="rsInner">
                          <p className="toprs"> {i18next.t('Seller transfer fee')} </p>
                          <p className="bottomrs">
                            {localSymbol}{' '}
                            {(property?.seller_transfer_fee * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="rsmain invest-return  row">
                      <div className="col-md-4 col-12 no-space">
                        <div className="rsInner">
                          <p className="toprs">{i18next.t('Closing Fee')}</p>
                          <p className="bottomrs">
                            {localSymbol} {(property?.closing_fee * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 no-space">
                        <div className="rsInner">
                          <p className="toprs">{i18next.t('Annual Fee')}</p>
                          <p className="bottomrs">
                            {localSymbol}{' '}
                            {(property?.annual_community_fee * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 no-space">
                        <div className="rsInner">
                          <p className="toprs">{i18next.t('Maintenance fee')}</p>
                          <p className="bottomrs">
                            {localSymbol} {(property?.maintenance_fee * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 no-space">
                        <div className="rsInner">
                          <p className="toprs">{i18next.t('Price')}</p>
                          <p className="bottomrs">
                            {localSymbol} {(property?.price * localCurrency).toFixed(0)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row place-offers-rw mb-4">
                    <div className="main-datas col-12 col-md-12 ">
                      <div className="cardss w-100 bg-white">
                        <span className="titleHeader">{i18next.t('What this place offers')} </span>
                        <div className="right-div aps row">
                          <div className="col-md-6 col-12">
                            <div className="place-offers-maincol">
                              <p>
                                {amenitiesArray.includes('Garden_view') ? (
                                  <>
                                    <i className="fa-solid fa-tree"></i>
                                    {i18next.t('Garden View')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('Kitchen') ? (
                                  <>
                                    <i className="fa-solid fa-kitchen-set"></i>{' '}
                                    {i18next.t('Kitchen')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('Linens') ? (
                                  <>
                                    <i className="fa-solid fa-shirt"></i> {i18next.t('Linens')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('tv') ? (
                                  <>
                                    <i className="fa-solid fa-tv"></i> {i18next.t('Telivision')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('Extra_Chairs') ? (
                                  <>
                                    <i className="fa-solid fa-chair"></i> {i18next.t('Extra Chair')}
                                  </>
                                ) : null}
                              </p>

                              <p>
                                {' '}
                                {amenitiesArray.includes('Charger') ? (
                                  <>
                                    <i className="fa-solid fa-bolt"></i> {i18next.t('Charger')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('Candels') ? (
                                  <>
                                    <i className="fa-solid fa-hanukiah"></i> {i18next.t('Candels')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('Utensils') ? (
                                  <>
                                    <i className="fa-solid fa-utensils"></i> {i18next.t('Utensils')}
                                  </>
                                ) : null}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="place-offers-maincol">
                              <p>
                                {' '}
                                {amenitiesArray.includes('Bath_Tub') ? (
                                  <>
                                    <i className="fa-solid fa-bath"></i> {i18next.t('Bath Tub')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {amenitiesArray.includes('Hair_Chair') ? (
                                  <>
                                    <i className="fa-solid fa-couch"></i> {i18next.t('Couch')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {' '}
                                {amenitiesArray.includes('Window_ac_unit') ? (
                                  <>
                                    <i className="fa-solid fa-temperature-low"></i>{' '}
                                    {i18next.t('Window Ac Unit')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {' '}
                                {amenitiesArray.includes('Pets_Allowed') ? (
                                  <>
                                    <i className="fa-solid fa-dog"></i> {i18next.t('Pets Allowed')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {' '}
                                {amenitiesArray.includes('Dedicated_Workspace') ? (
                                  <>
                                    <i className="fa-solid fa-briefcase"></i>{' '}
                                    {i18next.t('Dedicated Work Space')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {' '}
                                {amenitiesArray.includes(' Extra_Pillows_And_Blankets') ? (
                                  <>
                                    <i className="fa-solid fa-mattress-pillow"></i>{' '}
                                    {i18next.t('Extra Pillow And Blanket')}
                                  </>
                                ) : null}
                              </p>
                              <p>
                                {' '}
                                {amenitiesArray.includes('Extra_bed') ? (
                                  <>
                                    <i className="fa-solid fa-bed"></i> {i18next.t('Extra Bed')}
                                  </>
                                ) : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-datas outlets propert-details d-block col-12 col-md-12  col-lg-4">
                  <div className="profileFoto ">
                    <div className="card mainDIv">
                      <div className="topPics">{/* <img src={li7} alt="picture" /> */}</div>

                      <div className="midData text-center pt-2">
                        <h5>{translateAmenties('User Name')}</h5>
                        <p className="mb-0">{translateAmenties('User Bio"')} </p>
                      </div>
                      <hr />

                      <div className="bottomButn pb-2 text-center">
                        <Button
                          className="m-auto "
                          onClick={() => navigate(`/profile-details?id=${customerId}`)}
                        >
                          {translateAmenties('View Profile')}{' '}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="prop-cardss">
                    <div className="cardss iqforms  mt-4">
                      <Button
                        onClick={() => setPhone(true)}
                        className={clsx(phone == true ? 'clearBack' : 'pinkBack')}
                      >
                        {phone ? (
                          <>
                            <span className="hhh">
                              {' '}
                              <i className="fa-solid fa-phone-volume"></i> {property?.phone_number}
                            </span>
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-phone-volume"></i>{' '}
                            {i18next.t('Show Phone Number')}
                          </>
                        )}
                      </Button>

                      <div className="inquireformhover">
                        <span className="titleHeader2"> {i18next.t('Inquiry about the ad')}</span>
                        <fieldset disabled={loggedinId === customerId}>
                          <Form<inquiryAd>
                            onSubmit={handleSubmit}
                            schema={schema}
                            className="formMsg"
                          >
                            {({ register, formState }) => (
                              <>
                                <InputPhone
                                  floating
                                  error={formState.errors['telephone']}
                                  registration={register('telephone')}
                                />
                                <InputField
                                  label={translateAmenties('Email')}
                                  error={formState.errors['email']}
                                  registration={register('email')}
                                />
                                <TextAreaField
                                  label={translateAmenties('Message')}
                                  error={formState.errors['message']}
                                  registration={register('message')}
                                />
                                {loggedinId === customerId && (
                                  <p className="messages">You are owner of this Property</p>
                                )}
                                <div className="iq-div">
                                  <Button type="submit" className="iq-btn dark-btn">
                                    {translateAmenties('Inquire Now')}
                                  </Button>
                                </div>
                              </>
                            )}
                          </Form>
                        </fieldset>
                      </div>
                    </div>

                    <div className="gmaps mt-4">
                      <div style={{ height: '400px', width: '100%' }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                          defaultCenter={{ lat: iLat, lng: iLong }}
                          defaultZoom={13}
                        >
                          <AnyReactComponent
                            lat={iLat}
                            lng={iLong}
                            text={<i className="fa-sharp fa-solid fa-location-dot googleEarth"></i>}
                          />
                        </GoogleMapReact>
                      </div>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="modalSection col-md-6">
            <Modal open={open} onClose={onCloseModal} center>
              <section className="model-datas">
                <h3> {i18next.t('Landloard Detail')}</h3>
                <p>Name : {property?.landlord_name}</p>
                <p>
                  {i18next.t('Property Type')} : {property?.property_type}
                </p>
                <p>
                  {i18next.t('Deed Number')} : {property?.title_deed_number}
                </p>
              </section>
              <p className="btn-lines">
                <Button onClick={onCloseModal}>OKAY</Button>
              </p>
            </Modal>
            <Modal open={open2} onClose={onCloseModal2} center>
              <section className="model-datas">
                <h3>Agent Detail</h3>
                <p>Name : {property?.agent_name}</p>
                <p>Broker id : {property?.broker_id_number}</p>
                <p>Lister company name : {property?.lister_company_name}</p>
                <p>Permit NUmber : {property?.permit_number}</p>
              </section>
              <p className="btn-lines">
                <Button onClick={onCloseModal2}>OKAY</Button>
              </p>
            </Modal>
          </section>
        </div>
      )}
    </>
  );
}

export default PropertyContent;
