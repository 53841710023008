import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from '@/components/Elements/Card';
import { Button } from '@/components/Elements/Button';
import { AdminLayout } from '@/components/Layout';
import './RoleManagement.css';
import { editRoles } from '@/features/admin/api/userRoleManagement/editRoles';
import { getRoleData } from '@/features/admin/api/userRoleManagement/getRoleData';
import { usePermissions } from '@/features/admin/api/userRoleManagement/getPermissions';
import * as z from 'zod';
import { Form, InputField, SelectField } from '@/components/Form';
import { SubAdminData } from '@/types';
import RolesContent from '@/components/Elements/Loader/RolesContent';
import { useNotificationStore } from '@/stores/notifications';
import i18next from 'i18next';

function EditAssignUsersRole() {
  const { id } = useParams();
  const page = '10';
  const { data } = usePermissions({ page });
  const permissionList = data?.data;

  const [user, setUser] = useState<SubAdminData>();
  const [isLoader, setisLoader] = useState<boolean>(false);

  const getDataOfApi = async () => {
    setisLoader(true);
    if (id != null) {
      const data = await getRoleData(id);
      const userIn = data?.data ?? ({} as SubAdminData);
      setUser(userIn);
    }
    setisLoader(false);
  };

  const options = { ...user };

  useEffect(() => {
    getDataOfApi();
  }, [id]);

  const schema = z.object({
    firstName: z.string().min(1, 'Please enter role title'),
    lastName: z.string().min(1, 'Please enter role title'),
    email: z
      .string()
      .min(1, 'Please enter email address')
      .email('Please enter valid email address!'),
    phoneNumber: z
      .string()
      .min(1, 'Please enter phone Number')
      .max(10, 'Phone number should not exceed 10 digits')
      .regex(/^[-0-9+]+$/, 'Phone should contain numbers only!'),
    role: z.any(),
    password: z.string(),
  });

  type ContactValues = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: any;
    password: string;
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const optionsList = permissionList?.map((item) => ({
    label: item.role_name,
    value: item.id,
  }));

  const optionss = [{ label: 'User Management', value: 'user_management' }];

  const handleSubmit = async (values: ContactValues) => {
    setLoading(true);

    await editRoles({
      ...values,
      id: id,
    });
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'success',
      message: 'Role eddited successfully!',
    });
    setLoading(false);
    navigate('/admin/user-roles');
  };

  return (
    <>
      {isLoader ? (
        <RolesContent />
      ) : (
        <>
          <AdminLayout title="Edit role of user ">
            <Card shadow>
              <div className="p-3 pb-0">
                <h5>
                  <strong> Roles here </strong>
                </h5>
              </div>

              <Form<ContactValues, typeof schema>
                onSubmit={handleSubmit}
                schema={schema}
                options={{
                  defaultValues: options,
                }}
              >
                {({ register, formState }) => (
                  <>
                    <div className="mx-4">
                      <InputField
                        type="text"
                        label="First Name"
                        error={formState.errors['firstName']}
                        registration={register('firstName')}
                      />
                    </div>
                    <div className="mx-4">
                      <InputField
                        type="text"
                        label="Last Name"
                        error={formState.errors['lastName']}
                        registration={register('lastName')}
                      />
                    </div>
                    <div className="mx-4">
                      <InputField
                        type="text"
                        disabled
                        label={i18next.t('Email')}
                        error={formState.errors['email']}
                        registration={register('email')}
                      />
                    </div>
                    <div className="mx-4">
                      <InputField
                        type="text"
                        label={i18next.t('Phone')}
                        error={formState.errors['phoneNumber']}
                        registration={register('phoneNumber')}
                      />
                    </div>
                    <div className="mx-4">
                      <InputField
                        type="password"
                        label={i18next.t('Password')}
                        error={formState.errors['password']}
                        registration={register('password')}
                      />
                    </div>
                    <div className="mx-4">
                      <SelectField
                        options={optionsList ?? optionss}
                        label="Select role"
                        error={formState.errors['role']}
                        registration={register('role')}
                        placeholder="Select role"
                      />
                    </div>

                    <div className="mx-4">
                      <div className="d-flex justify-content-end my-5">
                        <Button
                          onClick={() => navigate(-1)}
                          variant="inverse"
                          block
                          className="me-3"
                        >
                          {i18next.t(`Cancel`)}
                        </Button>
                        <Button isLoading={loading} type="submit" block>
                          Save
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </Card>
          </AdminLayout>
        </>
      )}
    </>
  );
}

export default EditAssignUsersRole;
