import React from 'react';
import { AdminLayout } from '@/components/Layout';
import { usePagination } from '@/hooks/usePagination';
import CurrencyListing from './CurrencyListing';

export const Currency = () => {
  const { page, changePage } = usePagination();

  return (
    <AdminLayout title="Currency Listing">
      <CurrencyListing page={page} changePage={changePage} />
    </AdminLayout>
  );
};
