import React, { useState } from 'react';
import * as z from 'zod';

import plus from '@/assets/plus.png';
import { Form, InputField, SelectField, TextAreaField } from '@/components/Form';

import { ContentLayout } from '@/components/Layout';
import experiences from '@/constants/experiences';
import { Button } from '@/components/Elements';
import { uploadImage } from '@/api/uploadImage';
import { formatString } from '@/utils/format';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';
import { useNavigate } from 'react-router-dom';
import BackdropLoader from '@/components/Elements/Loader/BackdropLoader';
import { useUserStore } from '@/stores/user';
import { completeAgencyProfile } from '../api/completeAgencyProfile';
import i18next from 'i18next';

const schema = z
  .object({
    first_name: z.string().min(1, 'Please enter first name'),
    last_name: z.string().min(1, 'Please enter last name'),
    email: z.string().min(1, 'Please enter email address'),
    address: z.string().min(1, 'Please enter address'),
    dob: z.string().min(1, 'Please select date of birth'),
    user_type: z.string().min(1, 'Please select user type'),
    your_self: z.string().min(1, 'Please enter description'),
    past_experience: z.string().min(1, 'Please select past experience'),
    company: z.string().optional(),
  })
  .superRefine((entry: any, ctx) => {
    if (entry.user_level === 'company') {
      ['company'].forEach((i) => {
        if (!entry[i]) {
          ctx.addIssue({
            path: [i],
            code: 'custom',
            message: `Please enter ${formatString(i)}`,
          });
        }
      });
    }
  });

type CompleteProfileValues = {
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  dob: string;
  user_type: string;
  your_self: string;
  past_experience: string;
  company: string;
};

export const CompleteAgencyProfile = () => {
  const [file, setFile] = useState<File | null>(null);
  const [profilePicture, setPicturePreview] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { user, refetchUser } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setPicturePreview(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (values: CompleteProfileValues) => {
    try {
      setLoading(true);
      let url = '';
      if (file) {
        const response = await uploadImage({ file });
        url = response.url;
      }
      await completeAgencyProfile({ ...values, profile_image: url });
      useNotificationStore.getState().addNotification({
        title: 'Success',
        type: 'success',
        message: 'Profile completed successfully!',
      });
      await refetchUser();
      if (!user?.phoneVerified) {
        useUserStore.getState().show();
      }
      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentLayout title="Create Profile">
      <BackdropLoader open={loading} />

      <button onClick={() => navigate(-1)}>
        <i className="fa-regular fa-circle-left go-back-button"></i>
      </button>
      <div className="container mt-4">
        <h3>Create Profile</h3>
        <div className="profile-box">
          <h5>Profile Photo</h5>
          <label htmlFor="fileInput">
            <div className="image-box">
              {profilePicture ? (
                <img src={profilePicture} alt="" className="profileImage" />
              ) : (
                <img src={plus} alt="" width="60" />
              )}
            </div>
          </label>
          <input onChange={handleChange} type="file" id="fileInput" className="d-none" />
          <Form<CompleteProfileValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
            {({ register, formState }) => (
              <>
                <div className="row mt-4">
                  <div className="col-6 mt-4">
                    <InputField
                      type="text"
                      label="First Name"
                      error={formState.errors['first_name']}
                      registration={register('first_name', {
                        value: user?.first_name,
                      })}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputField
                      type="text"
                      label="Last Name"
                      error={formState.errors['last_name']}
                      registration={register('last_name', {
                        value: user?.last_name,
                      })}
                      placeholder="First Name"
                    />
                  </div>

                  <div className="col-6 mt-4">
                    <InputField
                      type="text"
                      label="Address"
                      error={formState.errors['address']}
                      registration={register('address')}
                      placeholder="Address"
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputField
                      type="date"
                      label="Date of birth"
                      error={formState.errors['dob']}
                      registration={register('dob', {
                        value: user?.dob,
                      })}
                      placeholder="Date of birth"
                    />
                  </div>

                  <div className="col-6 mt-4">
                    <SelectField
                      label="User Type"
                      error={formState.errors['user_type']}
                      registration={register('user_type')}
                      placeholder="User Type"
                      options={['Landlord', 'Tenant', 'Property Developer', 'Other'].map((o) => ({
                        label: o,
                        value: o.toLowerCase(),
                      }))}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputField
                      type="email"
                      label={i18next.t('Email')}
                      error={formState.errors['email']}
                      registration={register('email', {
                        value: user?.email,
                      })}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <TextAreaField
                      rows={5}
                      label="Tell About Your Self"
                      error={formState.errors['your_self']}
                      registration={register('your_self')}
                    />
                  </div>

                  <div className="col-6 mt-4">
                    <SelectField
                      label="Experience"
                      error={formState.errors['past_experience']}
                      registration={register('past_experience')}
                      placeholder="User Type"
                      options={experiences.map((o) => ({
                        label: o,
                        value: o,
                      }))}
                    />
                  </div>
                </div>
                <div className="row mt-0">
                  <div className="col-6 mt-4">
                    <InputField
                      type="text"
                      label={i18next.t('Company Name')}
                      error={formState.errors['company']}
                      registration={register('company')}
                      placeholder={i18next.t('Company Name')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button isLoading={loading} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </ContentLayout>
  );
};
