import React from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import Footer from '@/components/Layout/Footer/Footer';
import { ContentLayout } from '@/components/Layout';
import i18next from 'i18next';

export const About = () => {
  return (
    <ContentLayout title="About">
      <div>
        <Header type={HeaderType.LANDING} />
        {/* <section className="promo-about-pg py-5" style={{ backgroundImage: `url(${aboutBanner})` }}> */}
        <section className="promo-about-pg py-5">
          <div className="container">
            <div className="about-content text-center">
              <h2 className="f-30 semibold">{i18next.t('About Us')}</h2>
            </div>
          </div>
        </section>
        <section className="about-content-first pt-5 pb-5">
          <div className="container">
            <div className="about-us-box">
              <h5 className="bold text-left pt-3 pb-2">
                {i18next.t('We Are Here for All Real Estate Investment Needs')}
              </h5>
              <p className="f-14">
                {i18next.t(
                  'REEIPO is passionate about property and offers a unique, transparent experience for all of your real estate investment needs. Check out our large marketplace if you’re looking for your next property, investment, renter, or business opportunity. After all, real estate is better when it’s at your fingertips, on your time.'
                )}
              </p>
              <h5 className="bold text-left pt-3 pb-2">
                {i18next.t('Efficient Real Estate and Investment Deals')}
              </h5>
              <p className="f-14">
                {i18next.t(
                  'REEIPO offers efficient options where the deals come to you as well as do-it-yourself options. Our focus is delivering the same seamless experience and helping people take their next steps intentionally.'
                )}
                <br />{' '}
                {i18next.t(
                  'We offer traditional real estate buying and selling services, particularly for residential properties. With options ranging from do-it-yourself with a bit of legal help to finding someone to handle the transaction for you, REEIPO makes real estate easy.'
                )}
                <br />
                {i18next.t(
                  'REEIPO also offers assistance with rentals. Both properties and people can create listings on our site, which is unique. Therefore, someone can search for their next rental or be scouted as a desirable tenant. The choice is yours.'
                )}
                <br />{' '}
                {i18next.t(
                  'We also have project investment opportunities if you have a more commercial focus. These projects can range from small units to whole developments. This section always has exciting opportunities for savvy investors.'
                )}
                <br />{' '}
                {i18next.t(
                  'REEIPO also helps connect businesses with investors and buyers. These options help communities remain strong and provide vital capital to help them grow. Plus, these opportunities often yield great returns.'
                )}
                <br />{' '}
                {i18next.t(
                  'Our commitment is simple. If you have real estate needs, we’ll deliver options to meet them at your fingertips, on your time.'
                )}
              </p>
              <h5 className="bold text-left pt-3 pb-2">{i18next.t('REEIPO Meets Your Desires')}</h5>
              <p className="f-14">
                {i18next.t(
                  'The beautiful thing about REEIPO is the opportunities it creates. Since the website is always available and dedicated to the best opportunities, new options from your local area are always being added.'
                )}
                <br />
                {i18next.t(
                  'The way we treat you as a customer is even more critical than our deals. We know your time is valuable, so we’ve done our best to streamline everything from finding an investment to making a deal. Plus, we let you decide what you need help with!'
                )}
                <br />
                {i18next.t(
                  'We’re also dedicated to simplicity. Everything from our listings to our website focuses on it in line with one truth: simple works. Browse clear listings with spelled-out terms and practical descriptions whenever you want.'
                )}
                <br />
                {i18next.t(
                  'Realistically, REEIPO is constantly growing as people add more properties and investments. We promise to stay true to the simple, honest principles we began with, no matter where the next step goes.'
                )}
              </p>
              <h5 className="bold text-left pt-3 pb-2">
                {i18next.t('Let Us Streamline Your Project')}
              </h5>
              <p className="f-14">
                {i18next.t(
                  'REEIPO creates a seamless, streamlined experience, whether you want to offer property, find investors, add a rental, or buy a new investment. Check out how straightforward real estate can be with us.'
                )}{' '}
                <br />
                {i18next.t(
                  'Our first step is the list feature. It takes a few questions to list a property or investment opportunity, not pages of paperwork. With our process, your opportunity starts gathering eyes faster, and you may have an opportunity to create the deal sooner.'
                )}{' '}
                <br />
                {i18next.t(
                  'REEIPO also makes browsing for projects and properties easier. We provide streamlined thumbnails with pertinent information upfront so you can decide what’s worth your time before you click a single listing.'
                )}{' '}
                <br />
                {i18next.t(
                  'We also make connecting with other users easy. In order to connect, a profile must verify a phone number. However, from there, the connection happens at the push of a button. Talk about speeding up your deal!'
                )}
                <br />{' '}
                {i18next.t(
                  'Our site offers real estate and investment options that match your evolving needs. Whether you need to sell one property or make your living with real estate investment, we have options to help you take the next step in the exciting world of property investment.'
                )}
              </p>
              <h5 className="bold text-left pt-3 pb-2">
                {i18next.t('Our Clear as Crystal Transparency')}{' '}
              </h5>
              <p className="f-14">
                {i18next.t(
                  'REEIPO is committed to transparency from the first moment you visit our site. We provide transparent listings and connections, no matter where you access our website from.'
                )}
                <br />
                {i18next.t(
                  'We’re also committed to transparency around how people use our site. We also believe in a transparent fee structure. Rather than worrying about hidden fees, we help you focus on what really matters: the right opportunity. Check out a different way to do real estate at your fingertips today!'
                )}
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </ContentLayout>
  );
};
