import axios from 'axios';
import { API_URL } from '@/config';

export type ResendOtp = {
  email: string;
};

export const resendOtp = (data: ResendOtp) => {
  return axios.post(`${API_URL}customer-api/register-resend`, data);
};
