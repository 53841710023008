import { axios } from '@/lib/axios';
import { ApiResponse, SubAdminData } from '@/types';

export type contentManagementDTO = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  role: any;
};

export const editRoles = (data: contentManagementDTO): Promise<ApiResponse<SubAdminData>> => {
  return axios.post('/admin-api/edit-sub-admin', data);
};
