import React from 'react';
import '../user.css';
import { Image } from '@/components/Elements';
// import preview from '@/assets/preview.png';
import preview from '@/assets/backWal.png';
// import { useNotifications } from '@/api/Notification/getNotifications';
import { Notification } from '@/types';
import { timeFromNow } from '@/utils/format';
import clsx from 'clsx';

const NotifyAll = ({
  item,
  selected,
  onSelectItem,
}: {
  item: Notification;
  selected: string[];
  onSelectItem: (item: string) => void;
}) => {
  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };
  return (
    <>
      <div className={clsx('row notification', item.is_read == false ? 'unread' : 'read')}>
        <div className="col-md-2 row">
          <div className="setWidth col-3 col-md-4">
            <input
              checked={selected.includes(item.id)}
              type="checkbox"
              onChange={() => onSelectItem(item.id)}
              onClick={handleClick}
              className="form-check-input"
            />
          </div>
          <div className="setWidthImg col-5 col-md-6">
            <Image imagefallback={preview} className="itemImages" alt="banner" src={item.image} />
          </div>
          <div className="setWidthdot col-2 col-md-2">
            {item.is_read == false ? <i className="fa-solid fa-circle notificationDot"></i> : null}
          </div>
        </div>
        <div className="col-12 col-md-8 cntnt">{item.message}</div>
        <div className="col-12 col-md-2 dates dateSize">{timeFromNow(item.date)} ago</div>
      </div>
    </>
  );
};

export default NotifyAll;
