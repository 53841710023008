import React, { useEffect, useState } from 'react';
import { Button, Table, TableColumn } from '@/components/Elements';
import { useNavigate } from 'react-router-dom';
import { deleteFaq } from '@/features/admin/api/deleteFaq';
import { RoleContent } from '@/types';
import './RoleManagement.css';
import { useNotificationStore } from '@/stores/notifications';
import TableLoader from '@/components/Elements/Loader/TableLoader';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useCurrencyListing } from '../../api/getCurrencyListing';
import { deleteCurrency } from '../../api/deleteCurrency';
import i18next from 'i18next';

type Content = {
  id: string;
  symbol: string;
  name: string;
  converted_price: string;
};

const CurrencyListing = ({
  page,
  changePage,
}: {
  page: string;
  changePage: (page: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const navigate = useNavigate();
  const { data, refetch, isLoading, isFetching, isRefetching } = useCurrencyListing({ page });
  const [datId, setDataId] = useState('');

  const deleteSelected = async () => {
    setLoader(true);
    await deleteCurrency(datId);
    refetch();
    setLoader(false);
    onCloseModal();
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'info',
      message: 'Currency data  deleted successfully!',
    });
  };
  useEffect(() => {
    refetch();
  }, [page, refetch]);
  const columns: TableColumn<Content>[] = [
    {
      title: 'Currency',
      field: 'name',
      Cell({ entry: { name } }) {
        return <span>{name}</span>;
      },
    },
    {
      title: 'Symbol',
      field: 'symbol',
      Cell({ entry: { symbol } }) {
        return <span>{symbol}</span>;
      },
    },
    {
      title: 'Converted Currency',
      field: 'converted_price',
      Cell({ entry: { converted_price } }) {
        return <span>{converted_price}</span>;
      },
    },
    {
      title: 'Action',
      field: 'id',
      Cell({ entry: { id } }) {
        return (
          <>
            <span
              role="button"
              tabIndex={-1}
              onKeyDown={() => navigate(`/admin/add-currency-content/${id}`)}
              onClick={() => navigate(`/admin/add-currency-content/${id}`)}
              className="icon me-2 buttons2"
              title="Edit"
            >
              <i className="fa-solid fa-pencil"></i>
            </span>
            <span
              role="button"
              tabIndex={-1}
              onKeyDown={() => setDataId(`${id}`)}
              onClick={() => {
                setDataId(`${id}`);
                onOpenModal();
              }}
              className="icon me-2 buttons2"
              title="Edit"
            >
              <i className="fa-solid fa-trash-can"></i>
            </span>
          </>
        );
      },
    },
  ];
  return (
    <>
      {isLoading && isFetching && isRefetching ? (
        <TableLoader />
      ) : (
        <div>
          <Button
            className="addFaqs"
            onClick={() => {
              navigate('/admin/add-currency-content');
            }}
          >
            Add Currency +
          </Button>

          {data?.data ? (
            <Table<RoleContent>
              data={data?.data ?? []}
              columns={columns}
              page={parseInt(page)}
              changePage={changePage}
              total={data?.total ?? 0}
            />
          ) : (
            <TableLoader />
          )}
          <Modal open={open} onClose={onCloseModal} center>
            <section className="model-datas delModel ">
              <p>Are you Sure you want to delete the currency</p>
            </section>
            <div className="row btn-lines">
              <div className="col-md-6 dispCentr">
                <Button onClick={onCloseModal}> {i18next.t(`Cancel`)}</Button>
              </div>
              <div className="col-md-6 dispCentr">
                <Button isLoading={loader} onClick={deleteSelected}>
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default CurrencyListing;
