import { useQuery } from 'react-query';
import { axios, axios2 } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { ApiResponse, Property } from '@/types';

export const getPropertyForSale = (id: string): Promise<ApiResponse<Property>> => {
  // return axios.get(`/panel-api/sale-property-details?id=${id}`);
  return axios2.get(`/customer-api/sale-property-details?id=${id}`);
};

type QueryFnType = typeof getPropertyForSale;

type UseBusinessOptions = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const usePropertyForSale = ({ config, id }: UseBusinessOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-property-detail'],
    queryFn: () => getPropertyForSale(id),
  });
};
