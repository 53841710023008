enum PermissionCategory {
  content_management = 'Content Management',
  property_management = 'Property Management',
  business_management = ' Business Management',
  housing_management = ' Housing Management',
  property_rent_management = ' Property Rent Management',
  property_project_management = 'Property Project Management',
  subscription_management = 'Subscription Management',
  user_management = ' User Management',
  inquiries_management = ' Inquiries Management',
}
export default PermissionCategory;
