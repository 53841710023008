import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  wrapperClass?: string;
  disabled?: boolean;
  registration: Partial<UseFormRegisterReturn>;
  rows?: number;
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, wrapperClass, className, registration, rows = 10, error, disabled } = props;
  return (
    <FieldWrapper className={wrapperClass ?? ''} label={label} error={error}>
      <div className="form-floating">
        <textarea
          disabled={disabled}
          placeholder="Write a text here..."
          rows={rows}
          className={clsx('form-control h-100', className)}
          {...registration}
        />
        <label>{label}</label>
      </div>
    </FieldWrapper>
  );
};
