import nextArrow from "@/assets/arrowNext.png";
import prevArrow from "@/assets/arrowPrev.png";
import { Form } from '@/components/Form';
import { TextAreaField } from '@/components/Form/TextareaField';
// import SelectField from "@/components/Form/SelectField";
import { InputField } from '@/components/Form/InputField';


const StepSecond = () => {
    
    
    return (


  <div className="form-component">
    <div className="d-flex justify-content-between mb-4">
        <div className="d-flex tab-buttons">
            <button
            className="tab-button active"
            >
            Add Shareholder
            </button>
        </div>

        </div>

        <div className="card">
        <div className="card-body">
            <div className="property-info-form">
            <p className="form-heading pb-2 mb-3">Property Information</p>
            <Form<FormValues>
                onSubmit={async (values) => {
                alert(JSON.stringify(values, null, 2));
                }}
                id="my-form"
            >
            {({ register, formState }) => (
                <>
                <div className="row">
                    <div className="col-md-8">
                        <div className="form-block d-flex flex-column gap-2">

                            <InputField
                                type="text"
                                label="Name"
                                error={formState.errors['company_name']}
                                registration={register('company_name')}
                                placeholder="Content"
                            />
                            <span className="requiredField">Required</span>

                            <InputField
                                type="text"
                                label="Share"
                                error={formState.errors['company_name']}
                                registration={register('company_name')}
                                placeholder="Content"
                            />
                            <span className="requiredField">Required</span>
                            
                            <TextAreaField
                                rows={4}
                                error={formState.errors['company_details']}
                                registration={register('company_details')}
                                label="Description"
                            />
                            <span className="requiredField">Required</span>

                        </div>
                    </div>
                </div>
                </>
                )}
            </Form>

            <div className="next-prev-buttons d-flex gap-4 align-items-center mt-5">
                <span className="arrow prev-arrow"><img src={prevArrow} className="d-block w-100" alt="" /></span>
                <button className="arrow next-arrow d-flex gap-2 align-items-center">Save<img src={nextArrow} className="d-block w-100" alt="" /></button>
            </div>
            </div>
        </div>
        </div>
    </div>
    )
}
export default StepSecond;