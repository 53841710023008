import create from 'zustand';
import storage from '@/utils/storage';

const localCurrency = storage.getCurrency();
const localAmount = storage.getAmount();
const localSymbol = storage.getSymbol();

type State = {
  currentCurrency: string;
};

type Action = {
  currencies: any[];
  setCurrencies: (currencies: any[]) => void;
  updateCurrency: (currentCurrency: string) => void;
  convertToDollar: (amount: any) => any;
};

export const usePersonStore = create<State & Action>((set) => ({
  currencies: [],
  currentCurrency: localCurrency || '',
  setCurrencies: (currencies) => {
    set(() => ({
      currencies,
    }));
  },
  updateCurrency: (inputCurrency) => {
    storage.setCurrency(inputCurrency);
    set((state) => {
      const currency = state.currencies.find((i) => i.id == inputCurrency);
      console.log(currency);
      storage.setAmount(currency?.converted_price);
      storage.setSymbol(currency?.symbol);
      return { currentCurrency: inputCurrency };
    });
    window.location.reload();
  },
  convertToDollar: (amount) => {
    if (localCurrency === 'USD') {
      return amount;
    } else {
      return `${localSymbol} ${amount * localAmount}`;
    }
  },
}));
