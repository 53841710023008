import React from 'react';
import '../user.css';
import { Image } from '@/components/Elements';
// import preview from '@/assets/preview.png';
import preview from '@/assets/backWal.png';

// import { useNotifications } from '@/api/Notification/getNotifications';
import { Notification } from '@/types';
import { timeFromNow } from '@/utils/format';
import clsx from 'clsx';

const Notify = ({ item }: { item: Notification }) => {
  // const { refetch } = useNotifications();
  // refetch();
  return (
    <>
      <div className={clsx('row notification', item.is_read == false ? 'unread' : 'read')}>
        <div className="col-3 col-md-2 notImg">
          <Image imagefallback={preview} className="itemImages" alt="banner" src={item.image} />
          <div>
            {item.is_read == false ? (
              <i className="fa-solid fa-circle notificationDot"></i>
            ) : (
              <i className="fa-solid fa-check"></i>
            )}
          </div>
        </div>

        <div className="col-9 col-md-8 cntnt">{item.message}</div>
        <div className="col-12 col-md-2 dates dateSize">{timeFromNow(item.date)} ago</div>
      </div>
    </>
  );
};

export default Notify;
