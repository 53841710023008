import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Landing, About, PropertyProject, AllNotifiations, TermCondition } from '@/features/misc';
// import ProSale from '@/features/misc/routes/PropertySale/ProSale';
import { useAuth } from '@/lib/auth';
import { adminRoutes } from '@/features/admin';
import { userRoutes } from '@/features/user';
import { agentRoutes } from '@/features/user/agentRoutes';
import { agencyRoutes } from '@/features/agency';
import { publicRoutes } from './public';
import { lawyerRoutes } from '@/features/lawyer';
import { lazyImport } from '@/utils/lazyImport';
import PropertyForSaleDetail from '@/features/misc/routes/PropertyForSale/PropertyForSaleDetail';
import PropertyForRentDetail from '@/features/misc/routes/PropertyForRent/PropertyForRentDetail';
import { NewLandingPage } from '@/features/misc/routes/NewLanding/NewLandingPage';
import { HomePage } from '@/features/misc/routes/NewPages/NewHome/HomePage';
import { Advertisement } from '@/features/misc/routes/PropertyProject_v2/PropertyProjects';
import { FindInvestor } from '@/features/misc/routes/FindInvestor/FindInvestor';
// import { OurService } from '@/features/misc/routes/OurService/OurService';
// import { PropertyManager } from '@/features/misc/routes/PropertyManager/PropertyManager';
// import { PropertyForSale } from '@/features/misc/routes/PropertyForSale/PropertyForSale';
// import { PropertyForRent } from '@/features/misc/routes/PropertyForRent/PropertyForRent';
// import { HousingForRent } from '@/features/misc/routes/HousingForRent/HousingForRent';
// import { Contact } from '@/features/misc/routes/Contact/Contact';
// import { PrivacyPoilcy } from '@/features/misc/routes/PrivacyPoilcy';
// import { ViewAsOther } from '@/features/user/routes/ViewAsOther/ViewAsOther';
// import { AllReview } from '@/features/user/routes/ViewAsOther/AllReview';
// import { SaleDetail } from '@/features/misc/routes/SaleDetail/SaleDetail';
// import { ForSale } from '@/features/misc/routes/ForSale/ForSale';
// import { HowItWorks } from '@/features/misc/routes/HowItWorks/HowItWorks';
// import { PropertyDetail } from '@/features/misc/routes/PropertyProject/PropertyDetail';
// import { HousingDetail } from '@/features/misc/routes/HousingForRent/HousingDetail/HousingDetail';
// import { InvestmentDetail } from '@/features/misc/routes/PropertyManager/InvestmentDetail';
// import { CompanyDetail } from '@/features/misc/routes/PropertyManager/CompanyDetail';

const { SaleDetail } = lazyImport(
  () => import('@/features/misc/routes/SaleDetail/SaleDetail'),
  'SaleDetail'
);
const { ForSale } = lazyImport(() => import('@/features/misc/routes/ForSale/ForSale'), 'ForSale');
const { HowItWorks } = lazyImport(
  () => import('@/features/misc/routes/HowItWorks/HowItWorks'),
  'HowItWorks'
);
const { PropertyDetail } = lazyImport(
  () => import('@/features/misc/routes/PropertyProject/PropertyDetail'),
  'PropertyDetail'
);
const { HousingDetail } = lazyImport(
  () => import('@/features/misc/routes/HousingForRent/HousingDetail/HousingDetail'),
  'HousingDetail'
);
const { InvestmentDetail } = lazyImport(
  () => import('@/features/misc/routes/PropertyManager/InvestmentDetail'),
  'InvestmentDetail'
);
const { CompanyDetail } = lazyImport(
  () => import('@/features/misc/routes/PropertyManager/CompanyDetail'),
  'CompanyDetail'
);

const { OurService } = lazyImport(
  () => import('@/features/misc/routes/OurService/OurService'),
  'OurService'
);
const { PropertyManager } = lazyImport(
  () => import('@/features/misc/routes/PropertyManager/PropertyManager'),
  'PropertyManager'
);
const { PropertyForSale } = lazyImport(
  () => import('@/features/misc/routes/PropertyForSale/PropertyForSale'),
  'PropertyForSale'
);
const { PropertyForRent } = lazyImport(
  () => import('@/features/misc/routes/PropertyForRent/PropertyForRent'),
  'PropertyForRent'
);
const { HousingForRent } = lazyImport(
  () => import('@/features/misc/routes/HousingForRent/HousingForRent'),
  'HousingForRent'
);
const { Contact } = lazyImport(() => import('@/features/misc/routes/Contact/Contact'), 'Contact');
const { PrivacyPoilcy } = lazyImport(
  () => import('@/features/misc/routes/PrivacyPoilcy'),
  'PrivacyPoilcy'
);
const { ViewAsOther } = lazyImport(
  () => import('@/features/user/routes/ViewAsOther/ViewAsOther'),
  'ViewAsOther'
);
const { AllReview } = lazyImport(
  () => import('@/features/user/routes/ViewAsOther/AllReview'),
  'AllReview'
);

export const AppRoutes = () => {
  const auth = useAuth();
  const loginHeader = localStorage.getItem('loginType');

  const commonRoutes = [
    { path: '/', element: <NewLandingPage /> },
    { path: '/profile-details', element: <ViewAsOther /> },
    { path: '/all-review', element: <AllReview /> },
    { path: '/our-service', element: <OurService /> },
    { path: '/terms-conditions', element: <TermCondition /> },
    { path: '/privacy-policy', element: <PrivacyPoilcy /> },
    { path: '/contact-us', element: <Contact /> },
    { path: '/business-for-sale', element: <ForSale /> },
    { path: '/read-Notifications', element: <AllNotifiations /> },
    { path: '/about-us', element: <About /> },
    { path: '/property-project', element: <PropertyProject /> },
    { path: '/property-Detail/:id/{type}', element: <PropertyDetail /> },
    { path: '/property-Detail/:id', element: <PropertyDetail /> },
    { path: '/property-Detail', element: <PropertyDetail /> },
    { path: '/:active_tab?', element: <HowItWorks /> },
    { path: '/housing-for-rent', element: <HousingForRent /> },
    { path: '/project-developer', element: <PropertyManager /> },
    { path: '/investment-detail/:id/', element: <InvestmentDetail /> },
    { path: '/company-detail/:id/', element: <CompanyDetail /> },
    { path: '/property-for-sale', element: <PropertyForSale /> },
    { path: '/property-for-rent', element: <PropertyForRent /> },
    // { path: '/sale-detail/:id/{type}', element: <SaleDetail /> },
    // { path: '/sale-detail/:id/', element: <SaleDetail /> },
    // { path: '/sale-detail/', element: <SaleDetail /> },
    { path: '/housing-detail/', element: <HousingDetail /> },
    { path: '/housing-detail/:id/', element: <HousingDetail /> },
    { path: '/property-for-sale-detail/:id/', element: <PropertyForSaleDetail /> },
    { path: '/property-for-rent-detail/:id/', element: <PropertyForRentDetail /> },
    { path: '/*', element: <Navigate to="/" /> },
    { path: '/new-landing', element: <HomePage /> },
    // { path: '/property-projects', element: <Advertisement /> },
    { path: '/find-investor', element: <FindInvestor /> },
  ];

  let allRoutes: RouteObject[] = [];

  //Check user is logged in
  if (auth.user) {
    //If user is customer

    if (auth.user.role === 'customer') {
      if (loginHeader === 'admin') {
        allRoutes = [...allRoutes, ...agentRoutes];
      } else {
        allRoutes = [...allRoutes, ...userRoutes];
      }
    }
    //If user is admin
    if (auth.user.role === 'admin') {
      allRoutes = [...allRoutes, ...adminRoutes];
    }
    //If user is agency
    if (auth.user.role === 'agency') {
      allRoutes = [...allRoutes, ...agencyRoutes];
    }
    //If user is lawyer
    if (auth.user.role === 'lawyer') {
      allRoutes = [...allRoutes, ...lawyerRoutes];
    }
  } else {
    allRoutes = [...publicRoutes];
  }

  allRoutes = [...allRoutes, ...commonRoutes];

  const element = useRoutes(allRoutes);

  return <>{element}</>;
};
