import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/Elements/Card';
import { Button } from '@/components/Elements/Button';
import { AdminLayout } from '@/components/Layout';
import './ContentManagement.css';
import { useContent } from '@/features/admin/api/getContent';
import { postContent } from '@/features/admin/api/postContent';
import * as z from 'zod';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

import { Form, InputField } from '@/components/Form';

import ContentManagement from '@/components/Elements/Loader/ContentManagement';

import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { InvestorContent } from '@/types/InvestorContent';
import i18next from 'i18next';
const stateFromHTML = require('draft-js-import-html').stateFromHTML;

function EditContent() {
  const schema = z.object({
    page_title: z.string().min(1, 'Please enter valid Title'),
    page_title_nw: z.string().min(1, 'Please enter valid Title in NOk'),
  });

  type ContactValues = {
    page_title: string;
    page_title_nw: string;
  };
  const { data, isLoading, isRefetching, refetch } = useContent();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorStatenok, setEditorStatenok] = useState(() => EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState<any>(null);
  const [htmlnok, setHtmlnok] = useState<any>(null);
  const user = data?.data ?? ({} as InvestorContent);

  useEffect(() => {
    if (user.page_content) {
      setHtml(user.page_content);
      setHtml(user.page_content_nw);
      const contentState = stateFromHTML(user.page_content);
      const contentStatenok = stateFromHTML(user.page_content_nw);
      const editorState = EditorState.createWithContent(contentState);
      const editorStatenok = EditorState.createWithContent(contentStatenok);
      setEditorState(editorState);
      setEditorStatenok(editorStatenok);
    }
  }, [user.page_content]);

  const handleChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const handleChangenok = (state: any) => {
    setEditorStatenok(state);
    convertContentToHTMLnok();
  };

  const convertContentToHTML = () => {
    const currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtml(currentContentAsHTML);
  };

  const convertContentToHTMLnok = () => {
    const currentContentAsHTML = draftToHtml(convertToRaw(editorStatenok.getCurrentContent()));
    setHtmlnok(currentContentAsHTML);
  };

  const handleSubmit = async (values: ContactValues) => {
    setLoading(true);
    await postContent({
      ...(values ?? ''),
      page_content: html ?? '',
      page_content_nw: htmlnok ?? '',
    });
    refetch();
    setLoading(false);
  };

  return (
    <>
      <button onClick={() => navigate(-1)}>
        <i className="fa-regular fa-circle-left go-back-button"></i>
      </button>
      <AdminLayout title="Edit Content Management">
        <Card shadow>
          <div className="p-3 pb-0">
            <h5>
              <strong> Edit </strong>
            </h5>
          </div>
          {isLoading || isRefetching ? (
            <ContentManagement />
          ) : (
            <Form<ContactValues, typeof schema>
              onSubmit={handleSubmit}
              schema={schema}
              options={{
                defaultValues: user,
              }}
            >
              {({ register, formState }) => (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <p>English</p>
                      <div className="mx-4">
                        <InputField
                          type="text"
                          label={i18next.t('Title')}
                          error={formState.errors['page_title']}
                          registration={register('page_title')}
                        />
                      </div>
                      <div className="mx-4">
                        <Editor editorState={editorState} onEditorStateChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p>Norwegian</p>
                      <div className="mx-4">
                        <InputField
                          type="text"
                          label={i18next.t('Title in NOk')}
                          error={formState.errors['page_title_nw']}
                          registration={register('page_title_nw')}
                        />
                      </div>
                      <div className="mx-4">
                        <Editor
                          editorState={editorStatenok}
                          onEditorStateChange={handleChangenok}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mx-4">
                    <div className="d-flex justify-content-end my-5">
                      <Button onClick={() => navigate(-1)} variant="inverse" block className="me-3">
                        {i18next.t(`Cancel`)}
                      </Button>
                      <Button isLoading={loading} type="submit" block>
                        Save
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Card>
      </AdminLayout>
    </>
  );
}

export default EditContent;
