import { ROLES } from '@/lib/authorization';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../../../auth/components/Layout';
import { LoginForm } from '../../../auth/components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();

  return (
    <Layout type={ROLES.ADMIN} title="Log in - Admin Account">
      <LoginForm type={ROLES.ADMIN} onSuccess={() => navigate('/admin')} />
    </Layout>
  );
};
