const pageType: {
  [key: string]: string;
} = {
  investor: 'investor',
  propertyDeveloper: 'propertyDeveloper',
  PropertyProject: 'Property_project',
  project: 'project',
  saleCondition: 'saleCondition',
  howItWorks: 'howItWorks',
  PropertySale: 'PropertySale',
  businessForSale: 'businessForSale',
  businessDeveloper: 'businessDeveloper',
  faq: 'faq',
  Terms_And_Conditions: 'Terms_And_Conditions',
  Privacy_Policy: 'Privacy_Policy',
};
export default pageType;
