import storage from '@/utils/storage';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '@/lib/authorization';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Register your account">
      <RegisterForm
        type={ROLES.CUSTOMER}
        onSuccess={(email: string) => {
          storage.setEmail(email);
          navigate('/auth/verify-otp');
        }}
      />
    </Layout>
  );
};
