import * as React from 'react';
import * as z from 'zod';
import './layout.css';
import img from '@/assets/new-logo.png';
import { Button } from '@/components/Elements';
import { Form, InputField, CheckBoxField } from '@/components/Form';
import { RegisterDTO, registerWithEmailAndPassword } from '../api/register';
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '@/lib/authorization';
import i18next from 'i18next';

const schema = z
  .object({
    email: z
      .string()
      .min(1, 'Please enter email address')
      .max(35, 'Cannot exceed 35 characters')
      .email('Please enter valid email address!'),
    company_name: z
      .string()
      .min(1, 'Please enter Company Name')
      .max(45, 'Cannot exceed 45 characters'),
    first_name: z
      .string()
      .min(1, 'Please enter first name')
      .max(25, 'Cannot exceed 25 characters')
      .regex(/^[a-zA-Z ]+$/, 'Name should contain alphabets only!'),
    last_name: z
      .string()
      .min(1, 'Please enter last name')
      .max(25, 'Cannot exceed 25 characters')
      .regex(/^[a-zA-Z ]+$/, 'Name should contain alphabets only!'),
    password: z
      .string()
      .min(1, 'Please enter password')
      .regex(
        /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%]).*$/,
        'Password should be a combination of uppercase alphabets, numbers, and special characters'
      )
      .refine((value) => !/\s/.test(value), 'Password cannot contain spaces'),
    confirmPassword: z.string().min(1, 'Please enter confirm password'),
    terms: z.boolean(),
    isRoleAgency: z.boolean().optional(),
  })
  .superRefine(({ terms, password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        path: ['confirmPassword'],
        code: 'custom',
        message: 'Confirm password should be same as password',
      });
    }
    if (terms !== true) {
      ctx.addIssue({
        path: ['terms'],
        code: 'custom',
        message: 'Please accept Terms and Conditions',
      });
    }
  });

type RegisterValues = {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
  isRoleAgency: boolean;
  loginUserType: string;
};

type RegisterFormProps = {
  onSuccess: (email: string) => void;
  type: ROLES;
};

export const RegisterForm = ({ onSuccess, type }: RegisterFormProps) => {
  const [isRegistering, setRegistering] = React.useState<boolean>(false);
  const [userType, setUserType] = React.useState('');
  const [roleAgency, setIsRoleAgency] = React.useState(false);

  React.useEffect(() => {
    if (type == ROLES.AGENCY) {
      setUserType('agency');
      setIsRoleAgency(true);
    } else if (type == ROLES.LAWYER) {
      setUserType('lawyer');
    } else if (type == ROLES.CUSTOMER) {
      setUserType('customer');
    }
  }, [type]);
  const navigate = useNavigate();

  const handleSubmit = async (values: RegisterValues) => {
    try {
      setRegistering(true);

      const payload: RegisterDTO = {
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        email: values.email,
        company_name: values.company_name,
        isRoleAgency: roleAgency,
        loginUserType: userType,
      };
      await registerWithEmailAndPassword(payload);
      onSuccess(values.email);
    } finally {
      setRegistering(false);
    }
  };

  return (
    <div className="register-form">
      <div className="row">
        <div className="col-md-4">
          <img src={img} width="70" alt="alttext" />
        </div>
        <div className="col-md-8">
          {/* {roleAgency ? (
            <Button onClick={() => navigate('/auth/register')}>Register as Customer</Button>
          ) : (
            <>
              <Button onClick={() => navigate('/auth/register-agency')}>Register as Agency</Button>
              <Button onClick={() => navigate('/auth/register-lawyer')}>Register as LAwyer</Button>
            </>
          )} */}
        </div>
      </div>

      <h1 className="mb-0 mt-3">{i18next.t('Hello')},</h1>
      {userType == 'agency' ? (
        <h1 className="text-primary">{i18next.t('agency')}!</h1>
      ) : (
        <>
          {userType == 'lawyer' ? (
            <h1 className="text-primary">{i18next.t('lawyer')}!</h1>
          ) : (
            <h1 className="text-primary">{i18next.t('welcome')}!</h1>
          )}
        </>
      )}
      <Form<RegisterValues, typeof schema>
        onSubmit={handleSubmit}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <div className="row mt-4">
              <div className="col-12 col-md-6 form-space">
                <InputField
                  type="text"
                  label={i18next.t('First name')}
                  error={formState.errors['first_name']}
                  registration={register('first_name')}
                />
              </div>
              <div className="col-12 col-md-6 form-space">
                <InputField
                  type="text"
                  label={i18next.t('Last name')}
                  error={formState.errors['last_name']}
                  registration={register('last_name')}
                />
              </div>
            </div>

            <InputField
              type="email"
              wrapperClass="mt-2"
              label={i18next.t('Email Address')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="text"
              wrapperClass="mt-2"
              label={i18next.t('Company Name')}
              error={formState.errors['company_name']}
              registration={register('company_name')}
            />

            <InputField
              type="password"
              label={i18next.t('Password')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label={i18next.t('Confirm Password')}
              error={formState.errors['confirmPassword']}
              registration={register('confirmPassword')}
            />

            <div className="tc">
              <CheckBoxField
                registration={register('terms')}
                error={formState.errors['terms']}
                // label="Terms & Conditions"
              />
              <span className="tandc">
                <Link to="/terms-conditions?page=Terms_And_Conditions">
                  {i18next.t('Terms & Conditions')}
                </Link>
              </span>
            </div>
            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full mb-5">
                {i18next.t('Sign Up')}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
