/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-var */
import { PaginationQuery } from '@/types/PaginationQuery';
import { default as dayjs } from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const formatDateTime = (date: string | number) => dayjs(date).format('MMMM D, YYYY h:mm A');
export const formatDate = (date: string | number) => dayjs(date).format('MMMM D, YYYY');
export const timeFromNow = (date: string) => (dayjs() as any).from(dayjs(date), true);

export const formatString = (str: string) => str.replace('_', ' ');

export const formatMoney = (str: string | number) => `$ ${str}`;
export const formatPrice = (str: string | number) =>
  ` ${typeof str === 'number' ? str.toFixed(0) : parseFloat(str).toFixed(0)}`;
export const formatPercent = (str: string | number) => `${str} %`;

export const fMinDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export const trimSixty = (string: string) => {
  const result: string = string?.slice(0, 60) + '...';
  return result;
};
export const trimThirty = (string: string) => {
  const result: string = string?.slice(0, 30) + '...';
  return result;
};
export const trimTwenty = (string: string) => {
  const result: string = string?.slice(0, 20) + '...';
  return result;
};
export const trimTen = (string: string) => {
  const result: string = string?.slice(0, 10) + '..';
  return result;
};

export const formatURL = (url: string, pagination: PaginationQuery) => {
  const searchParmas = new URLSearchParams(pagination);
  return `${url}?${searchParmas.toString()}`;
};

// export const getUrl = (url: string) => {
//   if (url.includes('http://vipankumar.in:2022')) {
//     url = url.replace('http://vipankumar.in:2022', 'https://event-backend.vipankumar.in');
//   }
//   if (url.includes('http://vipankumar.in:4009')) {
//     url = url.replace('http://vipankumar.in:4009', 'https://event-backend.vipankumar.in');
//   }
//   return url;
// };
