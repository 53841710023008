import { axios } from '@/lib/axios';

export type SocialLoginDTO = {
  provider: string;
  social_id: string;
  first_name: string;
  last_name: string;
  dob: string;
  email: string;
};

export const socialLogin = (data: SocialLoginDTO) => {
  return axios.post('/customer-api/social-login', data);
};
