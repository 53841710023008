import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@/components/Elements/Card';
import { Button } from '@/components/Elements/Button';
import { AdminLayout } from '@/components/Layout';
import './ContentManagement.css';
import { useFaqData } from '@/features/admin/api/faqData';
import { postFaq } from '@/features/admin/api/postFaq';
import * as z from 'zod';
import { Form, InputField } from '@/components/Form';

import ContentManagement from '@/components/Elements/Loader/ContentManagement';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';

import { FaqContent } from '@/types';
import i18next from 'i18next';
const stateFromHTML = require('draft-js-import-html').stateFromHTML;

function EditFaq() {
  const schema = z.object({
    title: z.string().min(1, 'Please enter faq title'),
  });

  type ContactValues = {
    title: string;
  };
  const { data, isLoading, isRefetching, refetch } = useFaqData();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState<any>(null);
  const user = data?.data[0] ?? ({} as FaqContent);
  const id = data?.data[0].id;
  useEffect(() => {
    if (user.content) {
      setHtml(user.content);
      const contentState = stateFromHTML(user.content);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [user.content]);

  const handleChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setHtml(currentContentAsHTML);
  };

  const handleSubmit = async (values: ContactValues) => {
    setLoading(true);
    await postFaq({
      ...(values ?? ''),
      content: html ?? '',
      faq_id: id ?? '',
    });
    refetch();
    navigate('/admin/faq-content');
    setLoading(false);
  };

  return (
    <>
      <AdminLayout title="Edit Faq Content">
        <Card shadow>
          <div className="p-3 pb-0">
            <h5>
              <strong> Edit </strong>
            </h5>
          </div>
          {isLoading || isRefetching ? (
            <ContentManagement />
          ) : (
            <Form<ContactValues, typeof schema>
              onSubmit={handleSubmit}
              schema={schema}
              options={{
                defaultValues: user,
              }}
            >
              {({ register, formState }) => (
                <>
                  <div className="mx-4">
                    <InputField
                      type="text"
                      label={i18next.t('Title')}
                      error={formState.errors['title']}
                      registration={register('title')}
                    />
                  </div>
                  <div className="mx-4">
                    <Editor editorState={editorState} onEditorStateChange={handleChange} />
                    <div className="d-flex justify-content-end my-5">
                      <Button onClick={() => navigate(-1)} variant="inverse" block className="me-3">
                        {i18next.t(`Cancel`)}
                      </Button>
                      <Button isLoading={loading} type="submit" block>
                        Save
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          )}
        </Card>
      </AdminLayout>
    </>
  );
}

export default EditFaq;
