import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from '@/components/Elements/Card';
import { Button } from '@/components/Elements/Button';
import { AdminLayout } from '@/components/Layout';
import './RoleManagement.css';
import { editRoles } from '@/features/admin/api/editRoles';
import { getRoleData } from '@/features/admin/api/getRoleData';
import * as z from 'zod';
import { Form, InputField } from '@/components/Form';
import { RoleContent } from '@/types';
import RolesContent from '@/components/Elements/Loader/RolesContent';
import { addCurrency } from '../../api/addCurrency';
import { editCurrency } from '../../api/editCurrency';
import { getCurrencyData } from '../../api/getCurrencyData';
import { Currency } from '@/types/Currency';
import i18next from 'i18next';

const schema = z.object({
  symbol: z.string().min(1, 'Please enter symbol'),
  name: z.string().min(1, 'Please enter currency name'),
  converted_price: z.string().min(1, 'Please enter conversion price'),
});
type ContactValues = {
  symbol: string;
  name: string;
  converted_price: string;
};

function AddCurrency() {
  const { id } = useParams();
  const [user, setUser] = useState<Currency>();
  const [isLoader, setisLoader] = useState<boolean>(false);
  const getDataOfApi = async () => {
    setisLoader(true);
    if (id != null) {
      const data = await getCurrencyData(id);
      const userIn = data?.data ?? ({} as Currency);
      setUser(userIn);
    }
    setisLoader(false);
  };

  useEffect(() => {
    getDataOfApi();
  }, [id]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: ContactValues) => {
    setLoading(true);
    {
      id
        ? await editCurrency({ ...values, currency_id: id }, id)
        : await addCurrency({ ...values });
    }
    setLoading(false);
    navigate('/admin/currency');
  };
  console.log(user, 'user');

  return (
    <>
      {isLoader ? (
        <RolesContent />
      ) : (
        <>
          <AdminLayout title="Currency  ">
            <Card shadow>
              <div className="p-3 pb-0">
                <h5>
                  <strong> Fill currency details here </strong>
                </h5>
              </div>

              <Form<ContactValues, typeof schema>
                onSubmit={handleSubmit}
                schema={schema}
                options={{
                  defaultValues: user,
                }}
              >
                {({ register, formState }) => (
                  <>
                    <div className="row mt-5">
                      <div className="col-md-4">
                        <InputField
                          type="text"
                          label="Currency Name"
                          error={formState.errors['name']}
                          registration={register('name')}
                        />
                      </div>
                      <div className=" col-md-4">
                        <InputField
                          type="text"
                          label="symbol"
                          error={formState.errors['symbol']}
                          registration={register('symbol')}
                        />
                      </div>
                      <div className=" col-md-4">
                        <InputField
                          type="float"
                          label="Converted Price in $"
                          error={formState.errors['converted_price']}
                          registration={register('converted_price')}
                        />
                      </div>
                    </div>

                    <div className="mx-4">
                      <div className="d-flex justify-content-end my-5">
                        <Button
                          onClick={() => navigate(-1)}
                          variant="inverse"
                          block
                          className="me-3"
                        >
                          {i18next.t(`Cancel`)}
                        </Button>
                        <Button isLoading={loading} type="submit" block>
                          Save
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </Card>
          </AdminLayout>
        </>
      )}
    </>
  );
}

export default AddCurrency;
