import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PaginationQuery } from '@/types/PaginationQuery';
import { formatURL as url } from '@/utils/format';
import { TableResponse } from '@/types/TableResponse';
import { Currency } from '@/types/Currency';

export const getCurrencyListing = (
  pagination: PaginationQuery
): Promise<TableResponse<Currency>> => {
  return axios.get(url('/admin-api/get-currency-listing', pagination));
};

type QueryFnType = typeof getCurrencyListing;

type UseFaqOptions = {
  config?: QueryConfig<QueryFnType>;
  page_per_data?: string;
  page: string;
};

export const useCurrencyListing = ({ config, page_per_data = '10', page }: UseFaqOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['currency-list'],
    queryFn: () => getCurrencyListing({ page_per_data, page }),
  });
};
