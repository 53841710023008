import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const TableLoader = () => {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <Skeleton variant="rectangular" height={50} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-2">
          <Skeleton variant="rectangular" height={300} />
        </div>
        <div className="col-2">
          <Skeleton variant="rectangular" height={300} />
        </div>
        <div className="col-2">
          <Skeleton variant="rectangular" height={300} />
        </div>
        <div className="col-2">
          <Skeleton variant="rectangular" height={300} />
        </div>
        <div className="col-2">
          <Skeleton variant="rectangular" height={300} />
        </div>
        <div className="col-2">
          <Skeleton variant="rectangular" height={300} />
        </div>
      </div>
    </div>
  );
};

export default TableLoader;
