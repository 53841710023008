import create from 'zustand';

type UserStore = {
  showVerifyPopup: boolean;
  show: () => void;
  hide: () => void;
};

export const useUserStore = create<UserStore>((set) => ({
  showVerifyPopup: false,
  show: () =>
    set(() => ({
      showVerifyPopup: true,
    })),
  hide: () =>
    set(() => ({
      showVerifyPopup: false,
    })),
}));
