import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import * as z from 'zod';
import { useState } from 'react';
import { useNotificationStore } from '@/stores/notifications';
import { omit } from 'lodash';
import { resetPassword } from '../api/resetPassword';
import img from '@/assets/new-logo.png';
import i18next from 'i18next';

const schema = z
  .object({
    password: z
      .string()
      .min(1, 'Please enter password')
      .regex(
        /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%]).*$/,
        'Password should be a combination of uppercase alphabets, numbers and special characters'
      ),
    confirm_password: z.string().min(1, 'Please enter confirm password'),
  })
  .superRefine(({ confirm_password, password }, ctx) => {
    if (confirm_password !== password) {
      ctx.addIssue({
        path: ['confirm_password'],
        code: 'custom',
        message: 'The passwords did not match',
      });
    }
  });

type ResetPasswordValues = {
  password: string;
  confirm_password?: string;
};

export const ResetPasswordForm = ({ token }: { token: string }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: ResetPasswordValues) => {
    try {
      setLoading(true);
      let resetPayload = { ...values, token };
      resetPayload = omit(resetPayload, ['confirm_password']);
      await resetPassword(resetPayload);
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: 'Your password has been reset successfully!',
      });
      navigate('/auth/login');
    } catch (e: any) {
      const message = e.response?.data?.message || e.message;
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <img src={img} width="70" alt="alttext" />
      <h1 className="mb-0 mt-3">Reset Password</h1>
      <div className="mt-4">
        <Form<ResetPasswordValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
          {({ register, formState }) => (
            <>
              <InputField
                type="password"
                label="New Password"
                error={formState.errors['password']}
                registration={register('password')}
                placeholder="New Password"
              />
              <InputField
                type="password"
                label={i18next.t('Confirm Password')}
                error={formState.errors['confirm_password']}
                registration={register('confirm_password')}
                placeholder={i18next.t('Confirm Password')}
              />
              <Button isLoading={loading} type="submit" className="w-full">
                Save
              </Button>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
