import nextArrow from "@/assets/arrowNext.png";
import prevArrow from "@/assets/arrowPrev.png";
import "./custom.css";
import StepFirst  from './FindInvestorComponents/StepFirst';
import StepSecond  from './FindInvestorComponents/StepSecond';
import StepThird  from './FindInvestorComponents/StepThird';

type FormValues = {
    type: string;
    company_details: string;
    company_established: string;
};

export const FindInvestor = () => {
    return (
        <>
        <div className="section property-projects py-5">
            <div className="container">
                <h1 className="text-center text-dark mb-5">Add New Project for money !</h1>
                <div className="row">
                <div className="col-md-3">
                    <div className="sidebar-wrapper">
                    <ul>
                        <li className="sidebar-item pb-4 position-relative active">
                        <div className="d-flex align-items-center gap-4 justify-content-end">
                            <div className="content">
                            <span>Step 1</span>
                            <p className="title">Property Information</p>
                            <p className="subtitle">Lorem ipsum dolor</p>
                            </div>
                            <span className="counter">1</span>
                        </div>
                        </li>

                        <li className="sidebar-item pb-4 position-relative">
                        <div className="d-flex align-items-center gap-4 justify-content-end">
                            <div className="content">
                            <span>Step 2</span>
                            <p className="title">Add Shareholder</p>
                            <p className="subtitle">Lorem ipsum dolor</p>
                            </div>
                            <span className="counter">2</span>
                        </div>
                        </li>

                        <li className="sidebar-item pb-4 position-relative">
                        <div className="d-flex align-items-center gap-4 justify-content-end">
                            <div className="content">
                            <span>Step 3</span>
                            <p className="title">Company Information</p>
                            <p className="subtitle">Lorem ipsum dolor</p>
                            </div>
                            <span className="counter">3</span>
                        </div>
                        </li>
                    </ul>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="headline d-flex align-items-center justify-content-between mb-4">
                    <p className="mb-0">Complete the following fields</p>
                    <div className="nextPrevArrows d-flex gap-2">
                        <span className="arrow prev-arrow"><img src={prevArrow} className="d-block w-100" alt="" /></span>
                        <span className="arrow next-arrow"><img src={nextArrow} className="d-block w-100" alt="" /></span>
                    </div>
                    </div>

                    <div className="listingImports">
                        <StepFirst />
                        <StepSecond />
                        <StepThird />
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default FindInvestor;