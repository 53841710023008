import { Navigate, Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import { lazyImport } from '@/utils/lazyImport';
import './user.css';
import logo from '@/assets/new-logo.png';
// import { Purchase } from './routes/Contract/Purchase';
// import { CompanyInvestment } from './routes/Investor/CompanyInvestment';
// import { LinkNotFound } from './routes/LinkNotFound';
// import { SubscriptionType } from './SubscriptionType/SubscriptionType';
// import { ChargePayment } from '@/components/Payments/StripeCharge/ChargePayment';

const { LinkNotFound } = lazyImport(() => import('./routes/LinkNotFound'), 'LinkNotFound');
const { SubscriptionType } = lazyImport(
  () => import('./SubscriptionType/SubscriptionType'),
  'SubscriptionType'
);
const { ChargePayment } = lazyImport(
  () => import('@/components/Payments/StripeCharge/ChargePayment'),
  'ChargePayment'
);

const { CompanyInvestment } = lazyImport(
  () => import('./routes/Investor/CompanyInvestment'),
  'CompanyInvestment'
);
const { Purchase } = lazyImport(() => import('./routes/Contract/Purchase'), 'Purchase');
const { CompleteProfile } = lazyImport(() => import('@/features/user/routes'), 'CompleteProfile');
const { CreateBusiness } = lazyImport(() => import('@/features/user/routes'), 'CreateBusiness');
const { AddList } = lazyImport(() => import('@/features/user/routes'), 'AddList');
const { Profile } = lazyImport(() => import('@/features/user/routes'), 'Profile');
const { EditPropertyProject } = lazyImport(
  () => import('@/features/user/routes'),
  'EditPropertyProject'
);
const { CreatePropertyProject } = lazyImport(
  () => import('@/features/user/routes'),
  'CreatePropertyProject'
);

const { MyListing } = lazyImport(() => import('@/features/user/routes'), 'MyListing');
const { Option } = lazyImport(() => import('@/features/user/routes'), 'Option');
const { ProjectMoney } = lazyImport(() => import('@/features/user/routes'), 'ProjectMoney');
const { AddCompany } = lazyImport(() => import('@/features/company'), 'AddCompany');

const App = () => {
  return (
    <div>
      <Suspense
        fallback={
          <div className="w-screen h-screen alignmentLogo">
            {/* <Loader /> */}
            <img src={logo} alt="pics" />
          </div>
        }
      >
        <Header type={HeaderType.LANDING} />
        <Outlet></Outlet>
      </Suspense>
    </div>
  );
};

export const agentRoutes = [
  {
    path: '/user',
    element: <App />,
    children: [
      { path: '', element: <LinkNotFound /> },
      { path: 'profile', element: <Profile /> },
      { path: 'subscription-payment', element: <ChargePayment /> },
      { path: 'subscription-payment/:idSub', element: <ChargePayment /> },
      { path: 'complete-profile', element: <CompleteProfile /> },
      { path: 'purchase', element: <Purchase /> },
      { path: 'edit-property-project', element: <EditPropertyProject /> },
      { path: 'edit-property-project/:id/', element: <EditPropertyProject /> },
      { path: 'create-business', element: <CreateBusiness /> },
      { path: 'create-business/:id/', element: <CreateBusiness /> },
      { path: 'find-investor', element: <Option /> },
      { path: 'project-money', element: <ProjectMoney /> },
      { path: 'project-money/:id/', element: <ProjectMoney /> },
      { path: 'company-money', element: <CompanyInvestment /> },
      { path: 'company-money/:id/', element: <CompanyInvestment /> },
      { path: 'add', element: <AddList /> },
      { path: 'create-property-project', element: <CreatePropertyProject /> },
      { path: 'my-listing', element: <MyListing /> },
      { path: 'create-company', element: <AddCompany /> },
      { path: 'subscription', element: <SubscriptionType /> },
      { path: '*', element: <Navigate to="/user/add" /> },
    ],
  },
];
