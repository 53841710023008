import React, { useEffect, useState } from 'react';
import { Button } from '@/components/Elements';

import { SelectChangeEvent } from '@mui/material/Select';

import './PFSSearchForm.css';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import Menu from '@mui/material/Menu';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import axiosmod from 'axios';
import amanetiesCategory from '@/constants/amanetiesCategory';
import { MultiSelect } from 'react-multi-select-component';
import i18next from 'i18next';

const googleMapAPIkey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

// const googleMapAPIkey = 'AIzaSyC7Ru-5FPHA2frR9IYobw6XdfSqCdlqBc0';

const PFSSearchForm = ({
  setMinPrice,
  setMaxPrice,
  setMinBeds,
  setMaxBeds,
  setAmenities,
  setlocation,
  setSearch,
  setSave,
  setlat,
  setlng,
  setClearSearchVal,
}: {
  setMinPrice: (value: string) => void;
  setMaxPrice: (value: string) => void;
  setMinBeds: (value: string) => void;
  setMaxBeds: (value: string) => void;
  setAmenities: (value: string) => void;
  setlocation: (value: string) => void;
  setSearch: (value: boolean) => void;
  setSave: (value: boolean) => void;
  setlat: (value: string) => void;
  setlng: (value: string) => void;
  setClearSearchVal: (value: boolean) => void;
}) => {
  const [minBed, setMinBed] = useState('');
  const [maxBed, setMaxBed] = useState('');
  const [minPrice, setMin] = useState(null);
  const [maxPrice, setMax] = useState(null);
  const [searchs, setSearchVal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [clears, setClears] = useState(false);
  const [address, setAddress] = useState<string>('');
  const [locationGoogle, setLocationGoogle] = useState();

  const [value, setValue] = useState(null);
  const [latitude, setlatitude] = useState(0);
  const [longitude, setlongitude] = useState(0);

  useEffect(() => {
    const amenities = selected.map(({ value }) => `${value}`).join(',');
    setAmenities(amenities);
  }, [selected, setAmenities]);

  useEffect(() => {
    if (locationGoogle) {
      setlatitude(locationGoogle?.lat);
      setlongitude(locationGoogle?.lng);
    }
  }, [locationGoogle]);

  useEffect(() => {
    setlat(latitude);
    setlng(longitude);
    setlocation(address);
  }, [address]);

  // ------------------- Bedroms -------------------

  const handleMinBed = (event: SelectChangeEvent) => {
    const selectedMin = event.target.value as string;
    setMinBed(selectedMin);
  };

  const handleMaxBed = (event: SelectChangeEvent) => {
    const selectedMax = event.target.value as string;
    setMaxBed(selectedMax);
  };

  useEffect(() => {
    setMinBeds(minBed);
    setMaxBeds(maxBed);
  }, [maxBed, minBed, setMaxBeds, setMinBeds]);
  // ------------------- Price  Bar -------------------
  const handleStartChange = (event: SelectChangeEvent) => {
    const selectedStart = event.target.value;
    setMin(selectedStart);
    handleSearch();
  };

  const handleEndChange = (event: SelectChangeEvent) => {
    const selectedEnd = event.target.value;
    setMax(selectedEnd);
    handleSearch();
  };

  useEffect(() => {
    setMaxPrice(maxPrice);
    setMinPrice(minPrice);
    handleSearch();
  }, [maxPrice, minPrice, setMaxPrice, setMinPrice]);

  // ------------------- Menu Bar -------------------

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonId = event.currentTarget.id;

    if (buttonId === 'basic-button') {
      setAnchorEl(event.currentTarget);
    } else if (buttonId === 'basic-button2') {
      setAnchorEl2(event.currentTarget);
    } else if (buttonId === 'basic-button3') {
      setAnchorEl3(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  // ------------------ Location ---------------------

  const handleChangeLocation = async (value: any) => {
    const placeId = value.value.place_id;
    const { data } = await axiosmod.get(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${googleMapAPIkey}`
    );
    const location = data?.results[0]?.geometry?.location;
    const addressData = data?.results[0]?.formatted_address;

    if (location) {
      setLocationGoogle(location);
      setValue(value);
    }
    if (addressData) {
      setAddress(addressData);
    }
    handleSearch();
  };
  // ---------------- Submit --------------------
  const handleSearch = () => {
    setSearchVal(!searchs);
  };

  const clearSearch = () => {
    setClears(true);
    setAddress('');
  };

  useEffect(() => {
    if (clears === true) {
      setClearSearchVal(true);
      setMin('');
      setMax('');
      setlatitude('');
      setlongitude('');
      setMinBed('');
      setMaxBed('');
      setAddress('');
      setValue('');
      setSelected([]);
    }
  }, [clears]);

  useEffect(() => {
    setSearch(searchs);
  }, [searchs]);

  const handleSave = () => {
    setSave(true);
  };

  return (
    <div className="roundbox searchBar upperTop">
      <div className="outer row">
        <div className="left col-12 col-md-10">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3 bloks">
              <GooglePlacesAutocomplete
                apiKey={googleMapAPIkey}
                selectProps={{
                  defaultInputValue: '',
                  isClearable: true,
                  value,
                  placeholder: `${i18next.t('Search for city/location')}`,
                  onChange: handleChangeLocation,
                }}
              />
            </div>
            <div className="col-12 col-md-2 col-lg-3 bloks">
              <div className="MenuItemSearch">
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  className="priceButton"
                >
                  {minPrice == null && maxPrice == null ? (
                    'Price'
                  ) : (
                    <>
                      <span>
                        ${minPrice} - ${maxPrice}
                      </span>
                    </>
                  )}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <div className="jojoDiv">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          id="outlined-basic"
                          type="number"
                          defaultValue={minPrice}
                          onChange={handleStartChange}
                          label="Min"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          id="outlined-basic"
                          type="number"
                          onChange={handleEndChange}
                          defaultValue={maxPrice}
                          label="Max"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 bloks">
              <div className="MenuItemSearch">
                <Button
                  id="basic-button2"
                  aria-controls={open2 ? 'basic-menu2' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
                  onClick={handleClick}
                  className="priceButton"
                >
                  {i18next.t('Bedrooms')}
                </Button>
                <Menu
                  id="basic-menu2"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button2',
                  }}
                >
                  <div className="bedroomsDiv">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Min</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={minBed}
                            label={i18next.t('Property Type')}
                            onChange={handleMinBed}
                          >
                            <MenuItem value={'1'}>1 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'2'}>2 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'3'}>3 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'4'}>4 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'5'}>5 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'6'}>6 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'7'}>7 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'8'}>8 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'9'}>9 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'10'}>10 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'11'}>11 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'12'}>12 {i18next.t('Bedroom')}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Max</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={maxBed}
                            label={i18next.t('Property Type')}
                            onChange={handleMaxBed}
                          >
                            <MenuItem value={'1'}>1 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'2'}>2 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'3'}>3 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'4'}>4 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'5'}>5 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'6'}>6 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'7'}>7 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'8'}>8 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'9'}>9 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'10'}>10 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'11'}>11 {i18next.t('Bedroom')}</MenuItem>
                            <MenuItem value={'12'}>12 {i18next.t('Bedroom')}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>

            <div className="col-12 col-md-3 col-lg-3 bloks">
              <div className="MenuItemSearch">
                <Button
                  id="basic-button3"
                  aria-controls={open3 ? 'basic-menu3' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open3 ? 'true' : undefined}
                  onClick={handleClick}
                  className="priceButton"
                >
                  {i18next.t('Amenities')}{' '}
                </Button>
                <Menu
                  id="basic-menu3"
                  anchorEl={anchorEl3}
                  open={open3}
                  onClose={handleClose3}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button3',
                  }}
                >
                  <div className="amenitiesDiv2">
                    <MultiSelect
                      options={Object.entries(amanetiesCategory).map(([key, value]) => ({
                        label: value,
                        value: key,
                      }))}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select Amenaties."
                    />
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className="right col-12 col-md-2">
          {minBed == '' && maxBed == '' && maxPrice == null && selected.length == 0 ? (
            <Button className="btn-rounded bttn" onClick={() => handleSearch()}>
              {i18next.t('Search')}
            </Button>
          ) : (
            <>
              <Button className="btn-rounded bttn" onClick={() => handleSearch()}>
                <i className="fa-solid fa-magnifying-glass whiteColor"></i>
              </Button>
              <button className="saveButton " onClick={() => clearSearch()}>
                <i className="fa-solid fa-xmark "></i>
              </button>
              <button className="saveButton " onClick={() => handleSave()}>
                <i className="fa-regular fa-bookmark"></i>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PFSSearchForm;
