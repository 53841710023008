import React, { useEffect, useState } from 'react';
import './ServiceItemList.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Drawer } from '@/components/Elements/Drawer';
import { Button } from '@/components/Elements';
import { useDisclosure } from '@/hooks/useDisclosure';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Filter } from './filterType';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

const ServiceItemList = ({
  setObject,
  setSaveSearch,
  setClear,
  newObj,
  linkAddress,
}: {
  linkAddress?: any;
  newObj: any;
  setObject: React.Dispatch<React.SetStateAction<Filter | null>>;
  setSaveSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setClear: (value: boolean) => void;
}) => {
  // ==================== Filter form data =====================
  const { close, open, isOpen } = useDisclosure();

  const [priceSort, setPriceSort] = useState('');
  const [subscriptionSort, setSubscriptionSort] = useState('');
  const [timeSort, setTimeSort] = useState('');

  const handleSubsctiptionSort = (event: SelectChangeEvent) => {
    const selectedStart = event.target.value;
    setSubscriptionSort(selectedStart);
  };

  const clearFilter = () => {
    setSubscriptionSort('');
    setTimeSort('');
    setPriceSort('');
    close();
    setClear(true);
  };

  const obj = {
    priceSort,
    subscriptionSort,
    timeSort,
  };
  useEffect(() => {
    setObject(obj);
  }, [priceSort, subscriptionSort, timeSort]);

  const handleSaveSearch = () => {
    setSaveSearch(true);
    close();
  };

  useEffect(() => {
    if (newObj?.priceSort == '' || newObj?.timeSort == '' || newObj?.subscriptionSort == '') {
      null;
    } else {
      setSubscriptionSort(newObj?.subscriptionSort);
      setTimeSort(newObj?.timeSort);
      setPriceSort(newObj?.priceSort);
    }
  }, [newObj]);
  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-6 ">{/* <h3>Companies</h3> */}</div>
          <div className="col-12 col-md-6 filter">
            {linkAddress && (
              <button onClick={() => navigate(`${linkAddress}`)} className="howItWOrk">
                {i18next.t('How it works')}
              </button>
            )}
            <button onClick={open}>
              <i className="fa-solid fa-arrow-down-a-z"></i> {i18next.t('Filter')}
            </button>
          </div>
        </div>
      </div>

      <Drawer
        isOpen={isOpen}
        onClose={close}
        title={i18next.t('Filters')}
        size="sm"
        renderFooter={() => (
          <>
            <Button
              onClick={() => clearFilter()}
              variant="primary"
              className="w-100 justify-center"
              size="md"
            >
              {i18next.t('Clear')}
            </Button>
            <Button
              onClick={() => handleSaveSearch()}
              variant="primary"
              className="w-100 justify-center"
              size="md"
            >
              {i18next.t('Apply')}
            </Button>
          </>
        )}
      >
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              {i18next.t('Sort data according to price')}
            </FormLabel>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
              <FormControlLabel
                value="ascending"
                control={<Radio />}
                onClick={() => setPriceSort('ascending')}
                label={i18next.t('Low to high')}
                checked={priceSort === 'ascending'}
              />
              <FormControlLabel
                value="desending"
                control={<Radio />}
                onClick={() => setPriceSort('desending')}
                label={i18next.t('High to low')}
                checked={priceSort === 'desending'}
              />
            </RadioGroup>
          </FormControl>

          {/* -----------------------__? */}
          <hr />
          {/* <p>Select Subscription Typedd</p>
          <div className="row">
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Subscription</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subscriptionSort}
                  label="Property Type"
                  onChange={handleSubsctiptionSort}
                >
                  <MenuItem value={'0'}>No Subscription</MenuItem>
                  <MenuItem value={'1'}>Premium Subscription</MenuItem>
                  <MenuItem value={'2'}>Medium Subscription</MenuItem>
                  <MenuItem value={'3'}>Low Subscription</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <hr /> */}

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label"> {i18next.t('Date Created')}</FormLabel>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
              <FormControlLabel
                value="oldest"
                control={<Radio />}
                onClick={() => setTimeSort('oldest')}
                label={i18next.t('Oldest')}
                checked={timeSort === 'oldest'}
              />
              <FormControlLabel
                value="latest"
                control={<Radio />}
                onClick={() => setTimeSort('latest')}
                label={i18next.t('Latest')}
                checked={timeSort === 'latest'}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Drawer>
    </>
  );
};

export default ServiceItemList;
