import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { Notification } from '@/types/Notification';
import { ApiResponse } from '@/types';

export const getNotifications = (): Promise<ApiResponse<Notification[]>> => {
  return axios.get('/customer-api/customer-notifications');
};
type QueryFnType = typeof getNotifications;

type UseNotificationOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useNotifications = ({ config }: UseNotificationOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['notifications'],
    queryFn: () => getNotifications(),
  });
};
