import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout';
import EmailVerifyForm from '../components/EmailVerifyForm';
import storage from '@/utils/storage';
import { useNavigate } from 'react-router-dom';

export const EmailVerify = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const emailVal = storage.getEmail();
    if (emailVal) {
      setEmail(emailVal);
    } else {
      navigate('/auth/login');
    }
  }, [navigate]);

  return (
    <Layout title="Please verify your email address">
      <EmailVerifyForm email={email} />
    </Layout>
  );
};
