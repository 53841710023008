import React from 'react';
// import preview from '@/assets/preview.png';
import preview from '@/assets/backWal.png';

export const Image = (props: any) => {
  const fallback = props.imagefallback ? props.imagefallback : preview;
  return (
    <img
      className="buildChoti"
      alt="binary"
      {...props}
      src={props.src ? props.src : fallback}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = fallback;
      }}
    />
  );
};
