import { AdminAuthRoutes } from '@/features/admin/auth';
import { AuthRoutes } from '@/features/auth';

export const publicRoutes = [
  {
    path: '/auth/admin/*',
    element: <AdminAuthRoutes />,
  },
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
];
