import React, { useEffect, useState } from 'react';
import '../user.css';
import { Listing } from '@/types';
import serviceType from '@/constants/serviceType';
import BusinessSale from './BusinessSale';
import InvestmentProject from './InvestmentProject';
import InvestmentCompanys from './InvestmentCompanys';
import PropertyProjects from './PropertyProjects';
import PropertyForSaleItem from './PropertyForSaleItem';
import PropertyForRentItem from './PropertyForRentItem';
import HousingRent from './HousingRent';

const ServiceItem = ({
  item,
  showDeleteIcon = false,
  showEditIcon = false,
  showHeartIcon = false,
  showHeartFavIcon = false,
  setDeleteRefetch,
  setHeartClickedLanding,
}: {
  item: Listing;
  setDeleteRefetch: (p: boolean) => void;
  showEditIcon: boolean;
  showDeleteIcon: boolean;
  showHeartFavIcon: boolean;
  showHeartIcon: boolean;
  setHeartClickedLanding: (p: boolean) => void;
}): React.ReactElement => {
  const [deleteClicked, setDeleteClicked] = useState<boolean>(false);
  const [heartClicked, setHeartClicked] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    setDeleteRefetch?.(true);
  }, [deleteClicked]);

  useEffect(() => {
    if (heartClicked === true) {
      // setCheck(true);
      setHeartClickedLanding(true);
    }
  }, [heartClicked]);

  // useEffect(() => {
  //   if (check === true) {
  //     setHeartClickedLanding(true);
  //   }
  // }, [check]);

  switch (item.type) {
    case serviceType.BUSINESS_SALE:
      return (
        <BusinessSale
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    case serviceType.SALE_PROPERTY:
      return (
        <PropertyForSaleItem
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    case serviceType.RENT_PROPERTY:
      return (
        <PropertyForRentItem
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    case serviceType.HOUSE_RENT:
      return (
        <HousingRent
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    case serviceType.INVESTMENT_PROJECT:
      return (
        <InvestmentProject
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    case serviceType.INVESTMENT_COMPANY:
      return (
        <InvestmentCompanys
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    case serviceType.PROPERTY_PROJECT:
      return (
        <PropertyProjects
          headData={true}
          editIcon={showEditIcon}
          deleteIcon={showDeleteIcon}
          item={item}
          setHeartClicked={setHeartClicked}
          setDeleteClicked={setDeleteClicked}
          heartIcon={showHeartIcon}
          heartFavIcon={showHeartFavIcon}
        />
      );
    default:
      return <span></span>;
      break;
  }
};

export default ServiceItem;
