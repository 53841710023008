import { axios } from '@/lib/axios';
import { ApiResponse, FaqContent } from '@/types';

export type contentManagementDTO = {
  title: string;
  content: string;
  faq_id: string;
};

export const postFaq = (data: contentManagementDTO): Promise<ApiResponse<FaqContent>> => {
  return axios.post('/admin-api/edit-faq', data);
};
