import React from 'react';
import Header, { HeaderType } from '@/components/Layout/Header/Header';
import Footer from '@/components/Layout/Footer/Footer';
import { ContentLayout } from '@/components/Layout';
import { useTermsCondition } from '@/api/termsCondition';
import TermsLoader from '@/components/Elements/Loader/TermsLoader';
import i18next from 'i18next';

export const TermCondition = () => {
  // const data = termsCondition();
  const { data, isLoading } = useTermsCondition();

  const articleContent = data?.data.page_content;

  return (
    <ContentLayout title={i18next.t(`Privacy & Policy`)}>
      <div>
        <Header type={HeaderType.LANDING} />
        <section className="promo-about-pg py-5">
          <div className="container">
            <div className="about-content text-center">
              <h2 className="f-30 semibold">{i18next.t(`Terms & Conditions for Reeipo.`)}</h2>
            </div>
          </div>
        </section>
        {isLoading ? (
          <TermsLoader />
        ) : (
          <section className="about-content-first  about-privacy  pt-5 pb-5">
            <div className="container">
              <div className="about-us-box">
                <h2 className="f-30 semibold mb-2">{i18next.t(`Terms & Conditions`)}</h2>
                <div className="bg-light-pink abt">
                  <div dangerouslySetInnerHTML={{ __html: articleContent }} />
                </div>
              </div>
            </div>
          </section>
        )}
        <Footer />
      </div>
    </ContentLayout>
  );
};
