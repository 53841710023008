import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import img from '@/assets/new-logo.png';
import { Button, Spinner } from '@/components/Elements';
import { Form } from '@/components/Form';
import { resendOtp } from '../api/resendOtp';
import { useNotificationStore } from '@/stores/notifications';
import { useAuth } from '@/lib/auth';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

const schema = z.object({
  digit1: z.string().min(1, 'Required').max(1, 'Only one character is allowed'),
  digit2: z.string().min(1, 'Required').max(1, 'Only one character is allowed'),
  digit3: z.string().min(1, 'Required').max(1, 'Only one character is allowed'),
  digit4: z.string().min(1, 'Required').max(1, 'Only one character is allowed'),
});

type OtpValues = {
  digit1: string;
  digit2: string;
  digit3: string;
  digit4: string;
};

const EmailVerifyForm = ({ email }: { email: string }) => {
  const { register: verifyEmailApi, isRegistering } = useAuth();
  const [resending, setIsResending] = useState(false);
  const [canResendOtp, setResend] = useState(false);
  const [count, setCount] = useState(15);
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue.length > 1) {
      e.target.value = inputValue.slice(0, 1);
    }
  };
  useEffect(() => {
    const timer: any = count > 0 && setInterval(() => setCount(count - 1), 1000);
    if (count <= 0) {
      setResend(true);
    } else {
      setResend(false);
    }
    return () => clearInterval(timer);
  }, [count]);

  const sendOtpAgain = async () => {
    try {
      setIsResending(true);
      await resendOtp({ email });
      useNotificationStore.getState().addNotification({
        title: `${i18next.t('Success')}`,
        type: 'info',
        message: 'Otp has been sent to your email address again!',
      });
    } finally {
      setIsResending(false);
      setCount(15);
    }
  };

  const resendClicked = () => {
    if (canResendOtp) {
      sendOtpAgain();
    }
  };

  const handleSubmit = async (values: OtpValues) => {
    const payload = {
      otp: `${values.digit1}${values.digit2}${values.digit3}${values.digit4}`,
      email,
    };
    const response = await verifyEmailApi(payload);
    if (!response?.isProfileCompleted) {
      navigate(`/user/complete-profile`);
    }
    useNotificationStore.getState().addNotification({
      title: 'Success',
      type: 'success',
      message: 'Email verified successfully',
    });
  };

  const focus = (input: number) => (e: any) => {
    const inputValue = e.target.value;

    if (e.code === 'Backspace') {
      if (inputValue.length === 0) {
        if (input > 2) {
          const item = document.getElementById(`input${input - 2}`);
          item?.focus();
        }
        if (input === 2) {
          const item = document.getElementById(`input${input - 1}`);
          item?.focus();
        }
      }
    } else {
      const typedNumber = parseInt(e.key, 10);
      if (!isNaN(typedNumber) && inputValue.length === 0) {
        if (input < 5) {
          const item = document.getElementById(`input${input}`);
          item?.focus();
        }
      }
    }
  };

  return (
    <div>
      <img src={img} width="70" alt="alttext" />
      <h1 className="my-4">Verify Email Address</h1>
      <p className="mt-4 mb-0">Enter the code we sent to</p>
      <p>
        <b>{email} </b>
      </p>
      <Form<OtpValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
        {({ register, formState }) => (
          <div>
            <div className="row digits">
              <div className="col-2">
                <input
                  id="input1"
                  onKeyUp={focus(2)}
                  max="9"
                  type="number"
                  {...register('digit1')}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-2">
                <input
                  onKeyUp={focus(3)}
                  id="input2"
                  type="number"
                  {...register('digit2')}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-2">
                <input
                  id="input3"
                  onKeyUp={focus(4)}
                  type="number"
                  {...register('digit3')}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="col-2">
                <input
                  id="input4"
                  onKeyUp={focus(5)}
                  type="number"
                  {...register('digit4')}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
            {Object.keys(formState.errors)?.length > 0 && (
              <p className="mt-3 text-danger">Please enter otp</p>
            )}
            <div className="d-flex my-4 justify-content-between">
              <p className="">
                Didn&apos;t get the code?
                <span
                  tabIndex={-1}
                  onKeyDown={resendClicked}
                  role="button"
                  onClick={resendClicked}
                  className="text-blue-500 cursor-pointer"
                >
                  Send the code again
                </span>
              </p>
              {resending && <Spinner />}
              {count > 0 && (
                <span>
                  {count} <i className="fa-regular fa-clock" />
                </span>
              )}
            </div>
            <div className="my-4 d-flex">
              <Button isLoading={isRegistering} type="submit" className="w-50 me-3">
                {i18next.t('Continue')}{' '}
              </Button>
              <Button onClick={close} variant="outline" className="w-45">
                {i18next.t(`Cancel`)}
              </Button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EmailVerifyForm;
