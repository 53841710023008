import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, UseFormRegisterReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';
import i18next from 'i18next';

type InputFieldProps = FieldWrapperPassThroughProps & {
  registration: Partial<UseFormRegisterReturn>;
  value?: string;
  floating?: boolean;
};

export const InputPhone = (props: InputFieldProps) => {
  const { registration, error, value, floating = false, label } = props;
  const { control, setValue } = useForm();
  const { onChange: originalFormChange, name } = registration;

  useEffect(() => {
    if (value && name) {
      setValue(name, value);
    }
  }, [value]);

  const handleChange = (change: (event: string) => void) => (value: string) => {
    const e = {
      target: {
        value,
        name,
      },
    };
    originalFormChange?.(e);
    change(value);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <Controller
        name={name ?? ''}
        control={control}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            className={clsx('form-control phone-control', floating && 'floating')}
            placeholder={i18next.t('Enter Phone Number')}
            value={value}
            onChange={handleChange(onChange)}
          />
        )}
      />
    </FieldWrapper>
  );
};
